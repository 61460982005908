
let cup = [
  { 
    
    "name": "结构图拍摄",
    "studioName": "Plane_002",
    "studioColor": "random",
    "camera": {
      "position": {
        "x": 0.08814269762922308,
        "y": 0.2514253010704324,
        "z": 0.1860963135651011
    },
      "lookAt": {
        "x": -0.0382931702410367,
        "y": 0.08448494780859879,
        "z": -0.038290780368776894
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.4,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.9,
        "shadow": true
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.3
      }
    ],
    "objects": [
      {
        "angle": {
          "x": -180,
          "y": 0,
          "z": 90
        },
        "position": {
          "x": 0,
          "y": 0.06,
          "z": 0
        }
      },
      {
        "angle": {
          "x": 0,
          "y": -60,
          "z": 0
        },
        "position": {
          "x": 0,
          "y": 0.02,
          "z": -0.1
        }
      }
    ]
  },

  {
    "name": "正面拍摄",
    "studioName": "Plane_001",
    "camera": {
      "position": {
        "x": 0.08395310473408384,
        "y": 0.08852646314435636,
        "z": 0.1898542149449199
    },
      "lookAt": {
        "x": -0.016574696231715393,
        "y": 0.06280521314435639,
        "z": -0.035053652106572346
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.3,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.9,
        "shadow": true
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.3
      }
    ],
    "objects": [
      {
        "position": {
          "x": 0,
          "y": 0.01,
          "z": 0
        },        
          "angle": {
            "x": 0,
            "y": 124,
            "z": 0
          }
        
      }
    ]
  },
  {
    "name": "反面拍摄",
    "studioName": "Plane_001",
    "camera": {
      "position": {
        "x": 0.08395310473408384,
        "y": 0.08852646314435636,
        "z": 0.1898542149449199
    },
      "lookAt": {
        "x": -0.016574696231715393,
        "y": 0.06280521314435639,
        "z": -0.035053652106572346
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.3,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.9,
        "shadow": true
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.4
      }
    ],
    "objects": [
      {
        "position": {
          "x": 0,
          "y": 0.01,
          "z": 0
        },        
          "angle": {
            "x": 0,
            "y": -55,
            "z": 0
          }
        
      }
    ]
  }
]


let thermosCup = [
  { 
    
    "name": "结构图拍摄",
    "studioName": "Plane_002",
    "studioColor": "random",
    "enableEnvironment": true,
    "camera": {
      "position":{
        "x": 0.15988342806921385,
        "y": 0.35014931777502234,
        "z": 0.23409465351258837
    },
      "lookAt": {
        "x": -0.05559028363961568,
        "y": 0.1021161115327919,
        "z": -0.05783280055644438
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.4,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.5,
        "shadow": true  
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.4
      }
    ],
    "objects": [
      {
        "angle": {
          "x": -180,
          "y": 0,
          "z": 90
        },
        "position": {
          "x": -0.05,
          "y": 0.06,
          "z": 0
        },
        "scale":{
          "x": 2.5,
          "y": 2.5,
          "z": 2.5
        }
      },
      {
        "angle": {
          "x": 0,
          "y": -10,
          "z": 0
        },
        "position": {
          "x": 0,
          "y": 0.13,
          "z": -0.1
        },
        "scale":{
          "x": 2.5,
          "y": 2.5,
          "z": 2.5
        }        
      }
    ]
  },

  {
    "name": "正面拍摄",
    "studioName": "Plane_001",
    "enableEnvironment": true,
    "camera": {
      "position":{
        "x": 0.0792518165807629,
        "y": 0.22475394517121797,
        "z": 0.3357757840323358
    },
      "lookAt": {
        "x": 0.0010918279776251064,
        "y": 0.1443307588030131,
        "z": -0.004192109724378359
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.3,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.5,
        "shadow": true
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.4
      }
    ],
    "objects": [
      {
        "position": {
          "x": 0,
          "y": 0.14,
          "z": 0
        },        
          "angle": {
            "x": 0,
            "y": 30,
            "z": 0
          },
          "scale":{
            "x": 2.5,
            "y": 2.5,
            "z": 2.5
          }         
        
      }
    ]
  },
  {
    "name": "反面拍摄",
    "studioName": "Plane_001",
    "enableEnvironment": true,
    "camera": {
      "position":{
        "x": 0.0792518165807629,
        "y": 0.22475394517121797,
        "z": 0.3357757840323358
    },
      "lookAt": {
        "x": 0.0010918279776251064,
        "y": 0.1443307588030131,
        "z": -0.004192109724378359
    }
    },
    "lights": [
      {
        "type": "directionalLight",
        "lookAt": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "position": {
          "x": -0.038,
          "y": 0.3,
          "z": 0.2300
        },
        "color": "#fff",
        "intensity": 0.5,
        "shadow": true
      },
      {
        "type": "ambientLight",
        "color": "#fff",
        "intensity": 0.4
      }
    ],
    "objects": [
      {
        "position": {
          "x": 0,
          "y": 0.14,
          "z": 0
        },        
        "angle": {
          "x": 0,
          "y": -150,
          "z": 0
        },
        "scale":{
          "x": 2.5,
          "y": 2.5,
          "z": 2.5
        }
        
      }
    ]
  }
]

function getOptions(modelId) {

  switch (modelId) {
    case 2: return  cup;
    case 3: return thermosCup;
  }
}

export  default getOptions;