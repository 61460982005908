import { Raycaster, Group, Vector3 } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { createElement, setDefault, bindThis } from '../utils/helper';

export class CSS2DLabel {
  constructor(target, options = {}) {
    this.target = target;
    this.name = options.name || "";
    this.domEl = options.domEl;

    this.type = 'CSS2DLabel';
    this.visible = true;

    this.activeCamera = null;
    this.object3d = null;
    this.labelEl = null;
    this.raycaster = new Raycaster();
    this.backVisible = false;
    this.backSensitivity = 0.04;
    this.location = 'bottom';

    if(!target.isVector3) this.target = new Vector3().copy(target)
    this.init();
  }

  init() {
    this.domContainer = createElement('DIV', 'dom-container');
    this.labelEl = createElement('DIV', ' ');
    this.labelEl.appendChild(this.domContainer);

    this.setLocation(this.domContainer);

    if (this.domEl) {
      this.domContainer.appendChild(this.domEl);
    }
    this.object3d = createLabelOject3d(this);
    this.target = this.object3d.position.copy(this.target);
 
  }

  setLocation() {
    switch(this.location){
      case "bottom" :
        this.domContainer.style.transform = "translateY(-50%)";
    }
  }

  add(viewer) {

    viewer.scene.add(this.object3d);
    this.viewer = viewer;
    viewer.controls.addEventListener('change', bindThis(this.cameraPosEvent, this));

  }



  remove(viewer) {

    if (this.object3d) viewer.scene.remove(this.object3d);

    if (viewer.labelRenderer.domElement.contains(this.labelEl)) {
      viewer.labelRenderer.domElement.removeChild(this.labelEl);
    }

    viewer.controls.removeEventListener('change', bindThis(this.cameraPosEvent, this));

  }

  cameraPosEvent(e) {
    let distance = null;
    if(this.timeout) clearTimeout(this.timeout);

    if(this.backVisible) return;
    this.timeout = setTimeout(() => {
      let cameraPos = this.viewer.activeCamera.position.clone().applyMatrix4(this.viewer.activeCamera.matrixWorld);
      let dir = this.target.clone().sub(cameraPos).normalize();
      this.raycaster.set(cameraPos, dir);
      let intersects = this.raycaster.intersectObject(this.viewer.scene);      
      if(intersects.length > 0) {
        distance = intersects[0].point.distanceTo(this.target);
        if(distance < this.backSensitivity) this.object3d.visible = true;
        else this.object3d.visible = false;
      };     
      this.timeout = null;
    }, 100)    

  }





  toTopLayer () {
    if(this.object3d) {
      this.object3d.element.classList.add('top-layer');
      this.topLayer = true;
    }
  }
  resetLayer() {
    if(this.object3d) this.object3d.element.classList.remove('top-layer');
    this.topLayer = false;
  }

  animate() {
    this.switchVisibleState();
  }


}


function createLabelOject3d(_this) {

  let labelObj = new CSS2DObject(_this.labelEl);

  labelObj.name = "labelOject";

  let css2dLabel = new Group();
  css2dLabel.name = 'css2dLabel';
  css2dLabel.add(labelObj);

  return labelObj;
}


