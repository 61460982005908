<template>
  <van-col class="modal-message">
     <van-overlay :show="isShow">
      <van-col v-if="type==='loading'" class="message-loading">        
        <van-loading size="48px" vertical  >
          {{title}}
        </van-loading>
      </van-col>
    </van-overlay>
  </van-col>
</template>

<script>

import { Col, Row, Overlay, Loading } from 'vant';
import Vue from 'vue';

Vue.use( Col );
Vue.use( Row );
Vue.use(Overlay);
Vue.use(Loading);

export default {
  name: 'ModalMessage',
  data() {
    return {
      isShow: false,
      type: 'loading',
      title: '加载中'
    }
  },
  methods: {
    showModalMessage(isShow, options = {} ) {
      this.isShow = isShow;
      if(!isShow)  return;       
      if(options.type) this.type = options.type;
      if(options.title) this.title = options.title;
    }
  },

  created() {
    this.$eventBus.$on("showModalMessage", this.showModalMessage);
  },

}
</script>

<style lang="scss" scoped>
.message-loading {
  width: 200px;
  height: 200px;
  margin-left: 50%;
  margin-top: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 16px;

  .van-loading {
    margin-top:50%;
    transform: translateY(-50%);
  }
}

</style>
