import { Box3, Vector2, OrthographicCamera, CameraHelper, Sphere, Vector3, TextureLoader, ShaderMaterial, Euler, Raycaster } from "three";
import { defineProperty } from "../utils/helper";

export class ProjectionDecals {
  constructor(targetMesh, options = {}) {
    this.targetMesh = targetMesh;
    this.mapUrl = options.mapUrl;   
    this.scene = null; 
    this.bounding = options.bounding;    
    this.orthCamera = new OrthographicCamera();
    this.cloneMesh = null;
    this.texturer = new TextureLoader();
    this.visible = true;
    this.raycaster = new Raycaster();

    targetMesh.traverseAncestors(node => {if(node.isScene) this.scene = node});
    this.scene.add(this.orthCamera);
  
    this.setOrthCameraParam();
  

    this.addCloneMesh()
    this.init()
    this.definePropertyAll();
  }

  init() {
    
  }
  
  addCloneMesh() {
   
    this.cloneMesh = this.targetMesh.clone();
    this.targetMesh.renderOrder = 0;
    this.cloneMesh.renderOrder = 1
    this.cloneMesh.name ='cloneMesh';
    this.cloneMesh.removeFromParent();
 
    this.uniforms = {
      decalMap: { value: this.texturer.load(this.mapUrl) },
      orthProjectionMat:{ value: this.orthCamera.projectionMatrix.clone()},
      orhtViewMat: { value: this.orthCamera.matrixWorldInverse.clone() },
      orhtDir: { value: this.orthCamera.getWorldDirection(new Vector3())},
      normalMat: { value: this.cloneMesh.normalMatrix.clone()}
    }

    let decalMaterial = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,  
      // depthWrite: false,
      depthTest: false,
    })

    this.cloneMesh.material = decalMaterial;

    
    
    this.scene.add(this.cloneMesh);

  }

  setOrthCameraParam() {
   
    let bounding = this.bounding;
  
    let temp_1 = new Vector2(bounding.min.x, bounding.min.z);
    let temp_2 = new Vector2(bounding.max.x, bounding.max.z);
    let width = temp_1.distanceTo(temp_2) / 2;
    let height = Math.abs((bounding.max.y - bounding.min.y) / 2);


    this.orthCamera.left = -width;
    this.orthCamera.right = width;
    this.orthCamera.top = height;
    this.orthCamera.bottom = -height;
    console.log('bounding', width)
    let meshBox3 = new Box3();    
    meshBox3.expandByObject(this.targetMesh);
    let meshSphere = new Sphere();
     meshBox3.getBoundingSphere(meshSphere);
    let far = meshSphere.radius *1.5;
    let lookAtTarget = meshSphere.center.clone();
    this.lookAtTarget = lookAtTarget;
    let tempLookAtTarget = lookAtTarget.clone();
    tempLookAtTarget.y = bounding.center.y;
    let backDir = tempLookAtTarget.clone().sub(bounding.center).normalize();
    let endPos = tempLookAtTarget.clone().add( backDir.clone().multiplyScalar(-far));
    this.orthCamera.far = far;
    this.orthCamera.near = far *0.01;
    this.orthCamera.position.copy(endPos)
    this.orthCamera.lookAt(tempLookAtTarget);  
    this.orthCamera.updateProjectionMatrix();

    //测试用
    const helper = new CameraHelper( this.orthCamera );
    // this.scene.add( helper );

  }

  horizontalMove(angle) {

    let tempLookAtTarget = this.lookAtTarget.clone();
    tempLookAtTarget.y = this.orthCamera.position.y;
    let distance3D =  this.orthCamera.position.clone().sub(tempLookAtTarget);
    distance3D.applyEuler(new Euler( 0, angle, 0, 'XYZ' ));
    let endPos = tempLookAtTarget.clone().add(distance3D);
    this.orthCamera.position.copy(endPos);
    this.orthCamera.lookAt(tempLookAtTarget);
    this.orthCamera.updateProjectionMatrix();
    this.updatedMaterial()

  }

  verticalMove(distance) {

    this.orthCamera.position.y += distance;
    let tempLookAtTarget = this.lookAtTarget.clone();
    tempLookAtTarget.y = this.orthCamera.position.y;  

    this.orthCamera.lookAt(tempLookAtTarget);
    this.orthCamera.updateProjectionMatrix();
    this.updatedMaterial()  

  }

  updatedMaterial() {
    this.uniforms.orhtViewMat.value = this.orthCamera.matrixWorldInverse.clone();
    this.uniforms.orthProjectionMat.value = this.orthCamera.projectionMatrix.clone();
    this.uniforms.orhtDir.value = this.orthCamera.getWorldDirection(new Vector3());
  }

  updata() {
    // console.log('投影更新了', this.bounding)
    this.uniforms.decalMap.value = this.texturer.load(this.mapUrl);
    this.setOrthCameraParam();
    this.updatedMaterial()   

  }

  saveState() {
    this.state = {
      position: this.orthCamera.position.clone(),
      lookAtTarget: this.lookAtTarget.clone(),
    }

  }

  resetState() {
    this.orthCamera.position.copy(this.state.position);
    this.lookAtTarget.copy(this.state.lookAtTarget);
    this.updata();
  }

  getCenterRayInfo() {

    let cameraPos = this.orthCamera.position.clone();
    let tempLookAtTarget = this.lookAtTarget.clone();
    tempLookAtTarget.y = cameraPos.y;   
    let dir = tempLookAtTarget.sub(cameraPos).normalize();
    this.raycaster.set(cameraPos, dir);
    let intersects = this.raycaster.intersectObject(this.scene);  
    
    if(intersects.length === 0) return;
    return intersects[0]
  }

  definePropertyAll() {
    defineProperty(this, 'visible', () => this.cloneMesh.visible = this.visible);
  }

}

let vertexShader = `
varying vec2 vUv;
varying vec4 pos;
varying vec3 decalUv;
uniform vec3 orhtDir;
varying float clipV;
uniform mat4 orthProjectionMat;
uniform mat4 orhtViewMat;
uniform mat3 normalMat;

  void main() {
    vUv = uv;
    mat4 orthVP = orthProjectionMat * orhtViewMat;
    vec4 orthMVP = orthVP * (modelMatrix * vec4(position,1.0));  
    decalUv = orthMVP.xyz;
    // decalUv.y *= -1.;
    decalUv += 1.;
    decalUv *= 0.5;
    clipV = dot(orhtDir, (normalMat * normal));
    vec4 endPos = (projectionMatrix * modelViewMatrix * vec4(position,1.0));

    gl_Position = endPos;
  }
`


let fragmentShader = `

varying vec2 vUv;
varying vec3 decalUv;
varying float clipV;
uniform sampler2D decalMap;

void main() {
  
  vec4 color = texture2D(decalMap, decalUv.xy);
  if(decalUv.x >= 0.  && decalUv.x <= 1. && decalUv.y >= 0. && decalUv.y <= 1. && clipV <= -0.05 && decalUv.z >= 0. && decalUv.z <= 1.) {
    color.a *= 0.6;
  } else {
    color.a = 0.;
  }

  // gl_FragColor  = vec4(clipV, 0., 0., 1.);
  gl_FragColor  = color;
}
`