import {
  AmbientLight,
  PerspectiveCamera,
  Scene,
  Group,
  Vector2,
  WebGLRenderer,
  DirectionalLight,
  Color,
  Raycaster,
  EventDispatcher,
  PMREMGenerator
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { getFileNameByUrl , getElementWidth, getElementHeight } from '../utils/helper';
import Camera from './Camera';
import { SeriesModel } from './SeriesModel';
import ApiService from '../utils/ApiService';
import { AttachObject } from './AttachObject';
import fabricjsAdditional from './fabricjsAdditional';
import * as TWEEN from "three/examples/jsm/libs/tween.module.js";
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { Photostudio } from './Photostudio';

fabricjsAdditional();

export class Viewer  {
  constructor(el, options) {
    this.el = el;
    this.options = Object.assign({
      decoderPath: '/draco/'
    }, options);

    this.raycaster = new Raycaster();
    this.scene = new Scene();
    this.content = new Group(); 
    this.lights = new Group(); 
    this.lights.name = 'lights';
    this.helper = new Group(); 
    this.helper.name = 'helper'; 
    this.mouse = new Vector2();
    this.originalMaterial = {};
    this.objectScene = {};
    this.isRender = true;
    this.animateTimer = null; 
    this.envhdrUrl = './images/environment/venice_sunset_1k.hdr';
 

    window.styleEditorViewer = this;

    this.scene.add(this.content);
    this.scene.add(this.lights);  

    this.photostudio = new Photostudio(this);

    this.initScene();
    this.creatLabelRenderer()
    this.initEvent();
    this.initExtended();
  
    this.animate()
  }
 

  initScene() {

    this.defaultCamera = new PerspectiveCamera(45, this.el.clientWidth / this.el.clientHeight, 0.001, 10);
    this.defaultCamera.name = "camera";
    this.activeCamera = this.defaultCamera;
    this.scene.add(this.defaultCamera);
    this.activeCamera.position.z = 0.5 
    this.activeCamera.position.y = 0.2 
    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.el.clientWidth, this.el.clientHeight);
    this.el.appendChild(this.renderer.domElement);

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.controls = new OrbitControls(this.defaultCamera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;

    this.controlsDefaultParams = {
      maxDistance: this.controls.maxDistance,
      minDistance: this.controls.minDistance,
      maxPolarAngle: this.controls.maxPolarAngle,
      minPolarAngle: this.controls.minPolarAngle
    }
 
    // this.controls.enablePan = false;


    this.loader = new GLTFLoader()
    // .setCrossOrigin('anonymous')
    .setDRACOLoader(new DRACOLoader().setDecoderPath(this.options.decoderPath));

    window.addEventListener('resize', this.resize.bind(this), false);

  }
  

  initEvent() {
    this.renderer.domElement.addEventListener('mousedown', this.onMouseDown.bind(this), false);
    this.renderer.domElement.addEventListener('mouseup', this.onMouseUp.bind(this), false);
  }

  initExtended() {
    this.camera = new Camera(this)
  }

  onMouseDown(e) {
    // console.log("鼠标按下", e)
    this.oX = e.pageX;
    this.oY = e.pageY;
  }

  onMouseUp(e) {
      let _this = this;
      // console.log("鼠标弹起", e)
      this.fX = e.pageX;
      this.fY = e.pageY;
      if (Math.abs(this.fX - this.oX) < 2 && Math.abs(this.fY - this.oY) < 2) {
          let pickedTarget =  _this.raycast.apply(_this, [e]);
          
          console.log('相机位置', this.activeCamera.position.clone());
          console.log('控制器lookAt', this.controls.target.clone());
          // console.log('点击的位置UV',pickedTarget.point);
          this.dispatchEvent({ type: "click", picked: pickedTarget});
      }
  }

  addDefaultLight() {
    const ambientLight = new AmbientLight();
    ambientLight.intensity = 0.5
    ambientLight.name = 'ambient_light';
    this.lights.add(ambientLight);

    const light2 = new DirectionalLight(new Color(),  0.9);
    light2.position.set(-1, 3, 1); // ~60º
    light2.name = 'main_light';
    this.lights.add(light2);
  }

  reloadLights(lightParams) {
    this.clearLight();
    this.addLights(lightParams)
  }

  addLights(lightParams) {

    lightParams.forEach(param => {

      let light;

      switch(param.type) {
        case "directionalLight": 
          light = this.addDirectionalLight(param);
          break;

        case "ambientLight": 
          light = this.addAmbientLight(param);
          break;          

      }

      this.lights.add(light);
    });

  }

  addDirectionalLight(param) {
    let light = new DirectionalLight();
    if (param.position) light.position.copy(param.position);
    if (param.intensity >= 0) light.intensity = param.intensity;
    light.userData.originIntensity = light.intensity;
    return light;    
  }

  addAmbientLight(param) {
    let light = new AmbientLight();
    if (param.intensity >= 0) light.intensity = param.intensity;
    light.userData.originIntensity = light.intensity;
    return light;
  }

  setLightsIntensityRate(intensityRate = 1) {
    this.lights.children.forEach((light) => {
      light.intensity = light.userData.originIntensity * intensityRate;
    })
  }

  clearLight() {
    this.lights.clear();
  }

  resize() {
    const { clientHeight, clientWidth } = this.el;

    if (clientHeight === 0) {
        return
    }
    this.defaultCamera.aspect = clientWidth / clientHeight;
    this.defaultCamera.updateProjectionMatrix();
    this.renderer.setSize(clientWidth, clientHeight);
    this.labelRenderer.setSize(clientWidth, clientHeight);
    // this.labelRenderer.setSize(clientWidth, clientHeight);
    // if (this.composer) this.composer.setSize(clientWidth, clientHeight);
  }

  animate() {

    if(!this.isRender) return;    
    TWEEN.update();   
    if (this.labelRenderer) {
      this.labelRenderer.render(this.scene, this.activeCamera)
      // console.log('进来了')
    };
    this.animateTimer = requestAnimationFrame(this.animate.bind(this));  
    this.render();
  }

  render() {
    this.renderer.render(this.scene, this.activeCamera); 
  }

  loadModelByJson(jsonUrl, onComplete, onError) {

    return new Promise((resolve, reject) => {
      ApiService.getJson(jsonUrl).then(async (resp) => {
       
        if(!resp.url) reject();
        let glft = await this.load(resp.url);
        let seriesModel = new SeriesModel(glft.scene, resp);   
        this.content.add(seriesModel);
        console.log('resp', resp)
        resolve({model: seriesModel, json: resp})
      })  
  
    })
    
  }

   loadSeriesModel(options) {

    return new Promise( async (resolve, reject) => {
      if(!options.url) reject();
      let glft = await this.load(options.url);
      let seriesModel = new SeriesModel(glft.scene, options);   
      this.content.add(seriesModel);
      console.log('resp', options)
      resolve({model: seriesModel, options: options})  
    })
    
  }


  loadModels(urls=[], onComplete, onError) {    

    return new Promise(async (resolve, reject) => {
      for(let i = 0; i < urls.length; i++) {  

        let url = urls[i];
        let fileName = getFileNameByUrl(url);
        let glft =  await this.load(url, fileName);
        this.content.add(glft.scene)
      }
      if(onComplete) onComplete();
      resolve()
    })
   
  }

  load(url, fileName ) {
    let that = this;
    return new Promise((resolve, reject) => {
      this.loader.load(url, (glft)=> {
        if(fileName) glft.scene.name = fileName;       
        resolve(glft)
      })
    })
  }

  async addAttachObjectByUrl(url) {

    if(!url) return;
     let fileName = getFileNameByUrl(url);
    let glft = await this.load(url, fileName);
    let attachObject = new AttachObject(glft.scene); 
    attachObject.copy(glft.scene) 
    console.log('attachObject', attachObject)
    // this.content.add(attachObject);

    return attachObject
  }

  add(instance) {
    instance.add(this);
  }

  remove(instance) {
      if (instance) instance.remove(this);
  }

  stopRender() {
    this.isRender = false;
    cancelAnimationFrame(this.animateTimer);
    this.animateTimer = false;
  }

  startRender() {
    this.isRender = true;    
    this.animate();
  }

  creatLabelRenderer() {
    if (this.labelRenderer) {
        return;
    }
    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize(getElementWidth(this.el), getElementHeight(this.el));
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.top = '0px';
    this.labelRenderer.domElement.style.pointerEvents = 'none';
    this.labelRenderer.domElement.style.width = '100%';
    this.labelRenderer.domElement.style.height = '100%';
    this.labelRenderer.domElement.classList.add(`cInfo-label`)
    this.el.appendChild(this.labelRenderer.domElement);
}

 


  raycast(e) {

    const { clientHeight, clientWidth } = this.el;
    let mouse = new Vector2();
    mouse.x = (e.offsetX / clientWidth) * 2 - 1;
    mouse.y = -(e.offsetY / clientHeight) * 2 + 1;
    let selectNode = [];
    let intersects = [];
    let pickedTarget = null;

    let scene = this.scene;
    let raycaster = this.raycaster;
    raycaster.setFromCamera(mouse, this.activeCamera);
    scene.children.map((item) => {
      selectNode.push(item);
    });
    if (selectNode) {
      intersects = raycaster.intersectObjects(selectNode, true);
    }

    for(let nodeInfo of intersects) {
      if(!nodeInfo.object.visible) continue;
      pickedTarget = nodeInfo;
      break;
    }

    return pickedTarget;

  }

  createEnvironment(envUrl, options = {}) {

    envUrl = envUrl || this.envhdrUrl;
    let  scene = options.scene || this.scene;
    let pmremGenerator = options.pmremGenerator || this.pmremGenerator
 
    return new Promise((resolve, reject) => {

      new RGBELoader()
          // .setDataType(UnsignedByteType)
          .load(envUrl, (texture) => { 
            let envMap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envMap;      
          
            // this.scene.background = texture;     
            resolve(envMap)
          }, undefined, reject);
    });
  }

  setNodeEnvIntensity(tragetNode, intensity) {

    tragetNode.traverse((node)=> {
      if(!node.material) return;
      node.material.envMapIntensity = intensity
    })

  }

  closeEnvironment(scene) {
    scene = scene || this.scene;
    scene.environment = null; 
  }

  getObjectByScreenPoint(offsetX, offsetY) {
    let pickedTarget = this.raycast({ offsetX, offsetY });
    return pickedTarget;
  }  

  setCameraView(options = {}) {
    this.controls.reset()
    if(options.position) this.camera.position.copy(options.position);
    if(options.lookAt) this.controls.target.copy(options.lookAt);
    // else this.camera.lookTo(this.seriesModel.activeMesh.name);
    if(options.enablePan !== undefined) this.controls.enablePan = options.enablePan;

    if(options.maxDistance >= 0) this.controls.maxDistance = options.maxDistance;
    else this.controls.maxDistance = this.controlsDefaultParams.maxDistance;

    if(options.minDistance >= 0) this.controls.minDistance = options.minDistance;
    else this.controls.minDistance = this.controlsDefaultParams.minDistance;

    if(options.maxPolarAngle !== undefined) this.controls.maxPolarAngle = options.maxPolarAngle;
    else this.controls.maxPolarAngle = this.controlsDefaultParams.maxPolarAngle;

    if(options.minPolarAngle !== undefined) this.controls.minPolarAngle = options.minPolarAngle;
    else this.controls.minPolarAngle = this.controlsDefaultParams.minPolarAngle;
    
    this.controls.update();

    console.log('options.minPolarAngle', this.controls.maxPolarAngle)

    // this.controls.enablePan = false
    // this.controls.maxDistance  = 0.51;
    // this.controls.minDistance = 0.24;
    // this.controls.maxPolarAngle  = Math.PI / 1.5;
    // this.controls.minPolarAngle  = Math.PI / 4;
  }

  autoSetCameraView() {

  }


  clearModels() {

  }

}

Object.setPrototypeOf(Viewer.prototype, EventDispatcher.prototype);
