/**
 * Augments canvas by assigning to `onObjectMove` and `onAfterRender`.
 * This kind of sucks because other code using those methods will stop functioning.
 * Need to fix it by replacing callbacks with pub/sub kind of subscription model.
 * (or maybe use existing fabric.util.fire/observe (if it won't be too slow))
 */
function initCenteringGuidelines(canvas) {

    var canvasWidth = canvas.getWidth(),
        canvasHeight = canvas.getHeight(),
        canvasWidthCenter = canvasWidth / 4,
        canvasHeightCenter = canvasHeight / 2,
        canvasWidthCenterMap = { },
        canvasRightWidthCenterMap = { },
        canvasHeightCenterMap = { },
        centerLineMargin = 14,
        centerLineColor = 'rgba(236,15,43,1)',
        centerLineWidth = 5,
        ctx = canvas.getSelectionContext(),
        viewportTransform;
  
    for (var i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
      canvasWidthCenterMap[Math.round(i)] = true;
    }

    for (var i = canvasWidthCenter*3 - centerLineMargin, len = canvasWidthCenter*3 + centerLineMargin; i <= len; i++) {
      canvasRightWidthCenterMap[Math.round(i)] = true;

      // console.log(canvasRightWidthCenterMap)

    }


    for (var i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
      canvasHeightCenterMap[Math.round(i)] = true;
    }
  
    function showVerticalCenterLine() {
      showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
    }

    function showRightVerticalCenterLine() {
      showCenterLine(canvasWidthCenter*3 + 0.5, 0, canvasWidthCenter*3 + 0.5, canvasHeight);
    }

    
    function showHorizontalCenterLine() {
      showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
    }
  
    function showCenterLine(x1, y1, x2, y2) {
      ctx.save();
      ctx.strokeStyle = centerLineColor;
      ctx.lineWidth = centerLineWidth;
      ctx.beginPath();
      ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
      ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);

      // ctx.setLineDash([5,10]);  
      ctx.stroke();
      ctx.restore();

      
     
    }
  
    var afterRenderActions = [],
        isInVerticalCenter,
        isRightVerticalCenter,
        isInHorizontalCenter;
  
    canvas.on('mouse:down', function () {
      viewportTransform = canvas.viewportTransform;
    });
  
    canvas.on('object:moving', function(e) {
      var object = e.target,
          objectCenter = object.getCenterPoint(),
          transform = canvas._currentTransform;
  
      if (!transform) return;
  
      isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap;

      // console.log(objectCenter.x)

      
      isRightVerticalCenter = Math.round(objectCenter.x) in canvasRightWidthCenterMap;

    
      
      


      isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;


  
      if (isInHorizontalCenter || isInVerticalCenter||isRightVerticalCenter) {
        if (isRightVerticalCenter){

          // console.log(isRightVerticalCenter)
          object.setPositionByOrigin(new fabric.Point((isRightVerticalCenter ? canvasWidthCenter*3 : objectCenter.x), (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y)), 'center', 'center');
        }
        else
        {
          

          object.setPositionByOrigin(new fabric.Point((isInVerticalCenter ? canvasWidthCenter : objectCenter.x), (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y)), 'center', 'center');
        }
        
      }
    });
  
    canvas.on('before:render', function() {
      canvas.clearContext(canvas.contextTop);
    });
  
    canvas.on('after:render', function() {
      if (isInVerticalCenter) {
        showVerticalCenterLine();
      }
      if (isRightVerticalCenter) {
        showRightVerticalCenterLine();
      }
      if (isInHorizontalCenter) {
        showHorizontalCenterLine();
      }
    });
  
    canvas.on('mouse:up', function() {
      // clear these values, to stop drawing guidelines once mouse is up
      isInVerticalCenter = isInHorizontalCenter = null;
      isRightVerticalCenter = isInHorizontalCenter = null;
      canvas.renderAll();
    });
  }

  export default initCenteringGuidelines;