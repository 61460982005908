import { Object3D, sRGBEncoding, TextureLoader, RepeatWrapping, LinearFilter, EventDispatcher } from "three";

export class SeriesModel extends Object3D{

  constructor( models, seriesInfo = {}){
    super();
    this.name = seriesInfo.modelName || "seriesModel";
    this.type = 'seriesModel';
    this.seriesInfos = seriesInfo.series;
    this.seriesDirNode = seriesInfo.dirNode;
    this.seriesMeshes = [];
    this.activeMesh = null;
    this.models = null;
    this.textureLoader = new TextureLoader();
    this.add(models);
    this.init(models)
  }

  init(models) {

    if(!this.seriesDirNode) {
      models.children.forEach(node => node.visible = false)
    } else {
      let target = models.getObjectByName(this.seriesDirNode);
      target.children.forEach(node => node.visible = false)
    }
    // console.log('children', this.children)
    this.seriesInfos.forEach((item, index) => {
 
      let target =  this.getObjectByName(item.nodeName);
      if(!target) return;
      if(index === 0) {  
        this.activeMesh = target;
        target.visible = true;
      }
    
      target.userData = Object.assign(target.userData, item)
      this.seriesMeshes.push(target);
    })
  }

  switchTo(nodeName) {
    this.seriesMeshes.forEach(item => {
      if(item.name === nodeName) {
        item.visible = true;
        this.activeMesh = item;
      }
      else item.visible = false;
    })

    that.dispatchEvent({ type: "switchActive", activeMesh: this.activeMesh });
  }

  loadBumpTexture(url) {
    let displacementMap =  this.textureLoader.load(url);
    // displacementMap.magFilter = LinearFilter; 
    // displacementMap.minFilter = LinearFilter; 
    // displacementMap.encoding = sRGBEncoding;
    displacementMap.wrapT = RepeatWrapping;
    displacementMap.repeat.y = -1;
    
    // this.activeMesh.material.bumpMap = displacementMap;
    // this.activeMesh.material.bumpScale = 0.2;

    this.activeMesh.material.normalMap  = displacementMap;

    this.activeMesh.material.needsUpdate = true;
  }

  dispose() {
    this.removeFromParent();
  }


}

// Object.setPrototypeOf(SeriesModel.prototype, EventDispatcher.prototype);
