import { Box3, PerspectiveCamera, Sphere, WebGLRenderer, Group, DirectionalLight, Color, AmbientLight, Object3D, BoxGeometry, MeshBasicMaterial, Mesh, VSMShadowMap, Scene, PMREMGenerator } from "three";
import { angleToRad } from "../utils/helper";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export class Photostudio {
  constructor(viewer) {
    this.viewer = viewer;
    this.objectLayer = 5;
    this.studioMode = null;
    this.viewePixelWidth = 512;
    this.viewePixelHeight = 512;
    this.scene = new Scene();
    this.lights = new Group();
    this.lights.name = 'photostudio_ights';
    this.cloneObjects = new Group();
    this.pervStudio = null;

    this.studioRandomColors = [
      // '#ff3300', 
      // '#ff6633', 
      // '#ffcc33', 
      // '#ffff33', 
      // '#FF6A6A'
      '#fcf1ec',
      '#ece3f5',
      '#89bdd5',
      '#d4e8f2',
      '#f5f2e5',
      '#eeeeec',
      '#f3feff'
    ]

    this.initScene();
    this.viewer.createEnvironment('./images/environment/venice_sunset_1k.hdr', {
      scene: this.scene,
      pmremGenerator: this.pmremGenerator
    }).then((envMap) =>{
      this.scene.environment = null;
      this.envMap = envMap
    })
  
  }

  initScene() {

    this.renderer  = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(this.viewePixelWidth, this.viewePixelHeight);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = VSMShadowMap;

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.camera = new PerspectiveCamera(45, this.viewePixelWidth / this.viewePixelHeight, 0.01, 10);
    this.camera.name = 'photostudio_camera';
    // this.camera.position.set(1, 1, 1)
    // this.camera.layers.set(this.objectLayer);
    this.scene.add(this.lights);
    this.scene.add(this.camera);
    this.scene.add(this.cloneObjects);

    
    //测试用
    // this.showScene()

    this.renderer.domElement.addEventListener('click', () => {
      if (this.controls) console.log('photostudio-lookAt', this.controls.target.clone());
      if (this.camera) console.log('photostudio-position', this.camera.position.clone());
    })

  }

  initControls() {
    console.log('this.renderer.domElement', this.renderer.domElement)
    this.camera.position.set(3, 0, 3)
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;
    this.controls.enabled = true;
    this.controls.addEventListener('change', (e) => {
      console.log('change')
    })

    
  }


  loadStudioModel(url) {
    return new Promise((resolve, reject) => {
      this.viewer.load(url, "photostudio").then((glft) => {
        this.studioMode = glft.scene;
        this.studioMode.traverse(node => {
          if (!node.isMesh) return;
          // node.layers.set(this.objectLayer);
          node.receiveShadow = true;
          node.castShadow = true;
          node.material.envMapIntensity = 0
          node.material.aoMapIntensity = 0.4
          node.visible = false;
        })

        this.scene.add(this.studioMode)
        //  console.log('loadStudioModel')
        this.renderer.render(this.viewer.scene, this.camera);
        resolve(glft.scene);
      })
    })
  }

  setPhotographyScene(options) {
    this.sceneOptions = options;
    let { camera, lights, studioName, studioColor, enableEnvironment } = options;

    let currentColor;
    if (camera) {
      if (camera.position) this.camera.position.copy(camera.position);
      if (camera.lookAt) {
        this.camera.lookAt(camera.lookAt.x, camera.lookAt.y, camera.lookAt.z);
        if (this.controls) {
          this.controls.target.set(camera.lookAt.x, camera.lookAt.y, camera.lookAt.z);
          this.controls.update();
        }
      }
    }
    if (lights) this.addLoadLightsAll(lights);

    if (enableEnvironment) this.scene.environment = this.envMap
    else this.scene.environment = null;

    let currentTragetNode;
    if (!studioName) currentTragetNode = this.studioMode;
    else currentTragetNode = this.scene.getObjectByName(studioName);

    if (studioColor && studioColor === 'random') {
      let currentColorIndex = Math.floor(Math.random() * this.studioRandomColors.length);
      if (currentColorIndex === this.studioRandomColors.length) currentColorIndex -= 1;
      currentColor = new Color(this.studioRandomColors[currentColorIndex]);
    } else if (studioColor) {
      currentColor = new Color(studioColor);
    }

    if (this.pervStudio) this.pervStudio.traverse(node => {
      if (!node.isMesh);
      node.visible = false;
    })
    // debugger
    if (currentTragetNode && currentTragetNode.uuid !== this.pervStudio) {

      currentTragetNode.traverse(node => {
        if (!node.isMesh);
        node.visible = true;
        if (!node.userData.originColor) node.userData.originColor = node.material.color.clone()
        if (currentColor) node.material.color = currentColor;
        else node.material.color = node.userData.originColor;
      });
      this.pervStudio = currentTragetNode;
    }

  }

  setCameraNearFarBaseNode(node) {

    let box = new Box3();
    let sphere = new Sphere()
    box.expandByObject(node);
    box.getBoundingSphere(sphere);
    this.camera.near = sphere.radius * 0.01;
    this.camera.far = sphere.radius * 100;
    this.camera.updateProjectionMatrix();

  }

  generateSnapshoot(targetNode) {

    this.originNodeParam = {};
    if (this.cloneObjects.children.length > 0) {
      let clearObjects = [...this.cloneObjects.children];
      clearObjects.forEach((item) => this.cloneObjects.remove(item))
    }

    this.createCloneObjects(targetNode);
    this.cloneObjects.traverse(node => {
      if (!node.isMesh) return;
      node.receiveShadow = true;
      node.castShadow = true;
    })

    this.setCameraNearFarBaseNode(this.cloneObjects);

    return new Promise((resolve, reject) => {
      let dataURL = this.renderToDataURL();
      resolve(dataURL)
    })
  }

  renderToDataURL() {
    // console.log('renderToDataURL')
    this.renderer.render(this.scene, this.camera);
    let dataURL = this.renderer.domElement.toDataURL();
    // console.log('renderToDataURL',dataURL)
    return dataURL;

    // console.log('renderToDataURL', this.camera)
    // console.log('Photostudio', this.renderer.domElement.toDataURL())
    // requestAnimationFrame(this.renderToDataURL.bind(this))

  }

  render(){
    // console.log('出现了')
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render.bind(this))
  }


  createCloneObjects(node) {

    this.sceneOptions.objects.forEach(option => {
      let cloneObject = node.clone();
      // cloneObject.removeFromParent();   
      this.setNodeParam(cloneObject, option);
      this.cloneObjects.add(cloneObject);

    })
  }


  setNodeParam(node, nodeOpt) {
    // let nodeOpt = this.sceneOptions.node
    if (nodeOpt.angle) {
      console.log('nodeOpt.angle', nodeOpt)
      if (nodeOpt.angle.x !== undefined) node.rotation.x = angleToRad(nodeOpt.angle.x);
      if (nodeOpt.angle.y !== undefined) node.rotation.y = angleToRad(nodeOpt.angle.y);
      if (nodeOpt.angle.z !== undefined) node.rotation.z = angleToRad(nodeOpt.angle.z);
    }
    if (nodeOpt.position) node.position.copy(nodeOpt.position);
    if (nodeOpt.scale) node.scale.copy(nodeOpt.scale);
  }


  addLoadLightsAll(lightLists) {
    if (this.lights.children.length > 0) {
      let clearLights = [...this.lights.children];
      clearLights.forEach((item) => {
        this.lights.remove(item);
        if (item.dispose) item.dispose();

      });

    }
    lightLists.forEach(element => {
      if (element.type === 'directionalLight') this.addDirectionalLight(element);
      else if (element.type === 'ambientLight') this.addAmbientLight(element);
    });

  }

  addDirectionalLight(options) {

    let light = new DirectionalLight();
    let lookAtObject = null;
    if (options.position) light.position.copy(options.position);
    if (options.color) light.color.copy(new Color(options.color));
    if (options.lookAt) {
      lookAtObject = new Object3D();
      light.target = lookAtObject;
    };

    if (options.intensity) light.intensity = options.intensity;

    if (options.shadow) {
      let shadowWidth = 0.6;
      let shadowHeight = 0.6;
      // light.layers.set(this.objectLayer);
      light.castShadow = true
      light.shadow.mapSize.width = 512
      light.shadow.mapSize.height = 512
      light.shadow.camera.near = 0.05
      light.shadow.camera.far = 3
      light.shadow.camera.left = -shadowWidth
      light.shadow.camera.right = shadowWidth
      light.shadow.camera.top = shadowHeight
      light.shadow.camera.bottom = -shadowHeight
      light.shadow.radius = 3
      light.shadow.blurSamples = 8
      light.shadow.bias = -0.00004
    }

    this.lights.add(light);

    if (lookAtObject) {
      this.lights.add(lookAtObject);
      // lookAtObject.layers.set(this.objectLayer);
    }

  }

  addAmbientLight(options) {

    let light = new AmbientLight();
    if (options.color) light.color.copy(new Color(options.color));
    if (options.intensity) light.intensity = options.intensity;
    // light.layers.set(this.objectLayer);
    this.lights.add(light);

  }

  //用于测试
  showScene() {
    this.renderer.domElement.style.position = 'absolute'
    this.renderer.domElement.style.top = 0
    this.renderer.domElement.style.zIndex = 100
    this.renderer.domElement.style.background = "#fff"
    document.body.appendChild(this.renderer.domElement);
    this.initControls()
    this.render()
  }

}