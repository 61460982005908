import * as  THREE from 'three';
import { fabric } from 'fabric';

function createElement(tag, className, html) {
    let el = document.createElement(tag);
    if (className) {
        el.className = className;
    }
    if (html) {
        el.innerHTML = html;
    }
    return el;
}

function getElementWidth(el) {
    if (el.getBoundingClientRect) {
        return el.getBoundingClientRect().width;
    } else {
        return el.clientWidth;
    }
}

function getElementHeight(el) {
    if (el.getBoundingClientRect) {
        return el.getBoundingClientRect().height;
    } else {
        return el.clientHeight;
    }
}

function setDefault( object, defaultVal ) {
    if( object === null || object === undefined ) return defaultVal;    
    return object;
}



/** 
 * 获取该节点为的box、边长、，最大、最小的边长，中心点
 * @method getNodeBox
 * @param {ThreeObject3d} node 
 * @returns {Object}
 */
function getNodeBox(node) {
    
  let box = new THREE.Box3();
  let center = new THREE.Vector3();
  let length = new THREE.Vector3();
  box.expandByObject(node);
  box.getCenter(center);
  length = box.max.clone().sub(box.min); 
  let maxLength = Math.max(length.x, length.y, length.z);
  let minLength = Math.min(length.x, length.y, length.z);
    
  return {
    box, 
    center, 
    length, 
    maxLength, 
    minLength,
    max: box.max.clone(), 
    min: box.min.clone(), 
  };

}


//一般用于addEventListener和removeEventListener时绑定this用，
function bindThis(callBack, that) {

  if(!that._tempEvent) that._tempEvent = [];
  if(!that._tempEventBind) that._tempEventBind = [];


  let index = that._tempEvent.indexOf(callBack);
  let currentCallBackBinde = null
  if ( index === - 1 ) {
    currentCallBackBinde = callBack.bind(that)
    that._tempEvent.push(callBack);
    that._tempEventBind.push(currentCallBackBinde);
  } else {
    currentCallBackBinde = that._tempEventBind[index];
  }

  return currentCallBackBinde;
}

//保留小数位
function retainDecimals( num, decimals ){
  let currentDecimals = Math.pow( 10, decimals );
  return parseInt( num * currentDecimals ) / currentDecimals;
}

function angleToRad (angle) {
  return angle * (Math.PI / 180);
}

function defineProperty(object, property, callBack ) {
  var otheValue = object[property]
  Object.defineProperty(object, property, {
    get: () => {
        return otheValue;
    },
    set: (newVal) => {
      
      otheValue = newVal
      callBack()
    }
  });

}

function getFileNameByUrl(url, slicePattern) {
  let startIndex = url.lastIndexOf('/');
  let fileName = url.slice(startIndex + 1, url.length);

  if(slicePattern === 'prefix') {
    let lastIndex = fileName.lastIndexOf('.');
    fileName = fileName.slice(0, lastIndex);
  }
  return fileName;
}


function getPointBaseMeshLocation(mesh, location, distanceRate = 1) {

  let nodeBox = new THREE.Box3();
  let endPos = new THREE.Vector3();
  nodeBox.expandByObject(mesh);
  nodeBox.expandByScalar(distanceRate - 1);
  nodeBox.getCenter(endPos);

  if(!location) return endPos;

  let locationAll = location.split('-');
  if (locationAll.includes('top')) {
    endPos.y = nodeBox.max.y;
  } else if (locationAll.includes('bottom')) {
    endPos.y = nodeBox.min.y;
  }

  if (locationAll.includes('left')) {
    endPos.x = nodeBox.min.x;
  } else if (locationAll.includes('right')) {
    endPos.x = nodeBox.max.x;
  }

  if (locationAll.includes('front')) {
    endPos.z = nodeBox.max.z;
  } else if (locationAll.includes('back')) {
    endPos.z = nodeBox.min.z;
  }

  return endPos;

}

function cloneObject (obj = {})  {      
  let newobj = null;  
  if (typeof (obj) == 'object' && obj !== null) {
      newobj = obj instanceof Array ? [] : {}; 
      for (var i in obj) {
          newobj[i] = cloneObject(obj[i])
      } 
  } else newobj = obj;            
  return newobj;    
}

function loadImageByFabric(url) {  
  if(!url) return
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(url, function(img) {         
      resolve(img);
    }, {crossOrigin: 'anonymous'})
  })
}


function fitFabricImage(img, fitWidth, fitHeight, pattern) {
  let imgWidth = img.width;
  let imgHeight = img.height;
  let imgWidthRatio = fitWidth / imgWidth;
  let imgHeightRatio = fitHeight / imgHeight;

  switch(pattern) {

    case "fill" :
      img.scaleX = imgWidthRatio;
      img.scaleY = imgHeightRatio;
      break;
    default: 
      img.scaleX = 1;
      img.scaleY = 1;
  }
}

// function PointInTriangle(point_0, triangle) {
//   let x0 = point_0.x;
//   let y0 = point_0.y;
//   let x1 = triangle[0].x;
//   let y1 = triangle[0].y;
//   let x2 = triangle[1].x;
//   let y2 = triangle[1].y;
//   let x3 = triangle[2].x;
//   let y3 = triangle[2].y;

//   var divisor = (y2 - y3)*(x1 - x3) + (x3 - x2)*(y1 - y3);
//   var a = ((y2 - y3)*(x0 - x3) + (x3 - x2)*(y0 - y3)) / divisor;
//   var b = ((y3 - y1)*(x0 - x3) + (x1 - x3)*(y0 - y3)) / divisor;
//   var c = 1 - a - b;

//   return a >= 0 && a <= 1 && b >= 0 && b <= 1 && c >= 0 && c <= 1;
// }

function sign (p1, p2, p3)
{
    return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
}

function point2DInTriangle (point_0, triangle)
{
    let d1, d2, d3;
    let has_neg, has_pos;

    let pt = point_0;
    let v1 = triangle[0];
    let v2 = triangle[1];
    let v3 = triangle[2];

    d1 = sign(pt, v1, v2);
    d2 = sign(pt, v2, v3);
    d3 = sign(pt, v3, v1);

    has_neg = (d1 < 0) || (d2 < 0) || (d3 < 0);
    has_pos = (d1 > 0) || (d2 > 0) || (d3 > 0);

    return !(has_neg && has_pos);
}

function getTrianglePointWeight2D(triangle, centerPos) {
  let distance_0 = centerPos.distanceTo(triangle[0]);
  let distance_1 = centerPos.distanceTo(triangle[1]);
  let distance_2 = centerPos.distanceTo(triangle[2]);
  let weight_0 = 1 / distance_0;
  let weight_1 = 1 / distance_1;
  let weight_2 = 1 / distance_2;
  
  return [weight_0, weight_1, weight_2]
}


function radToAngle (rad) {
  console.log('radToAngle', rad)
  return rad * (180 / Math.PI);
}

export {
    createElement,
    getElementWidth,
    getElementHeight,
    setDefault,
    getNodeBox,
    bindThis,
    retainDecimals,
    angleToRad,
    radToAngle,
    defineProperty,
    getFileNameByUrl,
    getPointBaseMeshLocation,
    cloneObject,
    loadImageByFabric,
    fitFabricImage,
    point2DInTriangle,
    getTrianglePointWeight2D
}