
import { fabric } from 'fabric';

// let angleSvg =`<svg 
// xmlns="http://www.w3.org/2000/svg"
// xmlns:xlink="http://www.w3.org/1999/xlink"
// width="200px" height="200px">
// <path fill-rule="evenodd"  fill="rgb(43, 131, 255)"
// d="M100.000,-0.000 C155.228,-0.000 200.000,44.772 200.000,100.000 C200.000,155.228 155.228,200.000 100.000,200.000 C44.772,200.000 -0.000,155.228 -0.000,100.000 C-0.000,44.772 44.772,-0.000 100.000,-0.000 Z"/>
// <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
// d="M98.388,153.001 C83.095,153.018 68.527,146.502 58.364,135.099 L65.545,129.725 C73.995,138.890 85.909,144.099 98.388,144.084 C123.061,144.084 143.065,124.122 143.065,99.500 C143.065,74.878 123.061,54.917 98.388,54.917 C76.613,54.917 58.485,70.468 54.524,91.045 L58.310,86.527 C59.834,84.699 62.527,84.387 64.431,85.817 C66.334,87.247 66.779,89.915 65.441,91.882 L65.166,92.245 L53.030,106.732 C52.232,107.684 51.073,108.261 49.831,108.325 C48.589,108.388 47.377,107.933 46.486,107.067 L46.173,106.732 L34.039,92.245 C32.514,90.424 32.686,87.730 34.429,86.117 C36.173,84.503 38.878,84.536 40.583,86.190 L40.892,86.527 L45.332,91.826 C49.066,65.915 71.390,46.000 98.388,46.000 C127.997,46.000 152.000,69.953 152.000,99.500 C152.000,129.047 127.997,153.001 98.388,153.001 Z"/>
// </svg>`;

let angleSvg = `<svg t="1692876478966" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="67784" width="300" height="300" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M122.88 512h81.92l-102.4-162.512593L0 512h122.88zM901.12 512H819.2l102.4 162.512593L1024 512h-122.88z" fill="#767676" p-id="67785"></path><path d="M351.383704 417.185185l-43.045926-18.962963v254.862222l192.474074 75.851852v-252.207407L351.383704 417.185185m215.988148 295.822222l-43.045926 18.962963V477.487407l192.474074-75.851851v252.207407l-149.428148 59.164444m-52.337778-423.822222l-204.420741 81.351111 204.420741 81.161482 146.773333-58.216297 2.844445-1.137777 52.717037-21.807408-199.68-81.351111z" fill="#F47F7F" p-id="67786"></path><path d="M512 933.546667A421.925926 421.925926 0 0 1 90.453333 512h28.444445A393.102222 393.102222 0 0 0 834.37037 737.848889l23.324445 16.308148A422.305185 422.305185 0 0 1 512 933.546667zM933.546667 512h-28.444445A393.102222 393.102222 0 0 0 189.62963 286.53037l-23.324445-16.308148A421.546667 421.546667 0 0 1 933.546667 512z" fill="#767676" p-id="67787"></path></svg>`;
angleSvg = 'data:image/svg+xml;base64,' + window.btoa(angleSvg)
let angleImg = document.createElement('img');
angleImg.src = angleSvg;

let isLock = false;

export default function fabricjsAdditional() {

  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = '#2B83FF';
  fabric.Object.prototype.borderColor = '#ffffff36';
  fabric.Object.prototype.cornerSize = 80;
  fabric.Object.prototype.cornerStyle = "circle";
  fabric.Object.prototype.touchCornerSize = 200;
  replaceControlAngleIcon()
  isLock = false;
  
  

}

function replaceControlAngleIcon() {

  let controlsUtils = fabric.controlsUtils;
  // return
  fabric.Object.prototype.controls.mtr = new fabric.Control({

    x: 0,
    y: -0.5,
    offsetY: -80,
    offsetX: 4,
    actionHandler: controlsUtils.rotationWithSnapping,
    cursorStyleHandler: controlsUtils.rotationStyleHandler,
    withConnection: true,
    actionName: 'rotate',
    render: renderIcon,
    cornerSize: 150
  });

}


function renderIcon(ctx, left, top, styleOverride, fabricObject) {
  // console.log('ctx123', ctx)
  var size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(angleImg, -size / 2, -size / 2, size, size);
  ctx.restore();
}

const unlockIcon = require('../../public/image/unlock.png')
const lockIcon = require('../../public/image/lock.png')

// 这个函数不会写了，图层被锁定，每个图层的锁定是个单独的事件，锁定后不可以移动缩放 

  function lockObject(){
   
  console.log(isLock)
      if (isLock == true){ 
         isLock = false;
         fabric.Image.fromURL(unlockIcon, lockcallback);

      }
      else{
        isLock = true;
        console.log(isLock)
        fabric.Image.fromURL(lockIcon, lockcallback);
        
      }
       
  }

const lockcallback = (img, isError) => {

  console.log(img);

  if (!isError) {

    fabric.Object.prototype.controls.lock = new fabric.Control({
      // x和y设置该控制点和第二列中间的控制点重合,下面的正负代表四个角
      x: 0.5,
      y: 0,
      // offsetX和offsetY设置该控制点在水平和竖直两个方向上
      // 偏移的距离（单位px）
      offsetX: 120,
      offsetY: 0,
      // 自定义的值，可忽略
      actionName: "lock",
      // 设置当点击了该控制点，鼠标弹起是执行的动作处理方法
      mouseUpHandler: () => lockObject(),
      //渲染图标
      render: renderLockIcon(img._element, 0),
      cornerSize: 150
    });

  }

};
// 先屏蔽掉
// fabric.Image.fromURL(unlockIcon, lockcallback);

// 渲染图标的方法
function renderLockIcon(image, initialAngle) {
  return function (ctx, left, top, styleOverride, fabricObject) {
      let size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle + initialAngle));
      ctx.drawImage(image, -size / 2, -size / 2, size, size);
      ctx.restore();
      
  };
}

