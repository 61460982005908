<template>
  <div class="three-container">
    <ThreeStyleEditor></ThreeStyleEditor>
  </div>
</template>

<script>
import ThreeStyleEditor from '@/components/ThreeStyleEditor.vue';


import { Col, Row } from 'vant';
import Vue from 'vue';
Vue.use( Col );
Vue.use( Row );


export default {
  name: 'EditStyle',
  components: {
    ThreeStyleEditor,
  },
  data() {
    return {
 
    }
  },

  created() {
   
  },

  mounted(){    
	},

  methods: {

    
      
  },
 
}
</script>

<style lang="scss" scoped>

.three-container {
    width: 100%;
    height: 100%;
}

/* .van-col {
  margin-top: 20px;
} */

</style>
