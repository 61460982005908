import ApiService from "../utils/ApiService";
import { fabric } from 'fabric';
import { cloneObject, loadImageByFabric, fitFabricImage } from "../utils/helper";
import { CanvasTexture, Color, RepeatWrapping, sRGBEncoding, Vector2 } from "three";
import { BumpMaker } from "./BumpMaker";

export class MaterialMaker {
  constructor(object3d) {

    this.object3d = object3d;
    this.target = object3d.activeMesh;   

    this.textureFullCanvasList = {};
    this.texturesList = {};
    this.textureCanvases = [];

    this.bumpFullCanvas = null;
    this.bumpImages = [];
    this.bumpCanvas = null;
    this.bumpMaker = null;
    this.color = null;

    this.addTexturesCanvases();
    // this.addBumpCanvases();
  }

  async loadMaterialJson(json) {
  
    let materialInfo = await ApiService.getJson(json);
    this.loadMaterial(materialInfo)   
  }

  async loadMaterial(materialInfo) {
    this.clearAllTextures();
    this.fullCanvasClearColor();
    if(this.bumpMaker) this.bumpMaker.clear();
    if(materialInfo.nodeMaterials) this.loadNodeMaterials(materialInfo.nodeMaterials);
    if(materialInfo.textures) this.loadTextures(materialInfo.textures);
    if(materialInfo.bump) this.loadBump(materialInfo.bump);
    if(materialInfo.modelColor) this.setColorByAreaId(materialInfo.modelColor.areaId, materialInfo.modelColor.color);

    return materialInfo;
  }
  

  async loadTextures(params=[]) {  

    for(let i=0; i<params.length; i++) {
      let currentParam = params[i];
      let textureImg = await loadImageByFabric(currentParam.url);
   
      currentParam.areaIds.forEach(areaId => {        
        this.texturesList[areaId] = textureImg;        
        this.addImgToTextureCanvas(textureImg, areaId)
      })
    }
    
    this.mergeTexturesCanvas();
    this.renderTexturesToMesh();
    this.object3d.dispatchEvent({ type: "changeMap", target: this.target });

    // let textures = this.target.userData.material.textures;
    // textures.forEach(item => {      
    // });
 
  }

  clearAllTextures() {

    if(Object.keys(this.texturesList).length === 0) return;
    
    this.textureCanvases.forEach(canvas => canvas.clear());

    for( let key in this.texturesList) {
      let texture = this.texturesList[key];
      texture.dispose();
      delete this.texturesList[key];
    }

    
  }

  addTexturesCanvases() {

    if(!this.target.userData.textures) return;

    let { nodes, fullSize } = this.target.userData.textures;
    nodes.forEach((item => {
      let {pixelRate, nodeName} = item;

      let pixelWidth = parseInt(fullSize * pixelRate.x);
      let pixelHeight = parseInt(fullSize * pixelRate.y);

      let canvas = new fabric.Canvas(); 
      let canvasEl = canvas.getElement();
   
      canvas.setWidth(pixelWidth);
      canvas.setHeight(pixelHeight);
      canvasEl.width = pixelWidth;
      canvasEl.height = pixelHeight;
      canvas.userData = cloneObject(item);
      canvas.userData.fullSize = fullSize;
      this.textureCanvases.push(canvas);
      this.addTexturesFullCanvas(fullSize, nodeName);
    }))

  }

  addTexturesFullCanvas(fullSize, nodeName) {
   
    if(this.textureFullCanvasList[nodeName]) return;
    let canvas = document.createElement('canvas');
    canvas.width = fullSize;
    canvas.height = fullSize;
    this.textureFullCanvasList[nodeName] = canvas;
  }

  mergeTexturesCanvas() {   
    
    this.textureCanvases.forEach( textureCanvas => {
      let { nodeName, offsetRate, fullSize, areaId, fit } = textureCanvas.userData;
   
      let crrentImg = this.texturesList[areaId];

      if(crrentImg) fitFabricImage(crrentImg, textureCanvas.width, textureCanvas.height, fit)
     
      let fullTextureCanvas = this.textureFullCanvasList[nodeName];

      textureCanvas.renderAll()
      this.fullCanvasDraw(fullTextureCanvas, textureCanvas, offsetRate);
    })
  }

  
  renderTexturesToMesh() {

    for(let nodeName in this.textureFullCanvasList) {
      let fullCanvas = this.textureFullCanvasList[nodeName];
      let canvasTexture = new CanvasTexture(fullCanvas); 
      let currentTarget = this.target.getObjectByName(nodeName);
      canvasTexture.encoding = sRGBEncoding;
      canvasTexture.wrapT = RepeatWrapping;
      canvasTexture.repeat.y = -1
      currentTarget.material.map = canvasTexture;
      currentTarget.material.needsUpdate = true;
    }
  }

  fullCanvasClearColor() {

    for(let key in this.textureFullCanvasList) {
      let fullCanvas = this.textureFullCanvasList[key];
      let fullCtx = fullCanvas.getContext('2d');
      fullCtx.clearRect( 0, 0, fullCanvas.width, fullCanvas.height);
    }
   
  }

  fullCanvasDraw(fullCanvas, canvas, pixelOffset) {
    let fullCtx = fullCanvas.getContext('2d');    
    fullCtx.drawImage(
      canvas.getElement(), 
      pixelOffset.x * fullCanvas.width, 
      pixelOffset.y * fullCanvas.height, 
      canvas.width, 
      canvas.height
    )
  }

  addImgToTextureCanvas(img, areaId) {
    this.textureCanvases.forEach(item => {
      if(item.userData.areaId === areaId) item.add(img);
    })
  }

  addBumpCanvases() {

    let { nodes, fullSize, pixelRate } = this.target.userData.bump;

    let pixelWidth = fullSize * pixelRate.x;
    let pixelHeight = fullSize * pixelRate.y;
    let canvas = new fabric.Canvas(); 
    let canvasEl = canvas.getElement()
    this.bumpCanvas = canvas;  
    canvas.setWidth(pixelWidth);
    canvas.setHeight(pixelHeight);
    canvasEl.width = pixelWidth;
    canvasEl.height = pixelHeight;


    canvas.userData = {
      bump: cloneObject(this.target.userData.bump)
    };

    let bumpFullCanvas = document.createElement('canvas');
    let bumpFullCanvasCtx = bumpFullCanvas.getContext('2d'); 
    bumpFullCanvas.width = fullSize;
    bumpFullCanvas.height = fullSize;
    bumpFullCanvasCtx.fillStyle = "rgb(128,128,255)";
    bumpFullCanvasCtx.fillRect(0, 0, bumpFullCanvas.width, bumpFullCanvas.height)
    this.bumpFullCanvas = bumpFullCanvas;
  }

  async loadBump(param) {

    let { fullSize, pixelRate, offsetRate } = this.target.userData.bump;

    if( !this.bumpMaker ) {

      let offsetLeft = fullSize * offsetRate.x;
      let offsetTop = fullSize * offsetRate.y;
      let pixelWidth = fullSize * pixelRate.x;
      let pixelHeight = fullSize * pixelRate.y; 
 
      this.bumpMaker = new BumpMaker(this.target, {
        pixelWidth, 
        pixelHeight,
        pixelOffset:{x: offsetLeft, y: offsetTop}
      })
  
    }

    await this.bumpMaker.addImage(param.url);
    this.bumpMaker.apply();

  }

  loadNodeMaterials(materials) {

    materials.forEach((item) => {
      item.nodeNames.forEach(nodeName => {
        let target = this.object3d.getObjectByName(nodeName);
        if(!target || !target.material) return;
        if(item.metalness >= 0)  target.material.metalness = item.metalness;
        if(item.roughness >= 0)  target.material.roughness = item.roughness;
      })
      
    })

  }

  setColorByAreaId(areaId, color = '#fff') {
    let { colorAreas } = this.target.userData
    if(!colorAreas) return;
    let areaIndex = colorAreas.findIndex(item => {return item.areaId=== areaId});
    if(areaIndex === -1) return;

    colorAreas[areaIndex].nodeNames.forEach(nodeName => {
      let targetNode = this.target.getObjectByName(nodeName);
      if(targetNode && targetNode.material) targetNode.material.color = new Color(color);
    })
    //  console.log('colorAreas', colorAreas, areaId)

  }

  // tileImageToArea(img, startPos, endPos, pattern) {
    
  //   let imgs = []
  //   let imgWidth = img.width;
  //   let left = startPos.x;
  //   let top = startPos.y;
    
  //   // debugger
  //   let areaSize = new Vector2( endPos.x - startPos.x, endPos.y - startPos.y );
  //   img.scaleToHeight(areaSize.y);
  //   imgWidth *= img.scaleX;

  //   let count = Math.ceil(areaSize.x / imgWidth);
  //   for(let i=0; i<count; i++) {
  //     let crrentImg = img;
  //     if(i > 0) {
  //       img.cloneAsImage(function (cloneObject){
  //         crrentImg = cloneObject;
  //       });
  //       left += imgWidth;
  //     }
  //     crrentImg.set({ left, top})
  //     imgs.push(crrentImg)
  //   }
  //   return imgs

  // }

  // mergeBumpCanvas() {
    
  //   let { nodeName, offsetRate, fullSize } = this.bumpCanvas.userData.bump;

  //   this.bumpCanvas.renderAll();
  //   console.log('asf', this.bumpCanvas.getElement().toDataURL())
  //   this.fullCanvasDraw(this.bumpFullCanvas, this.bumpCanvas, offsetRate);
  // }


  // renderBumpToMesh() {

  //   let { nodeName } = this.target.userData.bump;

  //   let canvasTexture = new CanvasTexture(this.bumpFullCanvas); 
  //   let currentTarget = this.target.getObjectByName(nodeName);
  //   canvasTexture.encoding = sRGBEncoding;
  //   canvasTexture.wrapT = RepeatWrapping;
  //   canvasTexture.repeat.y = -1
  //   currentTarget.material.normalMap = canvasTexture;
  //   currentTarget.material.needsUpdate = true;
  // }

  
}