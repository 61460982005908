import axios from 'axios';

const http = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 60 * 1000,
    // baseURL:'https://api.shop.tczhilian.com/admin-server',//生产环境
    baseURL:'https://api.flea.tczhilian.com/admin-server',//测试环境

})

export default  http;
