<template>
  <div class="edit-style-page">    
 
    <van-col class="three-el">
        <ThreeStyleEditor></ThreeStyleEditor>
    </van-col> 

    <van-col id="step2" class="edit-tools" ref="editToolsEl" >
      <van-col span="24" class="pull-down-hide"
         
        @touchstart.native.prevent="touchstartEvent"
        @touchend.native.prevent="touchendEvent"
        @touchmove.native.prevent="touchmoveEvent"
        @click="showEditTools(!isShowEditTools)"
      >
        <van-col span="4" class="down-hide-icon">
      </van-col>
      </van-col>
      <EditNav v-show="!isShowCataloguePanel" :currentPanelIndex='currentPanelIndex' :panelInfos = 'panelInfos'></EditNav>
      <van-col v-show="!isShowCataloguePanel" span="24" class="tools-body">
        <!-- <TypePanel v-show="currentPanelIndex === panelInfos[0].key"></TypePanel> -->
        <!-- <MaterialPanel v-show="currentPanelIndex === panelInfos[1].key"></MaterialPanel> -->

        <!-- <SelectModelPanel v-show="currentPanelIndex === panelInfos[0].key" />
        <SelectTemplatePanel v-show="currentPanelIndex === panelInfos[1].key"></SelectTemplatePanel>
        <SelectImagePanel v-show="currentPanelIndex === panelInfos[2].key"></SelectImagePanel>
        <SelectTextPanel v-show="currentPanelIndex === panelInfos[3].key"></SelectTextPanel>
        <AddQRcode v-show="currentPanelIndex === panelInfos[4].key"></AddQRcode> -->
        <component  v-for="(item, index) in panelInfos" 
          :is="item.component" 
          :key="item.component" 
          v-show="currentPanelIndex === index"/>


        <!-- <controlImage v-show="currentPanelIndex === panelInfos[4].key"></controlImage> -->

        


      </van-col>   
      <CataloguePanel v-show="isShowCataloguePanel" :panelInfos="panelInfos" ></CataloguePanel>   
     
    </van-col>

    <ReplaceImagePanel  v-if="isShowReplaceImage"/>

    <ModalMessage />
    <!-- <PageNav /> -->

  </div>

</template>

<script>
import ThreeStyleEditor from '@/components/ThreeStyleEditor.vue';
// import TypePanel from './EditStylePlane/TypePanel.vue';
import MaterialPanel from './EditStylePlane/MaterialPanel.vue';//注释掉选择材质
import EditNav from './EditStylePlane/EditNav.vue';
import SelectImagePanel from './EditStylePlane/SelectImagePanel.vue';
import SelectTextPanel from './EditStylePlane/SelectTextPanel.vue';
import SelectTemplatePanel from './EditStylePlane/SelectTemplatePanel.vue';
import AddQRcode from './EditStylePlane/AddQRcode.vue';
import CataloguePanel from './EditStylePlane/CataloguePanel.vue';
import ModalMessage from './EditStylePlane/ModalMessage.vue';
import ReplaceImagePanel from './EditStylePlane/ReplaceImagePanel.vue';
import SelectModelPanel from './EditStylePlane/SelectModelPanel.vue';
// import controlImage from './EditStylePlane/ImageControlUI.vue';
// import PageNav from './PageNav.vue';

import { Col, Row } from 'vant';
import Vue from 'vue';
Vue.use( Col );
Vue.use( Row );


export default {
  name: 'EditStyle',
  components: {
    ThreeStyleEditor,
    EditNav,
    // TypePanel,
    MaterialPanel,
    SelectTemplatePanel,
    SelectImagePanel,
    SelectTextPanel,
    AddQRcode,
    CataloguePanel,
    ModalMessage,
    ReplaceImagePanel,
    SelectModelPanel
    // controlImage,
    // PageNav
  },
  data() {
    return {
      currentPanelIndex: 0,
      editToolsState: 'normal', 
      touchPress: false,
      touchStartPos: null,
      isShowCataloguePanel: false,
      isShowReplaceImage: false,
      panelInfos:[
        // {key: 0, title: '选择类型'},
      
        { title: '选择产品', component: 'SelectModelPanel'},
        { title: '选择材质', component: 'MaterialPanel'},
        { title: '选择模板', component: 'SelectTemplatePanel'},
        { title: '选择图片', component: 'SelectImagePanel'},
        { title: '选择文字', component: 'SelectTextPanel'},
        { title: '营销二维码', component: 'AddQRcode'},
        // {key: 4, title: '图像控制'},
      ]



    }
  },

  created() {
    this.$eventBus.$on("changePanelIndex", this.changePanelIndex);
    this.$eventBus.$on("changePanelIndexByName", this.changePanelIndexByName);
    this.$eventBus.$on("show", this.changePanelIndex);
    this.$eventBus.$on("selectElement", this.selectElement);
    this.$eventBus.$on('changeEditToolsState', this.changeEditToolsState);
    this.$eventBus.$on('showReplaceImage', this.showReplaceImage);
    this.$eventBus.$on("changePanel", (panelName) => {
      this.isShowCataloguePanel = false;
      this.changePanelIndexByName(panelName)
      this.$refs.editToolsEl.$el.classList.remove('expand');
      this.$refs.editToolsEl.$el.classList.add('transitionAnimation'); 
      let myEvent = new Event('resize');
        window.dispatchEvent(myEvent);
      
    });
  },

  mounted(){    
    if(this.$route.query.modelId && this.$route.query.enableSwitchModel!=="true") this.changePanelIndexByName('MaterialPanel');
	},

  methods: {

    changePanelIndex(index) {      
      if(index < 0 || index >= this.panelInfos.length) {
        console.error('changePanelIndex: index 错误');
        return;
      }   
      this.currentPanelIndex = index;
      setTimeout(()=> this.dispatchWindowResizeEvent())
    },

    changePanelIndexByName(panelName) {      
      let index = this.panelInfos.findIndex(item => {return item.component === panelName});      
      this.currentPanelIndex = index;
      setTimeout(()=> this.dispatchWindowResizeEvent())
    },

    showEditTools(isShow) {
      this.isShowEditTools = isShow;  
    },

    touchstartEvent(e){
      let { targetTouches } = e;

      this.touchPress = true;
      this.touchStartPos = {
        x: targetTouches[0].pageX,
        y: targetTouches[0].pageY
      }   
      this.editToolsElStartInfo = this.$refs.editToolsEl.$el.getBoundingClientRect();
      this.$refs.editToolsEl.$el.style.height = this.editToolsElStartInfo.height + 'px';
      this.$refs.editToolsEl.$el.classList.remove('expand');
      this.$refs.editToolsEl.$el.classList.remove('collapse');
      this.$refs.editToolsEl.$el.classList.remove('transitionAnimation');
    },

    touchmoveEvent(e) {
      if(!this.touchPress) return;
      let { targetTouches } = e;
      let editToolsEl =  this.$refs.editToolsEl.$el;

      this.touchmoveOffset = {
        x: targetTouches[0].pageX - this.touchStartPos.x,
        y: targetTouches[0].pageY - this.touchStartPos.y,
      }
      let currrentHeight = this.editToolsElStartInfo.height - this.touchmoveOffset.y;
      if( currrentHeight < document.body.clientHeight * 0.06 || currrentHeight > document.body.clientHeight * 0.8) return; 
      editToolsEl.style.height = currrentHeight + 'px';

    },

    touchendEvent() {

      let currrentHeightRatio = (this.editToolsElStartInfo.height - this.touchmoveOffset.y) / document.body.clientHeight;
      this.$refs.editToolsEl.$el.style.removeProperty('height');
      this.isShowCataloguePanel = false;     
      this.$refs.editToolsEl.$el.classList.add('transitionAnimation');
      let lastState  = this.editToolsState;
 
      if(currrentHeightRatio > 0.6) {
        this.$refs.editToolsEl.$el.classList.add('expand');  
        this.isShowCataloguePanel = true;
        this.$eventBus.$emit('expandThree', false);
        this.editToolsState = 'expand';
      } else if(currrentHeightRatio < 0.3) {       
        this.$refs.editToolsEl.$el.classList.add('collapse');  
        this.$eventBus.$emit('expandThree', true); 
        this.editToolsState = 'collapse';     
      } else {
        this.$eventBus.$emit('expandThree', false);
        this.editToolsState = 'normal';
      }

      if(lastState !== this.editToolsState) this.$eventBus.$emit('editToolsStateChange', this.editToolsState);

      this.touchPress = false;
      this.touchStartPos = null;
      this.editToolsElStartInfo = null;
    
    },

    selectElement(picked) {
      if(!picked ) return;     
      
      if ( picked.type === 'text'){

        this.changePanelIndexByName('SelectTextPanel');    

      }
      // else if (picked.type === 'image'){
      //   this.changePanelIndex(this.panelInfos[4].key);    
      // }
      else
      {
         return;

      }
       

    },
    dispatchWindowResizeEvent () {
      let myEvent = new Event('resize');
          window.dispatchEvent(myEvent);
    },

    changeEditToolsState(state) {

      if(state === 'normal') {
        console.log('changeEditToolsState', state)
        this.$refs.editToolsEl.$el.classList.remove('collapse');  
        this.$refs.editToolsEl.$el.classList.remove('expand'); 
        this.$refs.editToolsEl.$el.classList.remove('transitionAnimation');
        // this.$eventBus.$emit('expandThree', false);
     
      }
      
    },

    showReplaceImage(isShow) {
      this.isShowReplaceImage = isShow
      console.log('showReplaceImage', isShow)
      // this.contentPanelState = value
    }
      
  },
 
}
</script>

<style lang="scss" scoped>
.edit-style-page {
  width: 100%;
  height: 100%;

  .three-el {
    width: 100%;
    height: 57%;
    padding: 10px;
    // margin-top: 12%
  }
}
.edit-tools {

  width: 96%;
  height: 41vh;
  margin-left: 2%;
  // margin-top: 16px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  bottom: 2vh;
  background: #fff;
  z-index: 1;

  &.transitionAnimation {
    transition: height 0.3s;
    -moz-transition: height 0.3s;
    -webkit-transition: height 0.3s;
    -o-transition:height 0.3s;
  } 

  &.expand {
    height: 80vh;
  } 

  &.collapse {
    height: 5vh;
  }

  .pull-down-hide {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .down-hide-icon {
      width: 50px;
      height: 14px;
      border-radius: 476px;
      background: #E5E5E5;

    }
  } 
}




.tools-body {
  height: calc(100% - 78px);
  overflow: auto;
}



/* .van-col {
  margin-top: 20px;
} */

</style>
