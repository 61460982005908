<template>
  <van-Col span="24" class="addImage-panel">
    <van-tabs v-model="tabsaCtive">

      <van-tab title="内容" class="context-body">
        <van-Row class="text-style">
          <van-Col span="8" class="style-item" :class="{ active: fontStyle.textAlign === 'left' }" @click="changeFontStyle('textAlign', 'left')">
            <div class="style-icon font-letf-align"></div>
            左对齐
          </van-Col>
          <van-Col span="8" class="style-item" :class="{ active: fontStyle.textAlign === 'center' }" @click="changeFontStyle('textAlign', 'center')">
            <div class="style-icon font-center-align"></div>
            居中
          </van-Col>
          <van-Col span="8" class="style-item " :class="{ active: fontStyle.textAlign === 'right' }"
            @click="changeFontStyle('textAlign', 'right')">
            <div class="style-icon font-right-align"></div>
            右对齐
          </van-Col>
        </van-Row>


        <van-Row class="text-input">
          <van-Col span="2"></van-Col>
          <van-Col span="19">
            <van-field placeholder="请输入文字（支持多行）" :input-align="fontStyle.textAlign" type="textarea" rows="5" size="large"
              v-model="fontStyle.text" />
          </van-Col>
          <van-Col span="3" class="add-btn" v-if="!activeTextEl" @click="addText">
            <!-- <van-icon name="add-o" size="30" color="#2B83FF" />  -->
            <span style="color:#2B83FF">添加</span>
          </van-Col>

          <van-Col span="3" class="add-btn" v-if="activeTextEl" @click="changeText">
            <span style="color:#2B83FF">修改</span>
            <!-- <van-icon name="edit" size="30" color="#2B83FF" />  -->
          </van-Col>
        </van-Row>


      </van-tab>

      <van-tab title="样式">
        <van-Row class="text-style">
          <van-Col span="12" class="style-item" :class="{ active: fontStyle.bold }" @click="changeFontStyle('bold')">
            <div class="style-icon style-icon-bold"></div>
            粗体
          </van-Col>
          <van-Col span="12" class="style-item" :class="{ active: fontStyle.italic }" @click="changeFontStyle('italic')">
            <div class="style-icon style-icon-italic"></div>
            斜体
          </van-Col>
        </van-Row>
        <van-Row class="fontsize-input">
          <van-Col span="4">字号</van-col>
          <van-col class="control-input" span="16">
            <van-slider v-model="fontStyle.size" max="200" min="10" @change="changeTextSize" />
          </van-col>
          <van-col span="4">{{ fontStyle.size }} pt</van-col>
        </van-Row>
      </van-tab>

      <van-tab title="字体">
        <!-- <van-Row class="fontsize-input" >
          <van-Col span="4">字号</van-col>
          <van-col class="control-input" span="16">
            <van-slider v-model="fontStyle.size" max="200" min="10" @change="changeTextSize" /> 
          </van-col>
          <van-col span="4">{{ fontStyle.size }} pt</van-col>
        </van-Row> -->
        <van-Row v-if="textFonts" class="fonts-body">
          <van-col class="fonts-body-item" :class="{ activited: currentText === index }" v-for="(font, index) in textFonts"
            :key="font.name">
            <van-image width="100%" height="100%" fit="contain" radius="15" @click="loadFont(font, index)"
              :class="{ invalid: !activeTextEl }" :src="font.thumbnailPicUrl" />
          </van-col>
        </van-Row>
      </van-tab>

      <van-tab title="颜色">
        <van-row class="colors-body">
          <van-col class="colors-body-item" :class="{ white: index === 0 }" v-for="(colorItem, index) in textColors"
            :style="{ background: colorItem }" @click="changeFontStyle('color', colorItem)" :key="index">
          </van-col>


        </van-row>

      </van-tab>
    </van-tabs>

  </van-Col>
</template>

<script>

import { Col, Row, Tab, Tabs, Field, Icon, Slider } from 'vant';
import Vue from 'vue';
// import axios from 'axios';
import APIServe from '@/plugins/APIServe.ts';

Vue.use(Slider);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Icon);

let defaultFontStyle = {
  text: null,
  bold: false,
  italic: false,
  vertical: false,
  color: '#000',
  name: null,
  size: 30,
  textAlign: "left",
}

export default {
  name: 'SelectTextPanel',
  data() {
    return {
      text: null,
      hotImages: null,
      tabsaCtive: 0,
      textFonts: null,
      textColors: null,
      activeTextEl: null,
      fontStyle: { ...defaultFontStyle },
      currentText: null,
    }
  },
  methods: { 
   
    changeFontStyle(type, info) {

      if (type === 'color') {
        this.fontStyle.color = info;

      } else if(type === 'textAlign'){
        this.fontStyle.textAlign = info;
      } else {
        // 竖排功能置灰
        this.fontStyle[type] = type === 'vertical' ? false : !this.fontStyle[type];
      }
      if (this.activeTextEl) {
        this.$eventBus.$emit("changeTextElement", this.activeTextEl, { ...this.fontStyle })
      }
    },

    addText() {
      this.$eventBus.$emit("addTextElement", { ...this.fontStyle });
    },

    changeText() {
      console.log('changeText', this.activeTextEl)
      if (this.activeTextEl) this.$eventBus.$emit("changeTextElement", this.activeTextEl, { ...this.fontStyle });
    },

    changeTextSize() {
      if (this.changeTextSizeTime) clearInterval(this.changeTextSizeTime);
      this.changeTextSizeTime = setTimeout(() => {
        this.changeText()
      }, 300)
    },

    loadFont(item, index) {
      if (item.name === this.fontStyle.name) return;
      this.currentText = index
      this.fontStyle.name = item.name;
      this.$eventBus.$emit("loadOnlineFont", this.activeTextEl, item);
    },

    selectTextEvent(element) {

      if (!element && this.activeTextEl) {
        this.activeTextEl = null;
        this.fontStyle.text = null;
        return;
      }
      if (!element || element.type !== 'text') return;
      if (element.type !== 'text' && this.activeTextEl) {
        this.resetFontStyle();
        return
      }


      this.activeTextEl = element;
      this.fontStyle.size = element.fontSize / element.userData.ptToPxRatio;
      this.fontStyle.bold = element.fontWeight === 'bold';
      this.fontStyle.italic = element.fontStyle === 'italic';
      this.fontStyle.color = element.fill;
      this.fontStyle.text = element.text;
      this.fontStyle.textAlign = element.textAlign;
    },

    resetFontStyle() {
      Object.assign(this.fontStyle, defaultFontStyle);
    }

  },

  created() {
    this.$eventBus.$on("selectElement", this.selectTextEvent);
  },

  mounted() {

    APIServe.post('/design3dFont/getAllDesign3dFontAndColor').then((e) => {
      let resp = e.data;
      if (resp.code !== '200') return;
      this.textFonts = resp.data.lists;
      this.textColors = resp.data.colors;
      this.$eventBus.$emit("updateModelColorLists", resp.data.colors);
    })


    // axios.get('/cup-demo/mock/fonts.json').then((resp)=> {
    //     this.textFonts = resp.data.lists;   
    //     this.textColors = resp.data.colos;  
    //     console.log('FontStyl', this.textFonts)
    //   })
  },

}
</script>

<style lang="scss" scoped>
.context-body {
  padding: 10px 20px;
}

.text-input {
  box-sizing: border-box;
  // border-bottom: 1px solid #EEEEEE;
  height: 50px;

  .van-field {
    height: 100%;
    font-size: 20px;
  }

  .add-btn {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text-style {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  .style-item {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: #2B83FF;

      .style-icon-bold {
        background-image: url("@/assets/images/font-bold-active.svg");
      }

      .style-icon-italic {
        background-image: url("@/assets/images/font-italic-active.svg");

      }

      .style-icon-vertical {
        background-image: url("@/assets/images/font-vertical-active.svg");
        }




      .font-letf-align-s {
        background-image: url("@/assets/images/font-letf-align-s.svg");
      }

      .font-center-align-s {
        background-image: url("@/assets/images/font-center-align-s.svg");
      }

      .font-right-align-s {
        background-image: url("@/assets/images/font-right-align-s.svg");
      }
    }

    &.disable {
      color: #e0e0e0;

      .style-icon-vertical {
        background-image: url("@/assets/images/font-vertical-disable.svg");
      }
    }
  }

  .style-icon {
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;

    &.style-icon-bold {
      background-image: url("@/assets/images/font-bold.svg");
    }

    &.style-icon-italic {
      background-image: url("@/assets/images/font-italic.svg");
    }

    &.style-icon-vertical {
      background-image: url("@/assets/images/font-vertical.svg");
    }


    &.font-letf-align {
      background-image: url("@/assets/images/font-letf-align.svg");
      width: 30px;
    }

    &.font-center-align {
      background-image: url("@/assets/images/font-center-align.svg");
      width: 30px;
    }

    &.font-right-align {
      background-image: url("@/assets/images/font-right-align.svg");
      width: 30px;
    }


  }

}

.fonts-body {
  padding: 10px 20px 0px;
  margin-top: 10px;

  .fonts-body-item {
    margin: 1px 0.5%;
    width: 31%;
    height: 74px;
    position: relative;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    border-radius: 10px;

    .invalid {
      pointer-events: none;
      opacity: 0.3;
    }

    &.activited {
      border-color: #2b83ff;
    }
  }

}

.fontsize-input {
  padding: 10px 30px 0px;
  margin-top: 20px;
  align-items: center;
  display: flex;

  .control-input {
    padding: 0px 10px;
  }
}

.colors-body {
  padding: 10px 30px 0px;

  .colors-body-item {
    width: calc(25% - 8px);
    height: 59px;
    border-radius: 10px;
    margin: 4px;
    background: #FFFFFF;
    // box-sizing: border-box;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);

    &.white {
      border: 1px solid #e4e4e4;
    }
  }
}

::v-deep {
  .van-tabs__content {
    height: calc(41vh - 122px);
    overflow: auto;
  }
}
</style>
