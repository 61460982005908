import { Box3, Matrix4, Object3D, Vector3 } from 'three';
import { getPointBaseMeshLocation } from '../utils/helper';

export class AttachObject extends Object3D {
  constructor() {
    super();
    // this.target = target;
    this.name = 'attachObject'
    this.location = 'top';
    this.attachScale = 1.05;
    this.attachTarget = null;
    this.type = 'attachObject'
    // this.copy(attachObject);
  }


  attachTo(mesh, location) {
    this.target = mesh;
    if(location) this.location;
    this.setScale();
    this.setPosisiton();
    mesh.add(this);
    
  }

  setScale() {
    let targetBox = new Box3();
    targetBox.setFromObject(this.target);
    let targetSideLength_x = targetBox.max.x - targetBox.min.x;
    let targetSideLength_z = targetBox.max.z - targetBox.min.z;
    let targetMaxSide = Math.max(targetSideLength_x, targetSideLength_z);

    let oneselfBox = new Box3();
    oneselfBox.setFromObject(this);
    let oneselfSideLength_x = oneselfBox.max.x - oneselfBox.min.x;
    let oneselfSideLength_z = oneselfBox.max.z - oneselfBox.min.z;
    let oneselfMaxSide = Math.max(oneselfSideLength_x, oneselfSideLength_z);

    let targetRatio = (targetMaxSide / oneselfMaxSide) * this.attachScale;
    let invertScale = new Vector3().setFromMatrixScale(this.target.matrixWorld.clone().invert());
    invertScale.multiplyScalar(targetRatio);  
    this.scale.copy(invertScale);  
  }

  setPosisiton() {
    let targetPos = getPointBaseMeshLocation(this.target, this.location)
    let endPos = this.target.worldToLocal(targetPos);
    this.position.copy(endPos);    
  }

  showNode(nodeName) {
    let target = this.getObjectByName(nodeName);
    if(target) target.visible = true
  }
  hideNode(nodeName){
    let target = this.getObjectByName(nodeName);
    if(target) target.visible = false;
  }
  
}