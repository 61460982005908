<template>
  <div>
      <van-dialog v-model="praShow" mode="center" confirmButtonText="关闭"  :showCancelButton="false" :beforeClose="beforeCloseFn">
          <div class="img-box">
            <img :src="pickObj[qrcodeType]" />
          </div>
          <p style="font-size:12px;color:#010101;padding:0 30px">
            * 长按图片保存到手机！
          </p>
      </van-dialog>
      <div class="box">
        <van-empty description="暂无二维码，请联系商务~" />
        <van-button plain type="info" size="small" @click="Business(qrcodeType)">联系商务</van-button>
      </div>
  </div>
</template>

<script>
import { Empty } from 'vant';
import Vue from 'vue';
Vue.use( Empty );
export default {
    props:{
        qrcodeType:{
            type:String,
            default:"officialAccounts"
        }
    },

  data() {
    return {
    praShow:false,//小程序
      pickObj:{
        officialAccounts:'https://oss.shop.tczhilian.com/static/images/businessQrCode.jpg',
        miniProgram:'https://oss.shop.tczhilian.com/static/images/businessQrCode.jpg',
        websiteList:'https://oss.shop.tczhilian.com/static/images/businessQrCode.jpg',
      }
    };
  },
  methods: {
    Business(type) {
      this.praShow = true;
      console.log(type);
    },
    beforeCloseFn(action, done){
       if (action === 'confirm') {
        done()
      } else {
        // 如果点击了取消按钮，则阻止对话框关闭
        done()
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.box{
    display: flex;
    flex-direction: column;
}
.img-box{
    width: 250px;
    height: 250px;
    padding: 20px 0 0;
    margin: 0 auto;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
::v-deep{
    .van-empty{
        padding: 0;
    }
    .van-empty__image {
        width: 70px;
        height: 70px;
    }
    .van-empty__description {
         margin-top: 0; 
         padding: 0; 
         line-height: 20px; 
    }
    .van-button--small {
        border-radius: 16px
      }
    
}
</style>
