<template>
  <van-Col span="24" class="addImage-panel">
    <van-tabs v-model="tabsaCtive" @change="tabsChange" sticky>
      <template v-for="(platImg,index) in PlateTableList" >
        <van-tab :title="platImg.tagName" :key="index" :name="'plate'+index" v-if="!platImg.tabName">
          <van-Row class="tab-content">
            <van-loading type="spinner" color="#1989fa" v-if="loading0"/>
            <van-Col class="image-item" v-for="(image, index) in platImg.list" :key="index"> 
              <van-image     
                  width="100%"
                  height="100%"
                  fit="cover"
                  :src="image.thumbnailPicUrl"
                  @click="addImage(image, index)"
                />
                <div class="imageName">{{ image.name }}</div>
            </van-Col>
          </van-Row>
        </van-tab>
        <van-tab :title="platImg.tagName" :key="index" name="my" v-else>
           <van-Row class="tab-content" id="scroll">
              <van-row span="24" class="content-edit-bar">
                  <p class="content-edit-icon"  :class="{active: isEditMeImage}" @click="showEditUi(!isEditMeImage)"> {{isEditMeImage ? '退出管理':"管理"}} </p>
              </van-row>
              <van-Row>
                  <van-loading type="spinner" color="#1989fa" v-if="loading"/>
                <van-col v-if="!isEditMeImage" class="image-item upload-image" @click="uploadImage">
                  <input type="file" class="fileInput" ref="fileInputEl" accept=".jpg,.png" @change="fileInputChange">
                </van-col>
            
                <van-Col class="image-item" v-for="(image, index) in meTable.images" :key="index">  
                  <van-col v-if="isEditMeImage" class="delete-icon" @click="deleteMeIamge(image)" ></van-col>
                  <van-image     
                      width="100%"
                      height="100%"
                      fit="cover"
                      :src="image.url || image.bigPicUrl||image.thumbnailPicUrl"
                      @click="addImage(image, index)"
                    />
                    <div class="imageName">{{ image.name }}</div>
                </van-Col>      
              </van-Row>
            </van-Row>
        </van-tab>
      </template>
      <!-- <van-tab title="我的">
        <van-Row class="tab-content" >
          
          <van-row span="24" class="content-edit-bar">
              <p class="content-edit-icon"  :class="{active: isEditMeImage}" @click="showEditUi(!isEditMeImage)"> {{isEditMeImage ? '退出管理':"管理"}} </p>
          </van-row>
           <van-Row>
              <van-loading type="spinner" color="#1989fa" v-if="loading"/>
            <van-col v-if="!isEditMeImage" class="image-item upload-image" @click="uploadImage">
              <input type="file" class="fileInput" ref="fileInputEl" accept=".jpg,.png" @change="fileInputChange">
            </van-col>
        
            <van-Col class="image-item" v-for="(image, index) in meTable.images" :key="index">  
              <van-col v-if="isEditMeImage" class="delete-icon" @click="deleteMeIamge(image)" ></van-col>
              <van-image     
                  width="100%"
                  height="100%"
                  fit="cover"
                  radius="15"
                  :src="image.url || image.bigPicUrl||image.thumbnailPicUrl"
                  @click="addImage(image, index)"
                />
            </van-Col>      
           </van-Row>
        </van-Row>

      </van-tab> -->
    </van-tabs>
  
  </van-Col>
</template>

<script>

import { Col, Row, Tab, Tabs, Dialog } from 'vant';
import Vue from 'vue';
// import axios from 'axios';
import APIServe from '@/plugins/APIServe.ts';

Vue.use( Col );
Vue.use( Row );
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);


export default {
  name: 'SelectImagePanel',
  data() {
    return {
      hotTable: {
        images: [],
        url: "/design3dImg/getPageDesign3dImg",
        current: 1,
        pages: -1, 
        pageSize:20,
        uploadMark: 0,
        total:-1,
                
      },
      meTable: {
        images: [],
        url: "/design3dImg/getPageDesign3dImg",
        current: 1,
        pages: -1, 
        pageSize:20,
        uploadMark: 1,
        total:-1
      },
      PlateTableList: [],
      meImages: null,
      isEditMeImage: false,
      tabsaCtive:'plate0',
      loading:true,
      loading0:true,

    }
  },
  methods: {

    addImage(imageInfo) {
      let temp = {...imageInfo}
      temp.url = imageInfo.bigPicUrl || imageInfo.url
      // console.log('SelectImagePanel', temp)
      this.$eventBus.$emit("addImageToCup", temp);
    },

    uploadImage() {
      // console.log('uploadImage', this.$refs.fileInputEl)    
      this.$refs.fileInputEl[0].click();
    },

    deleteMeIamge(item ){
      
      let params = {
        id: item.id
      }

      Dialog.confirm({
        title: '提示',
        message: '是否删除该图片',
        cancelButtonText:'否',
        confirmButtonText:'是',
      }).then(() => {
        APIServe.get("/design3dImg/delDesign3dImgById", {params})
        .then(() =>this.loadImage(this.meTable,true))    
      }) 
      .catch(() => {
         
        });     
      
    },

    showEditUi(isShow) {
      this.isEditMeImage = isShow;
    },

    fileInputChange(e) {
      
      let file = e.target.files[0];
      var formData = new FormData();
      formData.append('file', file);
      this.$eventBus.$emit("showModalMessage", true, {title: '图片正在上传中'});
      APIServe.post("/file/uploadImageRes", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((e) => {
        let resp = e.data;
        this.addDesignImg(resp.data)
        // console.log('上传图片返回的信息', resp.data.url)
      });
   
    },

    addDesignImg(imageInfo) {
      this.meTable.images = []
      console.log('addDesignImg', imageInfo)
      let params = {
        "thumbnailPicUrl": imageInfo.thumbnailPicUrl,
        "uploadMark": 1,
        "url": imageInfo.bigPicUrl
      }
      APIServe.post("/design3dImg/addDesign3dImg", params,{
        'Content-Type': 'application/json',
      }).then(() => {
        this.loadImage(this.meTable,true);
        this.$eventBus.$emit("showModalMessage", false);
      });
    },

    loadImage(options,flag) {
      if( options.total > -1 && (options.current >= options.total)&& !flag) return
      this.loading = true
      let params = {
        page: options.current, 
        pageSize: options.pageSize,
        uploadMark: options.uploadMark
      }
   
      APIServe.post(options.url, params).then((e)=>{
        let resp = e.data;
        console.log('加载图片', resp)
        console.log(options.url, resp)
        if(resp.code !== '200') return;
        let {data} = resp;

        options.images.push(...data.records);
        options.pages = data.pages;
        options.total = data.total;
        if (options.images.length<options.total) {
              options.current +=1
            }
        this.loading = false
      })

    },

    tabsChange(index) {
      // if(index === 0 && this.hotTable.images.length === 0 ) this.loadImage(this.hotTable);
      if(index != 'my' && this.PlateTableList.length === 0) this.getImgFromPlat();
      else if(index === 'my' && this.meTable.images.length === 0 ){
        this.loadImage(this.meTable);
        this.listenerScroll()
      } 
  },

    getImgFromPlat(){
      // 获取平台上传的图片
      this.loading0 = true
      APIServe.get("/design3dImg/getTagsImgListDesign3dImg", {})
        .then((res) =>{
          this.PlateTableList = res.data.data
          this.PlateTableList.push({tagName:'我的',tabName:"my"})
          if (this.PlateTableList.length === 1) {
            this.loadImage(this.meTable,true)
          }
          this.loading0 = false
        })    
      
    },
     debounce(fn, delay) { 
            let timer = null;//time用来控制事件的触发
            let starttime = Date.now()
              // console.log("--------------执行---------------",fn);
            return function () {
              if (timer !== null) {
                clearTimeout(timer);
                }
                timer = setTimeout(() => {
                            fn.call(this);
                }, delay)
            }
        },
    listenerScroll(){
        let scroll = 'scroll'
        let _this = this
        let list = this.meTable.images
        this.$nextTick(()=>{
          let container = document.getElementById(scroll)
            container.addEventListener('scroll', _this.debounce(function(e) {
                var containerHeight = this.offsetHeight; // 滚动容器的高度
                var scrollHeight = this.scrollHeight; // 滚动内容的总高度
                var scrollTop = this.scrollTop; // 滚动内容的垂直滚动距离
                var scrollBarHeight = containerHeight / scrollHeight * containerHeight; // 滚动条的高度
              if (scrollTop + containerHeight >= scrollHeight - scrollBarHeight) {
                if (_this.meTable.total > list.length) {
                      _this.loading = true
                      let time2 = null
                       if (time2 !== null) {
                        clearTimeout(time2);
                        }
                        // time2 = setTimeout(() => {
                            _this.loadImage(_this.meTable,true);
                            console.log('--------触底了-请求数据----------');
                        // }, 1000)

                    }
                  console.log('--------触底了----------');
                // 触底逻辑
              }
          },1000))
      });
    }
  },
  mounted(){ 
    this.meTable.images = []
    this.getImgFromPlat()
	}, 

}
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 0px 20px 0px;
  margin-top: 0px;

  .image-item {
    margin: 0px 1.5%;
    width: calc(33.33% - 8px);
    margin: 15px 4px;
    height:74px;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.06);
    font-size: 12px;
    color: #48494b;
    &.upload-image {
      border-radius: 15px;
      background-image: url("@/assets/images/add_icon.svg");
      background-position: center;
      background-size: 30%;
      background-repeat: no-repeat;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
    }

    .imageName {
      text-align: center;
      
            }


  }

  .fileInput {
    display: none;
  }

  .content-edit-bar {
    // background: #fafcea;
    // padding: 4px 10px;
    margin-top: -14px;
    margin-bottom: -22px;
    text-align: center;
    border-radius: 16px;
    .content-edit-icon {
      margin: 21px;
      line-height: 32px;
      // border: 1px solid #2B83FF ;
      color: #fff;
      background: #2B83FF;
      border-radius: 18px;
      width: 90%;
      margin-left: 5%;

      &.active {
        background: #fff;
        border: 1px solid #2B83FF ;
        color: #2B83FF;
      }
    }
    
  }
  .delete-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 15px;
    background-color: #000000bb;
    background-image: url("@/assets/images/delete_icon.svg");
    background-position: center;
    background-size: 30%;
    background-repeat: no-repeat;
  }

}
::v-deep{
      .van-loading {
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        z-index: 99999;
      }
      .van-tabs__content {
        .tab-content{

          max-height: calc(41vh - 122px);
          overflow: auto;
        }
    }
}

</style>
