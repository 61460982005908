<template>
  <div>
    <h1>
      测试页面
    </h1>
  
  </div>
</template>

<script>

import { Col, Row } from 'vant';
import Vue from 'vue';
Vue.use( Col );
Vue.use( Row );
import * as wx from "weixin-js-sdk"

export default {
  name: 'TestPage',
  components: {

  },
  data() {
    return {
 
    }
  },

  created() {
   
  },

  mounted(){   

    console.log('进来了availWidth', window.screen.availWidth)
    console.log('进来了availWidth', window.screen.availHeight )
    console.log('screen.width', window.screen.width )
    console.log('screen.height', window.screen.height )
    console.log('进来了innerHeight', window.innerHeight )
    console.log('进来了innerHeight', window.innerWidth )

    
    try {
      // wx.getSystemInfo({
      //   success:function(res) {
      //   console.log(res.windowWidth, '宽')
      //   console.log(res.windowHeight, '长')   
      //   }
      // })
      console.log('wx.getSystemInfoSync().windowWidth', wx.getSystemInfoSync().windowWidth)
      alert(wx.getSystemInfoSync().windowWidth)
      console.log('wx.getSystemInfoSync().windowHeight', wx.getSystemInfoSync().windowHeight)
      alert(wx.getSystemInfoSync().windowHeight)

    } catch(e) {
      console.log('catch', e)
    }

   
    
	},

  methods: {

    
      
  },
 
}
</script>

<style lang="scss" scoped>

.three-container {
    width: 100%;
    height: 100%;
}

/* .van-col {
  margin-top: 20px;
} */

</style>
