import { defineProperty, getPointBaseMeshLocation, bindThis } from "../utils/helper";
import * as TWEEN from "three/examples/jsm/libs/tween.module.js";

export default class Camera {
  constructor(viewer){
    this.viewer = viewer;
    this.activeCamera = viewer.activeCamera
    this.position = viewer.activeCamera.position;
    this.target = viewer.controls.target;

  }

  setView(cameraPos, lookAt) {
  
    if(typeof(lookAt) === 'string') {
      lookAt = getPointBaseMeshLocation(this.viewer.content);
    }   
    this.position.copy(cameraPos);
    this.target.copy(lookAt);

    this.viewer.controls.update();
  }

  flyTo() {

  }

  lookTo(nodeName) {
    let target = this.viewer.content.getObjectByName(nodeName);
    let lookAt = getPointBaseMeshLocation(target);
    this.target.copy(lookAt);
    this.update();    
  }

  update() {
    this.viewer.controls.update();
  }

  moveTo(nodeName, location = 'front', distanceRate = 1.1) {

    let target = nodeName ? this.viewer.content : this.viewer.content.getObjectByName(nodeName);
    let endPos = getPointBaseMeshLocation(target, location, distanceRate);
    let lookAt = getPointBaseMeshLocation(target);

    this.setView(endPos, lookAt);
    // console.log('endPos', endPos, 'lookAt', lookAt)

  }


  flyBackwardsTo(distance, options) {  

    options = Object.assign({
      duration: 1000,
    }, options) 

    let endPos;
    let dir = this.target.clone().sub(this.position).normalize();
    let moveDistance = dir.clone().multiplyScalar(distance);  

    if(options.reverse) endPos = this.position.clone().add(moveDistance);
    else endPos = this.position.clone().sub(moveDistance);
    
    options.endPos = endPos;

    return new Promise((resolve, reject) => {
      this.addCameraTween(options)
      .onComplete(()=> {
        if(options.onComplete) options.onComplete();
        resolve();
      })
    })
  } 

  flyForwardTo(distance, options) {
    options.reverse = true;
    this.flyBackwardsTo(distance, options);
  }

  addCameraTween(options = {}) {
    
    let { duration, startPos, endPos } = options;

    if(!startPos) startPos = this.position.clone();

    let startParam = { progress: 0 };
    let endParam = { progress: 1 };

    let currentTween = new TWEEN.Tween(startParam).to(endParam, duration).easing(TWEEN.Easing.Linear.None)
    .onUpdate(e => {
      let { progress } = e;
      let crrentPos;
      if(endPos) crrentPos = startPos.clone().lerp(endPos, progress);
      this.position.copy(crrentPos);
      this.viewer.controls.update();
    })
    currentTween.start()
    return currentTween;
 
  }



}