<template>
  <van-Row class="catalogue-list" type="flex" justify="center">
    <van-col class="catalogue-title" span="20">步骤</van-col>
    <van-Col class="catalogue-item" span="24" v-for="(item, index) in panelInfos" 
      :key="item.key" 
      :class="{invalid: index === 0}"
      @click="changePanel(item)">
      <span>{{ index+1 }} </span>  {{ item.title }}
    </van-Col>
  </van-Row>
</template>

<script>

import { Col, Row } from 'vant';
import Vue from 'vue';

Vue.use( Col );
Vue.use( Row );

export default {
  name: 'CataloguePanel',
  props: {
    panelInfos: Array
  },
  data() {
    return {
      state: 'normal'
    }
  },
  methods: {
    changePanel(item) {
      console.log('item', item)
      this.$eventBus.$emit("changePanel", item.component);
    }
  }

}
</script>

<style lang="scss" scoped>
.catalogue-list {
  width: 100%;
  margin-top: 60px;

  .catalogue-title {
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.171);
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
  }
  .catalogue-item {

    height: 36px;
    border-radius: 18px;
    // border: 1px solid #EEEEEE;
    line-height: 36px;
    text-align: center;
    margin: 6px 0px;

    &.invalid {
      pointer-events: none;
      opacity: 0.3;
    }
    
    span {
      margin-right: 10px;
      color: rgba(0, 0, 0, 0.2);
    }


  }
}

</style>
