import { fabric } from 'fabric';
import FontFaceObserver from "fontfaceobserver";
import { getFileNameByUrl } from '../utils/helper';

export class DecalsText extends fabric.Text {
  constructor(text, options) {  
    super(text, options);
    this.userData = {};

  }

  async setOnlineFont(url) {
    let that = this;
    let fontName = getFileNameByUrl(url, 'prefix');
    if(!window.editorOnlineFont[fontName]) {
      window.editorOnlineFont[fontName] = await this.loadFont(url, fontName)
    }
   
    var myfont = new FontFaceObserver(fontName)
    await myfont.load().then(function() {
      that.set('fontFamily', fontName);  
      that.userData.fontName = fontName;
      that.userData.fontUrl = url;
    }).catch(function(e) {
      console.log('FontFaceObserver错误了', e)   
    });      
  }

  loadFont(url, fontName) {

    return new Promise((resolve, reject) => {
      const fontFile = new FontFace(fontName, `url(${url})`);
      document.fonts.add(fontFile);
      fontFile.load().then(()=> {
        resolve(fontFile)
      })
    })

  }
}

