<template>
   <router-view></router-view>
</template>

<script>
import Vue from 'vue';
// import LayoutView from './components/LayoutView.vue'
import "vant/lib/index.css";
import VueRouter from 'vue-router';
import router from './router.ts';


import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
import 'intro.js/themes/introjs-modern.css' // introjs主题

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus
Vue.prototype.$intro = intro
Vue.use( VueRouter);


export default {
  name: 'App',
  components: {
    // LayoutView
  },
  router
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
