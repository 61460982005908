import EditStyle from './views/EditStyle.vue';
import PreviewScheme from './views/PreviewScheme.vue';
import VueRouter from 'vue-router';
import TestPage from './views/TestPage.vue';

const router  = new VueRouter({
  mode: 'history',
  base:'/cup-demo/',
  routes: [
    { path: '/',   redirect:'/editstyle'},
    { path: '/editstyle', component: EditStyle },
    { path: '/previewscheme', component: PreviewScheme },
    { path: '/testPage', component: TestPage },
  ]
})

export default router ;
