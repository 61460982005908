import { render, staticRenderFns } from "./ContactBusiness.vue?vue&type=template&id=56c09d37&scoped=true&"
import script from "./ContactBusiness.vue?vue&type=script&lang=js&"
export * from "./ContactBusiness.vue?vue&type=script&lang=js&"
import style0 from "./ContactBusiness.vue?vue&type=style&index=0&id=56c09d37&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c09d37",
  null
  
)

export default component.exports