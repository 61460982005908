<template>
  <van-Col span="24" class="templates-panel">
    <van-tabs v-model="tabsaCtive" @click="tabsClick">
      <van-tab title="平台">
        <div id="scroll0" class="tab-cotain" v-if="tabsaCtive == 0">
          <!-- <van-row span="12" class="content-loading"> -->
          <van-loading v-if="loading" size="24px" vertical  color="#1989fa">加载中...</van-loading>
          <!-- </van-row> -->
          <van-Row class="templates-content" v-if="templatesAll">
            <van-Col class="templates-item" v-for="(item, index) in templatesAll" 
              :class="{active: selectIndex === index}"
              :key="index"> 
              <van-image     
                  width="100%"
                  height="100%"
                  fit="cover"
                  radius="6"
                  :src="item.thumbnailPicUrl"       
                  @click="loadTemplate(item, index)"
                />
                <div class="imageName">{{ item.name }}</div>
            </van-Col>
          </van-Row>
        </div> 
      </van-tab>
      <van-tab title="我的">
        <div id="scroll1" class="tab-cotain" v-if="tabsaCtive == 1">
          <div id="inner-cotain">
            <van-row span="24" class="content-edit-bar">
                <p class="content-edit-icon"  :class="{active: isEditMyTemplate}" @click="showEditUi(!isEditMyTemplate)"> {{isEditMyTemplate ? '退出管理':"管理"}} </p>
            </van-row>
            <!-- <van-row span="12" class="content-loading"> -->
              <van-loading v-if="loading" size="24px" vertical  color="#1989fa">加载中...</van-loading>
            <!-- </van-row> -->
            <van-Row class="templates-content" v-if="myTemplatesAll">
              <van-Col class="templates-item" v-for="(item, index) in myTemplatesAll" 
                :class="{active: selectIndex === index}"
                :key="index"> 
                <van-col v-if="isEditMyTemplate" class="delete-icon" @click="deleteTemplate(item)" ></van-col>
                <van-image     
                    width="100%"
                    height="100%"
                    fit="cover"
                    radius="6"
                    :src="item.thumbnailPicUrl"       
                    @click="loadTemplate(item, index)"
                  />
                  <div class="imageName">{{ item.name }}</div>
              </van-Col>
            </van-Row>
          </div>
        </div>
        
      </van-tab>
    </van-tabs>
    
  </van-Col>
</template>

<script>

import { Col, Row, Tab, Tabs ,Loading,Dialog,Image as VanImage } from 'vant';
import Vue from 'vue';
// import axios from 'axios';
import APIServe from '@/plugins/APIServe.ts';

Vue.use( Col );
Vue.use( Row );
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Loading);
Vue.use(VanImage);
Vue.use(Dialog);

export default {
  name: 'SelectTemplatePanel',
  data() {
    return {
      templatesAll: null,
      myTemplatesAll: null,
      queryParames:{
        page:1,
        pageSize:10,
        uploadMark: 0
      },
      selectIndex: -1,
      tabsaCtive:0,
      loading:true,
      isEditMyTemplate:false,
      total:0,
      list:[],
      lis2:[],
    
    }
  },
  methods: {
    loadTemplate(template, index) {
      this.selectIndex = index;
      template.elements.forEach((el) => {
        el.forbidAutoAlign = true;
        el.forbidAutoScale = true;
      })
      this.$eventBus.$emit("loadElements", template.elements, template.location);
    },
    // 获取模板数据
    getTemplateData(){
     
      this.loading = true
      const {uploadMark} = this.queryParames
        APIServe.post('/design3dTemplate/getPageDesign3dTemplate', this.queryParames).then((e)=> {
            let resp = e.data;
            let pages = resp.data.pages
            let templates = resp.data.records;
            this.total = resp.data.total
            let list = this.tabsaCtive == 0 ? this.list:this.list2
            this.loading = false
            if (!templates||list.length>=this.total) return
              templates.forEach(item => { 
                item.elements = JSON.parse(item.elements)          
                  this.list.push(item)
                  this.list2.push(item)
              });
              if (uploadMark == 0) {
                  this.templatesAll = this.list;
              }else{
                  this.myTemplatesAll = this.list2
              }
              console.log(this.templatesAll,this.myTemplatesAll,this.list2);
            if (pages>this.queryParames.page) {
              this.queryParames.page +=1
            }
           
        })
    },
    // 点击tab
    tabsClick(name,title){
      this.loading = true
      this.list = []
      this.list2 = []
      this.templatesAll = null
      this.myTemplatesAll = null
      this.queryParames.page = 1;
      this.queryParames.uploadMark = name
      this.getTemplateData()
      console.log(name,title,"----------->点击模板的tab");
    this.listenerScroll()

    },
    // 删除模板
    deleteTemplate(item ){
      
      let params = {
        id: item.id
      }

      Dialog.confirm({
        title: '提示',
        message: '是否删除该模板',
        cancelButtonText:'否',
        confirmButtonText:'是',
        // beforeClose,
      }).then(() => {
        APIServe.get("/design3dTemplate/delDesign3dTemplateById", {params})
        .then(() =>this.deleteMyTemplate(item.id))    
      }) 
      .catch(() => {
         
        });     
      
    },
    deleteMyTemplate(id){
      let index = this.list2.findIndex(i=>i.id==id)
      this.list2.splice(index,1)
      this.myTemplatesAll = this.list2
      console.log(this.myTemplatesAll,this.list2);
    },
    showEditUi(isShow) {
      this.selectIndex = -1
      this.isEditMyTemplate = isShow;
    },
    debounce(fn, delay) {
      console.log(this);
            let timer = null;//time用来控制事件的触发
            let starttime = Date.now()
              // console.log("--------------执行---------------",fn);
            return function () {
              if (timer !== null) {
                clearTimeout(timer);
                }
                timer = setTimeout(() => {
                            fn.call(this);
                }, delay)
            }
        },
    listenerScroll(){
        let scroll =  'scroll' + this.tabsaCtive
        let _this = this
        let list = this.tabsaCtive == 0 ? this.list:this.list2
        this.$nextTick(()=>{
          let container = document.getElementById(scroll)
            container.addEventListener('scroll', _this.debounce(function(e) {
              console.log(this.offsetHeight,this.scrollHeight,this,e);
                var containerHeight = this.offsetHeight; // 滚动容器的高度
                var scrollHeight = this.scrollHeight; // 滚动内容的总高度
                var scrollTop = this.scrollTop; // 滚动内容的垂直滚动距离
                var scrollBarHeight = containerHeight / scrollHeight * containerHeight; // 滚动条的高度
              
              if (scrollTop + containerHeight >= scrollHeight - scrollBarHeight) {
                if (_this.total > list.length) {
                      let time2 = null
                       if (time2 !== null) {
                        clearTimeout(time2);
                        }
                        // time2 = setTimeout(() => {
                            _this.getTemplateData()
                            console.log('--------触底了-请求数据----------');
                        // }, 1000)

                    }
                  console.log('--------触底了----------');
                // 触底逻辑
              }
          },500))
      });
    }

  },

  mounted(){
    // axios.get('/cup-demo/mock/templateList.json').then((resp)=> {
    //   this.templatesAll = resp.data
    // })
    this.tabsaCtive = 0
    let params = {
      "page":1,
      "pageSize":10,
      "uploadMark": 0
      // id:2
    }

    APIServe.post('/design3dTemplate/getPageDesign3dTemplate', params).then((e)=> {
      let resp = e.data;
      let templates = resp.data.records;
      console.log('/design3dTemplate/getPageDesign3dTemplate', resp)
      templates.forEach(item => { item.elements = JSON.parse(item.elements) });
      this.templatesAll = templates;
      this.loading = false
    })
    this.listenerScroll()


	}, 

}
</script>

<style lang="scss" scoped>
.templates-content {
  padding: 0px 20px 10px;
  box-sizing: border-box;
  .templates-item {
    margin: 15px 1.5%;
    width: 47%;

    height:74px;
    position: relative;
    border-radius: 6px;
    box-shadow: 6px 6px 7px 1px rgba(0, 0, 0, 0.06);
    
    font-size: 12px;
    color: #48494b;
    &.active {
      border: 1px solid #2B83FF;
      line-height: 74px;
      padding: 4px;
      .van-image__img {
        width: 90% !important;
        height: 90% !important;
      }
      .imageName {
        transform: translateY(-50px);
      }
    }

    .imageName {
      text-align: center;
            }
    .delete-icon {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 6px;
      background-color: #000000bb;
      background-image: url("@/assets/images/delete_icon.svg");
      background-position: center;
      background-size: 30%;
      background-repeat: no-repeat;
    }
  }
}
.content-edit-bar {
    // background: #fafcea;
    // padding: 4px 10px;
    margin-top: -14px;
    margin-bottom: -22px;
    text-align: center;
    border-radius: 16px;
    .content-edit-icon {
      margin: 21px;
      line-height: 32px;
      // border: 1px solid #2B83FF ;
      color: #fff;
      background: #2B83FF;
      border-radius: 18px;
      width: 90%;
      margin-left: 5%;

      &.active {
        background: #fff;
        border: 1px solid #2B83FF ;
        color: #2B83FF;
      }
    }
    
  }

::v-deep{
  // .content-loading{
    .van-loading {
      position: absolute;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%,96%);
      z-index: 99999;
      width: 100%;
      
    }
  // }
    //   .van-tabs__content {
    //     max-height: calc(41vh - 122px);
    //     overflow: auto;
    // }
}
.tab-cotain{
        max-height: calc(41vh - 122px);
        overflow: auto;
}
</style>
