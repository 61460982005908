<template>
  <van-Col span="24" class="addImage-panel">
    <van-tabs v-model="tabsaCtive">
      <!-- <van-tab title="名片">
          <van-row class="tab-content" v-if="QRcodeImages.businessCard">
            <van-Col class="hot-item" v-for="(image, index) in QRcodeImages.businessCard" :key="index"> 
              <van-image     
                  width="100px"
                  height="100px"
                  fit="cover"
                  radius="15"
                  :src="image.thumbnailPicUrl"
                  @click="addImage(image, index)"
                />
              <van-col class="item-text">{{ image.title }}</van-col>
          </van-Col>            
          </van-row>
      </van-tab> -->
       <van-tab title="微传单">
        <van-row class="tab-content" v-if="websiteList">
            <van-Col class="hot-item" v-if="websiteList.length != 0"> 
              <div v-for="(item,index) in websiteList" :key="index" style="width:50%;margin-bottom:10px">
                <van-image     
                  width="100px"
                  height="100px"
                  fit="cover"
                  :src="weiSrc(item)"
                  radius="15"
                  @click="addImage(item)"
                />
                <van-col class="item-text">{{ item.title }}</van-col>
              </div>
          </van-Col> 
          <ContactBusiness v-else :qrcodeType="'websiteList'"/>
        </van-row>
      </van-tab>
      <van-tab title="公众号">
        <van-row class="tab-content" v-if="officialAccounts">
            <van-Col class="hot-item" v-if="officialAccounts.thumbnailPicUrl"  > 
              <van-image
                  width="100px"
                  height="100px"
                  fit="cover"
                  radius="15"
                  :src="officialAccounts.thumbnailPicUrl"
                  @click="addImage(officialAccounts)"
                />
              <van-col class="item-text">{{ officialAccounts.title }}</van-col>
          </van-Col>            
          <ContactBusiness v-else :qrcodeType="'officialAccounts'"/>
        </van-row>
      </van-tab>

      <van-tab title="小程序 ">
        <van-row class="tab-content" v-if="miniProgram">
            <van-Col class="hot-item" v-if="miniProgram.thumbnailPicUrl"> 
              <van-image     
                  width="100px"
                  height="100px"
                  fit="cover"
                  radius="15"
                  :src="miniProgram.thumbnailPicUrl"
                  @click="addImage(officialAccounts)"
                />
              <van-col class="item-text">{{ miniProgram.title }}</van-col>
          </van-Col>  
          <ContactBusiness v-else :qrcodeType="'miniProgram'"/>
        </van-row>
      </van-tab>

     
      <!-- <van-tab title="活动页面"></van-tab> -->
    </van-tabs>
  
  </van-Col>
</template>

<script>

import { Col, Row, Tab, Tabs } from 'vant';
import Vue from 'vue';
import axios from 'axios';
import APIServe from '@/plugins/APIServe.ts';
import ContactBusiness from "./ContactBusiness"
Vue.use( Col );
Vue.use( Row );
Vue.use(Tab);
Vue.use(Tabs);

export default {
  name: 'AddQRcode',
  components:{ContactBusiness},
  data() {
    return {
      hotImages: null,
      tabsaCtive:0,
      QRcodeImages: {},
      miniProgram:null,
      officialAccounts:null,
      website:null,
      websiteList:null,

      imageUrlList: {
        hot: "/cup-demo/mock/hot-images.json",
        me: "/cup-demo/mock/hot-images.json",
      }
    }
  },
  methods: {
    weiSrc(item){
      console.log(item.yl,item.url,"1234");
      // let r =!!item.yl ?item.yl:item.url
      return item.yl || item.url
    },
    addImage(imageInfo) {
      this.$eventBus.$emit("addImageToCup", imageInfo);
    },


  },

  mounted(){
		axios.get('/cup-demo/mock/QRcodeList.json').then(resp => {
      this.QRcodeImages = resp.data;
    })

    APIServe.get('/scanData/qrCodeSelect').then((e)=> {
      let  resp = e.data;
      if(resp.code !== "200") return;
      this.officialAccounts = resp.data.officialAccounts
      this.miniProgram = resp.data.miniProgram
      this.websiteList = resp.data.websiteList
      console.log('qrCodeSelect-resp', resp)
    
    })
	}, 

}
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 10px 20px 0px;
  margin-top: 10px;
  .hot-item {
    margin: 10px 1.5%;
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .item-text {
      font-size: 12px;
      text-align: center;
      width: 100%;
      overflow: hidden;
    }
  }

  
}

::v-deep{
      .van-tabs__content {
        height: calc(41vh - 122px);
        overflow: auto;
    }
}
</style>
