
import { fabric } from 'fabric';
import { CanvasTexture, RepeatWrapping, EventDispatcher, sRGBEncoding, Vector2, Vector3, SpriteMaterial, Sprite, Color, Box2, SRGBColorSpace, LinearSRGBColorSpace, TextureLoader } from 'three';
import { bindThis, cloneObject,  loadImageByFabric, point2DInTriangle, getTrianglePointWeight2D, createElement, getElementWidth, getElementHeight } from '../../utils/helper';
import { DecalsText } from '../DecalsText';
import ApiService from '../../utils/ApiService';
import Hammer from '../../utils/hammer'
import initAligningGuidelines from '../../components/aligning_guidelines.js';
import initCenteringGuidelines from '../../components/centering_guidelines.js';

let objectId = 0

export class SurfaceDecals {

  constructor(target, canvasContainer) {  
   
    this.target = target;
    this.canvasContainer = canvasContainer;
    this.name = 'decals'; 
    let printParam = target.activeMesh.userData.prints;
    this.active = printParam.sides[0];
    this.fullSize = printParam.fullSize;
    this.detailViewEl = null;
    this.navigationMapEl = null;
    this.isNavigationMap = false;
    this.activeIndex = this.active.type === 'group'?  0 : -1;  //如果active 是一个组，那么它的值将会大于-1;
    this.backgroundColorList = {};
    let options = cloneObject(printParam.sides[0]);
    if( this.active.type === 'group') options.children[this.activeIndex];

    this.backgroundImgList = {};
    this.fullCanvasList = {};
    this.canvases = [];
    this.helperEls = []
    this.pinchState = null
    this.defaultMaterial = this.getMaterial();
   
    this.canvas = null;
    this.dragOutCanvasDelete = false;
    this.dragOutDeleteDistance = 0.7;
    this.dragOutHintDistance = 0.5;
    this.dragOutResetPosition = false;
    this.isDesignChange = false;
    this.canvasMaxHeight = 0.37;

    window.editorOnlineFont = {}
    
    this.addTouchEvent()
    this.addCanvasViewEl();
    this.addCanvas(this.active);
    // this.addDetailViewCanvas();
    this.resetCanvasViewEl();
    this.saveBackgroundImg();
    this.addHelperElenemts();
    this.definePropertyAll();
    this.target.addEventListener('changeMap', bindThis(this.changeMapEvent, this))
  
  }

  initBackgroundCanvas() {

  }

  getMaterial() {
    
    let map, normalMap;
    let mesh = this.target.getObjectByName(this.active.nodeName)

    if(mesh.material.map) map = mesh.material.map.clone();
    if(mesh.material.normalMap) normalMap = mesh.material.normalMap.clone();
    return {map, normalMap}
  }

  resetMaterial() {
    console.log('resetMaterial', this.defaultMaterial)
    let mesh = this.target.activeMesh;
    mesh.material.map = this.defaultMaterial.map;
    mesh.material.normalMap = this.defaultMaterial.normalMap;
    this.saveBackgroundImg()
    // this.canvas.clear();
    this.apply();
    }

  addTouchEvent() {
    let that = this
    var mc = new Hammer(this.canvasContainer);
    mc.get('pinch').set({ enable: true });

    let targetElement;

    mc.on('pinchstart', function(e) {

      targetElement = that.canvas.getActiveObject();
      if(!targetElement) return
      that.pinchState = {};
      that.pinchState.press = true;
      that.pinchState.state = targetElement.toJSON();
      that.pinchState.startScale = e.scale; 
      that.pinchState.scale = 1;  
    });

    mc.on('pinchmove', function(e) {

      if(!targetElement) return
      targetElement.scale( that.pinchState.state.scaleX * e.scale)
           
      that.canvas.renderAll();
   
    });

    mc.on('pinchend', function(e) {
      if(!targetElement) return
      that.pinchState = null
    });
    
  }

  addCanvasViewEl() {
    this.detailViewEl = createElement('div', 'canvas-detail-view');
    this.navigationMapEl = createElement('div', 'canvas-navigation-map');
    
    this.detailViewEl.style.width = "100%";
    this.detailViewEl.style.height = "100%";

    this.navigationMapEl.style.width = "100%";

    // this.canvasContainer.appendChild(this.detailViewEl);
    this.canvasContainer.appendChild(this.navigationMapEl);
  }

  resetCanvasViewEl() {


    let navigationViewRato = 1;

    let elwidth = getElementWidth(this.canvasContainer);
    let elHeight = getElementHeight(this.canvasContainer);
    // let mapElScale = (elwidth / this.canvas.width);
    // let currentMapElHeight = mapElScale * this.canvas.height;
    // navigationViewRato = currentMapElHeight / elHeight; 

    // let mapCanvasContainer = this.navigationMapEl.querySelector('.canvas-container');
    // mapCanvasContainer.style.transform =`scale(${mapElScale} )`;
    // mapCanvasContainer.style.transformOrigin = "left top";
    // mapCanvasContainer.style.pointerEvents = "all";
    // this.navigationMapEl.style.background = 'rgba(0, 0, 0, 0.2)';
    // this.navigationMapEl.style.height = elHeight * navigationViewRato +'px';

    // this.navigationMapEl.style.position = 'absolute';
    // this.navigationMapEl.style.bottom = '60px';

    // this.navigationViewRato = navigationViewRato;
 
    // this.detailViewEl.style.background = 'rgba(0, 0, 0, 0.3)';
    // this.detailViewEl.style.height = elHeight * (1 - navigationViewRato) +'px';;

    // this.resetDetailViewEl();
  

    let scaleX = elwidth  / this.canvas.width;
    let scaleY = elHeight  / this.canvas.height;

    // let elAspectRatio = elwidth / elHeight;
    // let canvasAspectRatio = this.canvas.width / this.canvas.height;
    // let offsetAspectRatio = canvasAspectRatio / elAspectRatio;

    // if(scaleX > scaleY) scaleY = scaleX;

    let currentScale = scaleX;

    
    let maxWindowHeight = window.innerHeight * this.canvasMaxHeight;
    let currentCanvasHeight = currentScale * this.canvas.height
    let heightOffset = currentCanvasHeight - maxWindowHeight;
    let marginLeft = 0;
    if(heightOffset > 0)  {
      
      currentScale -= (heightOffset / currentCanvasHeight) * currentScale;
      let currentWidth = currentScale * this.canvas.width;
      marginLeft = (elwidth - currentWidth) / 2;
  

      console.log('marginLeft', marginLeft)
      console.log('currentScaleTemp', currentWidth)

    }
    // console.log('window.innerHeight', window.innerHeight * window.devicePixelRatio)
    console.log('currentCanvasHeigth',maxWindowHeight,  currentCanvasHeight)

    // console.log('offsetAspectRatio', offsetAspectRatio)
    // console.log('scaleX', aspectRatio, elwidth / elHeight)
    this.navigationMapEl.style.height = currentScale * this.canvas.height + 'px'
    let mapCanvasContainer = this.navigationMapEl.querySelector('.canvas-container');
    if(heightOffset > 0) {
      // mapCanvasContainer.style.backgroundColor = '#ffffff14'; 
      mapCanvasContainer.style.borderLeft = "4px dashed #0000003d";
      mapCanvasContainer.style.borderRight = "4px dashed #0000003d";
    }  
    mapCanvasContainer.style.transform =`scale(${currentScale})`;
    mapCanvasContainer.style.transformOrigin = "left top";
    mapCanvasContainer.style.pointerEvents = "all";
    mapCanvasContainer.style.marginLeft = marginLeft + 'px';
  }

  addHelperElenemts() {

    let canvasWidth = this.canvas.width;
    let canvasHeight = this.canvas.height;
    let canvasVerticalMiddleLine = new fabric.Line([canvasWidth/2, 0, canvasWidth/2, canvasHeight], {
         stroke: "rgba(0, 0, 0, 0.2)",
         strokeWidth: 6,
         strokeDashArray: [20, 20],
      });

    canvasVerticalMiddleLine.name = 'canvasVerticalMiddleLine';    
    canvasVerticalMiddleLine.selectable = false   //对象不可选择
    this.helperEls.push(canvasVerticalMiddleLine);

    this.showHelperElenemts();
  }

  showHelperElenemts() {
    this.helperEls.forEach((el)=> this.canvas.add(el));
    for(let i =this.helperEls.length - 1; i < 0; i--) this.helperEls[i].sendToBack();
  }
  
  hideHelperElenemts() {
    this.helperEls.forEach((el)=> this.canvas.remove(el));
  }

  resetDetailViewEl () {    

    if(!this.detailViewCanvas) return;
    let elHeight = getElementHeight(this.canvasContainer);
    let navigationScale = (elHeight * (1 - this.navigationViewRato)) / this.detailViewCanvas.height;  
    console.log('navigationScale', navigationScale)
    let detailCanvasContainer = this.detailViewEl.querySelector('.canvas-container');
    detailCanvasContainer.style.marginLeft = '50%';
    detailCanvasContainer.style.transform =`scale(${navigationScale})  translateX(-50%)`;
    detailCanvasContainer.style.transformOrigin = "left top";
    detailCanvasContainer.style.pointerEvents = "all";
   
  }

  importElementToDetailView(element) {

    let scale = 1.5;
    let that = this;

    if(element.type === 'text') scale = 6;
    if(!this.detailViewCanvas) return;
    this.detailViewCanvas.setHeight(element.height * scale);
    this.detailViewCanvas.setWidth(element.width * scale);
    this.detailViewCanvas.clear();
    // console.log('importElementToDetailView', this.detailViewCanvas )
    element.clone(function(cloneObject) {

      cloneObject.originElement = element;
      that.elementAlignCanvasCenter(that.detailViewCanvas, cloneObject);
      if(cloneObject.type === 'text')  that.changeNavigationTextStyle(cloneObject);
      else that.changeNavigationImgStyle(cloneObject);
     
      that.detailViewCanvas.add(cloneObject);   
      that.resetDetailViewEl();
      that.detailViewCanvas.setActiveObject(cloneObject);
      that.detailViewCanvas.renderAll();
    })

  }
  elementAlignCanvasCenter(canvas, element) {

    let  bounding = element.getBoundingRect();

    let elementCenter = {
      x:bounding.left + bounding.width / 2,
      y:bounding.top + bounding.height / 2
    }
    let offset = {
      x: canvas.width / 2 - elementCenter.x,
      y: canvas.height / 2 - elementCenter.y,
    }

    element.left += offset.x;
    element.top += offset.y;
    console.log('elementAlignCanvasCenter', offset)
    
  }

  changeNavigationImgStyle(element) {

    element.cornerSize = 40;
    element.touchCornerSize = 40;
    element.borderScaleFactor = 6;
    element.centeredScaling = true;

    element.controls.mtr.cornerSize = 70;
    element.controls.mtr.touchCornerSize = 70;
    element.controls.mtr.offsetY = -60;

    element.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
      ml: false, // middle left
      mr: false, // I think you get it
    });
    
    element.lockMovementX = true;
    element.lockMovementY = true;
    // element.set({
    //   borderScaleFactor: 4, 
    // })


  }

  changeNavigationTextStyle(element) {

    element.cornerSize = 40;
    element.touchCornerSize = 40;
    element.borderScaleFactor = 6;
    element.centeredScaling = true;

    element.controls.mtr.cornerSize = 70;
    element.controls.mtr.touchCornerSize = 70;
    element.controls.mtr.offsetY = -60;

    element.setControlsVisibility({
      bl: false,
      br: false,
      mt: false, 
      mb: false, 
      ml: false, 
      mr: false, 
      tl: false,
      tr: false
    });
    
    element.lockMovementX = true;
    element.lockMovementY = true;
    // element.set({
    //   borderScaleFactor: 4, 
    // })


  }

  addCanvas(canvasParams) {

    //把之前的图像、文字 元素放到最新的Canvas中
    let lastObjects;
    if(this.canvases.length > 0) {
      this.fullCanvasList = {}; 
      lastObjects = this.canvas.getObjects();
      this.canvases.forEach(item => item.clear())
      this.canvases = [];
      this.canvas = null;
    }
   
    let canvasAll = [];
    if(canvasParams.type === 'group' && canvasParams.children) {
      canvasParams.children.forEach(item => canvasAll.push(item))
    } else {
      canvasAll.push(canvasParams);
    }

    canvasAll.forEach((item) => {
    
      let { pixelRate, offsetRate, nodeName } = item;
      let pixelWidth = parseInt(this.fullSize * pixelRate.x);
      let pixelHeight = parseInt(this.fullSize * pixelRate.y);

      let canvasEl = document.createElement('canvas');
      canvasEl.width = pixelWidth;
      canvasEl.height = pixelHeight;      

      this.navigationMapEl.appendChild(canvasEl)
      let canvas = new fabric.Canvas(canvasEl, {
        enableRetinaScaling: false,  
        selectionKey:null,
        selection: false,// 禁用组选择
        allowTouchScrolling: false,
      }); 

      initAligningGuidelines(canvas)
      initCenteringGuidelines(canvas)
      
      

      this.addCanvasEvent(canvas);
      canvas.userData = cloneObject(item);
      canvas.userData.sideId = item.id;
      this.canvases.push(canvas);

      this.addFullCanvas(this.fullSize, nodeName);
    
    })

    if(this.activeIndex === -1) this.canvas = this.canvases[0];
    else this.canvas = this.canvases[this.activeIndex];
    this.canvas.getElement().style.removeProperty('display');
    if(lastObjects) {
      lastObjects.forEach(item => this.canvas.add(item));
    };
  }

  addCanvasEvent(canvas) {

    let that = this;
    // let isObjectMove = false;
    // let isObjectScale = false;
    // let isObjectRotating = false;

    this.objectActive ={
      move: false,
      rotating: false,
      scale: false,
    }
    let hasElementDragOut = {
      x: false,
      y: false,
    }

    canvas.on('object:moving', (e) => {
      let {target} = e;
      that.objectActive.move = true;
      that.isDesignChange = true;
      if(that.pinchState) {
        target.left= that.pinchState.state.left;
        target.top= that.pinchState.state.top;  
      };
      this.objectMovingEvent(e, hasElementDragOut);
    })

    canvas.on('object:scaling', function(e){
      
      that.objectActive.scale = true; 
      that.isDesignChange = true;

      // 限制最大为3.5倍
      if(e.target.scaleX>3.5){
        e.target.lockScalingX = true;
        e.target.scale(3.5);
        e.target.lockScalingX = false;
      }

      // 限制最小
      if(e.target.scaleX<0.35){
        e.target.lockScalingX = true;
        e.target.scale(0.35);
        e.target.lockScalingX = false;
      }

      
    });
    canvas.on('object:rotating', function(e){ that.objectActive.rotating = true; that.isDesignChange = true;})
    canvas.on('selection:updated', this.canvasSelectionUpdataEvent.bind(this));
    canvas.on('selection:cleared', this.canvasSelectionChengeEvent.bind(this));
    canvas.on('selection:created', this.canvasSelectionChengeEvent.bind(this));

    canvas.on('mouse:up', this.canvasMouseup.bind(this))
    // canvas.on('touch:longpress', (e) => {console.log('touch:gesture', e); alert('帮忙干嘛')})
   
  }

  putToEdge(axle, target) {

    let edgeDistance = {
      left: target.left,
      right: this.canvas.width - target.left,
      top: target.top,
      bottom: this.canvas.height - target.top,
    }

    if(axle === 'x') {
       if(edgeDistance.left < edgeDistance.right) target.left = target.width*target.scaleX / 2;
       else  target.left = this.canvas.width - (target.width*target.scaleX  / 2 );

    } else if(axle === 'y') {
      if(edgeDistance.top < edgeDistance.bottom) target.top = target.height*target.scaleX  / 2;
      else  target.top = this.canvas.height - (target.height*target.scaleX  / 2);
    }

  }

  canvasSelectionChengeEvent(e) {
    let { selected } = e;

    selected = selected ? selected[0] : selected;
    if(selected) this.importElementToDetailView(selected);
    // if(!selected && this.selectedElement && !this.removingElement){
    //   this.canvas.setActiveObject(this.selectedElement);
    //   selected = this.selectedElement;
    //   this.canvas.renderAll();
    // };
    // this.removingElement = false;
    this.selectedElement = selected;
    this.dispatchEvent({ type: "selectChange", picked: selected});
  }

  canvasSelectionUpdataEvent(e) {
  
    let { selected } = e;
    this.canvasSelectionChengeEvent(e);
  }

  objectMovingEvent(e, hasElementDragOut) {
    let { target } = e;
    if(!target) return;

    if(!this.dragOutCanvasDelete) return;

    let isOutCanvas = this.detectIsOutCanvas(target)
    // console.log('isOutCanvas', isOutCanvas)
    if(hasElementDragOut.x === isOutCanvas.x && hasElementDragOut.y === isOutCanvas.y) return;    
    hasElementDragOut.x = isOutCanvas.x;
    hasElementDragOut.y = isOutCanvas.y;
    this.dispatchEvent({ type: "elementDragOut", dragOutAxle: isOutCanvas, picked: target});

  }

  canvasMouseup(e) {
    let { target } = e
    let entireDragOutAxle, dragOutAxle; 
    if(this.objectActive.move ||this.objectActive.scale || this.objectActive.rotating) this.apply();
    this.objectActive.move = false;
    this.objectActive.scale = false;
    this.objectActive.rotating = false; 

    if(target) {
      entireDragOutAxle = this.detectIsOutCanvas(target, this.dragOutDeleteDistance);  
      dragOutAxle = this.detectIsOutCanvas(target);  
      this.dispatchEvent({ 
        type: "elementDragOut", 
        dragOutAxle: {...dragOutAxle}, 
        entireDragOutAxle: {...entireDragOutAxle}, 
        picked: target
      }); 
 
      // if(isOutCanvas.y) {
      //   this.canvas.remove(target);
      //   this.dispatchEvent({ type: "elementDragOut", dragOutAxle: {...hasElementDragOut}, picked: target}); 
      //   return
      // }         
    }
    

    if(this.dragOutResetPosition && target) {
   
      let isOutCanvas_a = this.detectIsOutCanvas(target, 0);
   
      if(isOutCanvas_a.x) this.putToEdge('x', target);
      if(isOutCanvas_a.y) this.putToEdge('y', target);
    }  
    
    this.dispatchEvent({ type: "mouseup", picked: target, dragOutAxle, entireDragOutAxle}); 

  }

  detectIsOutCanvas(target, HintDistance) {
    if(!this.dragOutCanvasDelete) return;
    let bounding = target.getBoundingRect()
    // console.log('bounding', bounding)
    // console.log('target.left', target.width)

    if(HintDistance === undefined) HintDistance = this.dragOutHintDistance;
   
    let isOutX = false;
    let isOutY = false;
    if(bounding.left + bounding.width * HintDistance < 0  || bounding.left> this.canvas.width - (bounding.width * (1 - HintDistance))) {
      isOutX = true;
    } 
    if(bounding.top + bounding.height * HintDistance < 0  || bounding.top > this.canvas.height - (bounding.height * (1 - HintDistance))) {
      isOutY = true;
    }

    return {x:isOutX, y:isOutY}
  }

  addFullCanvas(fullSize, nodeName) {
   
    if(this.fullCanvasList[nodeName]) return;

    let targetNode = this.target.getObjectByName(this.active.nodeName);
    let canvas = document.createElement('canvas');
    canvas.width = fullSize;
    canvas.height = fullSize;
    this.fullCanvasList[nodeName] = {canvas};
    // if(targetNode.material.color.getHexString() !== 'FFFFFF' ) this.fullCanvasList[nodeName].backgroundColor = '#' + targetNode.material.color.getHexString()
   
  }


  addImage(url, options) {
    let that = this;

    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(url, function(oImg) {
        oImg.originY = 'center';
        oImg.originX = 'center';
        oImg.id = objectId++
        // that.canvas.add(oImg);

        console.log('addImage', oImg)
        if(options.replaceElement) {
          console.log('replaceElement')
          options.forbidAutoScale = true;
          let layerIndex = that.getElementLayerIndex(options.replaceElement);
          oImg.moveTo(layerIndex)
          that.canvas.remove(options.replaceElement);
         
          
          options = Object.assign(options, that.getElementOptionsBaseOthe(options.replaceElement, oImg))
        } 

        that.setImage(oImg, options);  
      
        if(!options.forbidAutoScale)  that.restrictImageInScreenScale(oImg);       
        // else  that.elementAlignTo(oImg, that.canvas);
     
        //  oImg.centeredScaling = true;
        //  oImg.centeredRotation = true;
        that.canvas.add(oImg);
        if(options.replaceElement)  that.canvas.setActiveObject(oImg);
      
        oImg.userData = {url},      
        that.setImageControlStyle(oImg);
        that.apply()  
        that.isDesignChange = true;  
        resolve(oImg);
      }, {crossOrigin: 'anonymous'}
      );
    })
 
  }

  addText(text, options) {

    if(!text) text = '文本' ;
    
    let textEl = new DecalsText(text);
    textEl.id = objectId++
    // textEl.originY = 'center';
    // textEl.originX = 'center';
    textEl.originY = 'center';
    textEl.originX = 'center';
    this.setTextControlStyle(textEl);

    if(options) this.setText(textEl, options);
    else this.elementAlignTo(textEl, this.canvas);  
    this.isDesignChange = true;  
    this.canvas.add(textEl);
    return textEl;
  }

  setImageControlStyle(element) {

    element.setControlsVisibility({
      mt: false, // middle top disable
      mb: false, // midle bottom
      ml: false, // middle left
      mr: false, // I think you get it
    });

    element.set({
      borderScaleFactor: 4, 
    })
    element.centeredScaling = true;
    element.centeredRotation = true;
    // element.hasControls = false;

  }
  restrictImageInScreenScale(oImg) {

    let scale, scale_x, scale_Y;
    let bounding = oImg.getBoundingRect();
    
    if(bounding.width > this.canvas.width * 0.9) {
      scale_x = this.canvas.width * 0.9 / bounding.width;
      scale = scale_x;
    }

    if(bounding.height > this.canvas.height * 0.9) {
      scale_Y = this.canvas.height * 0.9 / bounding.height;
      if(!scale || scale > scale_Y) scale = scale_Y;   
    }
    
    if(scale) {
      oImg.scale(oImg.scaleX * scale);
      if(scale === scale_Y) {
        oImg.top = this.canvas.height / 2;
        if((oImg.left - (oImg.width *oImg.scaleX)/2 ) < 0) oImg.left = (oImg.width*oImg.scaleX)/2;
      } else {
        oImg.left = this.canvas.width / 2;
        if((oImg.top - (oImg.height *oImg.scaleX)/2 ) < 0) oImg.top = (oImg.height*oImg.scaleX)/2;
      };
    };

    
  }

  setTextControlStyle(element) {    

    element.setControlsVisibility({
      bl: false,
      br: false,
      mt: false, 
      mb: false, 
      ml: false, 
      mr: false, 
      tl: false,
      tr: false
    });

    element.set({
      borderScaleFactor: 6,
      borderColor: "#2B83FF"
    })

    // element.centeredScaling = true;
    element.centeredRotation = true;
  }
  //这个接口测试用的
  renderDataURL() {
    let selected = this.canvas.getActiveObject();
    if(selected) this.canvas.discardActiveObject();
    this.hideHelperElenemts();
    this.canvas.renderAll();
    if(selected) this.canvas.setActiveObject(selected);
    this.showHelperElenemts();
    return  this.canvas.getElement().toDataURL();
    // console.log('renderAll',)   
  }

  remove(element){
    // this.removingElement = element;
    
    this.isDesignChange = true;  
    this.canvas.remove(element);
    console.log('remove(element)', this.canvas.getObjects())
  }

  apply() {
    let lastActivesElement = this.canvas.getActiveObject();
    let lastHasControls = false;
    let lastHasBorders  = false;
    // this.canvas.renderAll();
    // this.canvas.discardActiveObject();
    if(lastActivesElement) {
      lastHasControls = lastActivesElement.hasControls;
      lastHasBorders = lastActivesElement.hasBorders  ;
      lastActivesElement.hasControls = false;
      lastActivesElement.hasBorders  = false;
    }
    this.hideHelperElenemts();
    this.mergeCanvas();


    this.renderFullCanvasToMesh();
    if(lastActivesElement)  {
      lastActivesElement.hasControls = lastHasControls;
      lastActivesElement.hasBorders  = lastHasBorders;
      this.canvas.renderAll();
    }
    this.showHelperElenemts();

    // console.log('this.canvas_toDataURL_123',  this.canvas.getElement().toDataURL())
  }

  mergeCanvas() {

    this.fullCanvasDrawBackground();
    this.canvases.forEach( canvas => {
      let {nodeName, offsetRate} = canvas.userData
      let fullCanvas = this.fullCanvasList[nodeName].canvas;
      canvas.renderAll();

      this.fullCanvasDraw(fullCanvas, canvas, offsetRate);
      // console.log('fullCanvas551', fullCanvas.toDataURL())
    })
      
  }

  fullCanvasDraw(fullCanvas, canvas, pixelOffset) {
    let fullCtx = fullCanvas.getContext('2d');    
    fullCtx.drawImage(
      canvas.getElement(), 
      pixelOffset.x * fullCanvas.width, 
      pixelOffset.y * fullCanvas.height, 
      canvas.width, 
      canvas.height
    )
  }

  fullCanvasDrawBackground() {

    for( let nodeName in this.fullCanvasList) {
      let fullCanvas = this.fullCanvasList[nodeName].canvas;
      // let backgroundColor = this.fullCanvasList[nodeName].backgroundColor;
      let originMap = this.backgroundImgList[nodeName];
      let fullCtx = fullCanvas.getContext('2d');    
      let {width, height} = fullCtx.canvas;

      fullCtx.clearRect( 0, 0, width, height);
      if(originMap) fullCtx.drawImage( originMap, 0, 0, width, height);     
           
    }
  }

  renderFullCanvasToMesh() {  

    for(let nodeName in this.fullCanvasList) {
      let fullCanvas = this.fullCanvasList[nodeName].canvas;
      // console.log('fullCanvas9999', fullCanvas.toDataURL("image/png"))
      let canvasTexture = new CanvasTexture(fullCanvas); 
      // let canvasTexture = new TextureLoader().load( this.canvas.getElement().toDataURL("image/png") ); 
      let currentTarget = this.target.getObjectByName(nodeName);
      // canvasTexture.colorSpace = SRGBColorSpace;
      canvasTexture.encoding = sRGBEncoding

      // canvasTexture.wrapT = RepeatWrapping;
      canvasTexture.wrapS = RepeatWrapping; 
      canvasTexture.wrapT = RepeatWrapping;

      canvasTexture.repeat.y = -1
      currentTarget.material.map = canvasTexture;  

 
      currentTarget.material.needsUpdate = true


      
    }
    
  }

  saveBackgroundImg() {

    let selectName = [];
    this.backgroundImgList = {};

    if(this.activeIndex != -1) {
      this.active.children.forEach(item => {
        if(selectName.includes(item.nodeName)) return;
        selectName.push(item.nodeName);
      })
    } else {
      selectName.push(this.active.nodeName);
    }
    // console.log('selectName', selectName)
    selectName.forEach(nodeName => {
      if(this.backgroundImgList[nodeName]) return;      
      let target = this.target.getObjectByName(nodeName);
      if(target && target.material.map) this.backgroundImgList[nodeName] = target.material.map.source.data;    
    })
  }

  changeMapEvent(e) {
    this.saveBackgroundImg();
    this.apply()
  }

  sideSwitchTo(id) {
    
    let { prints } = this.target.activeMesh.userData;

    if(this.activeIndex === -1 && this.active.id === id) return
    else if(this.activeIndex > -1 && this.active.children[this.activeIndex].id === id) return;

    //如果id 和当前 this.activeIndex是同一组的话，就只切换this.canvas即可
    if(this.activeIndex > -1) {
      let currentIndex = this.active.children.findIndex((item) => {return item.id === id} );
      if(currentIndex > -1) {
        this.activeIndex = currentIndex;
        this.canvas = this.canvases[this.activeIndex];
        return
      }
    }

    this.switchActiveInfo(prints, id);  
    this.addCanvas(this.active);
    this.apply();

  }

  switchActiveInfo(prints, id) {

    for(let side of prints.sides) {

      if(side.type === 'group') {
        for(let i=0; i < side.children.length; i++) { 
          if(side.children[i].id === id ) {
            this.active = side;
            this.activeIndex = i;
            return
          }
        }

      } else if(side.id === id) {
        this.active = side;
        this.activeIndex = -1;
        return
      }
    }
  }

  triggerClickByUv(object, uv) {

    if(!object.material.map) return
    
    let nodeName = object.name;
    let sideIds = [];

    let mapData = object.material.map.source.data
    let mousePos = new fabric.Point(mapData.width *uv.x, mapData.height *uv.y);
    if(this.activeIndex === -1 && this.active.nodeName === nodeName) {
      sideIds.push(this.active.id);
    } else {
      this.active.children.forEach( sideInfo => {if(sideInfo.nodeName === nodeName) sideIds.push(sideInfo.id)});
    }

    if(sideIds.length === 0) return;

    for(let canvas of this.canvases) {   
      if(!sideIds.includes(canvas.userData.sideId)) return;
      let picked;
      let pickedsAll = this.getObjcetByClickCanvas(mousePos, canvas, mapData.width);  
      if(pickedsAll) {
        picked = pickedsAll[pickedsAll.length - 1]
        // 现在暂时只能先选中一个
        console.log('出现了')
        canvas.setActiveObject(picked);
        this.apply();
        return picked;
      };
    }


  }

  getObjcetByClickCanvas(clickPos, canvas, fullSize) {

    let { offsetRate, pixelRate } = canvas.userData;
    let pickeds = [];
    let pixelWidth = fullSize * pixelRate.x;
    let pixelHeight = fullSize * pixelRate.y;
    let left = fullSize * offsetRate.x;
    let top = fullSize * offsetRate.y;

    if(clickPos.x < left || clickPos.y < top || 
      clickPos.x > left + pixelWidth || clickPos.y > top + pixelHeight) return;
    let objects = canvas.getObjects();
    let localPoint = clickPos.clone();
    localPoint.x -= left;
    localPoint.y -= top;
    objects.forEach(item => {
      // console.log('left', item.left)
      // console.log('item', item.getBoundingRect(undefined, true))
      if(!item.selectable) return;
      let bounding =  item.getBoundingRect(undefined, true);
      if(this.containsPoint(bounding, localPoint)) pickeds.push(item)
    })

    if(pickeds.length > 0) return pickeds;
  }

  containsPoint(bounding, point) {
    let {left, top, width, height} = bounding
    if(point.x < left || point.y < top || 
      point.x > left + width || point.y > top + height) return;
      return true;
  }

  async addElements(elementInfos, canvasLocation) {

    let elements = []
    for(let elInfo of elementInfos) {
      let el;
      if(elInfo.type === 'image') {
        el =  await this.addImage(elInfo.url, elInfo);

      } else if(elInfo.type === 'text') {
        el =  this.addText(elInfo.text, elInfo);
 
      }
      elements.push(el)
    }

    if(canvasLocation) this.setElementsPositionByLocation(elements, canvasLocation);
    return elements;

  }

  setElementsPositionByLocation(elements, canvasLocation) {

    let elAllBounding = this.getElementsBounding(elements);
    let isAlignCenter = false;
    let offset = {
      left: 0,
      top:0
    }

    let elAllCenterPos = (elAllBounding.left + elAllBounding.width /2);
    if(canvasLocation && elAllBounding.left + elAllBounding.width > this.canvas.width / 2) isAlignCenter = true;

    if(isAlignCenter) offset.left = (this.canvas.width / 2) - elAllCenterPos;
    else if(canvasLocation === 'right') offset.left = this.canvas.width / 2;
    elements.forEach((el) => el.left += offset.left);    
  }

  getElementsBounding(elements) {

    let elAllMax = {x:0, y:0}
    let elAllMin = {x:0, y:0}

    elements.forEach((el, index)=> {      
      let currentBounding = el.getBoundingRect();

      if(index === 0) {
        elAllMin.x = currentBounding.left;
        elAllMin.y = currentBounding.top;

        elAllMax.x = currentBounding.left + currentBounding.width;
        elAllMax.y = currentBounding.top + currentBounding.height;

      } else {
        if(currentBounding.left < elAllMin.x) elAllMin.x = currentBounding.left;
        if(currentBounding.top < elAllMin.y) elAllMin.y = currentBounding.top;
  
        if(currentBounding.left + currentBounding.width > elAllMax.x) elAllMax.x = currentBounding.left + currentBounding.width;
        if(currentBounding.top + currentBounding.height > elAllMax.y) elAllMax.y = currentBounding.top + currentBounding.height;
      }    

    })

    return {
      left: elAllMin.x, 
      top: elAllMin.y,
      width: elAllMax.x - elAllMin.x,
      height: elAllMax.y - elAllMin.y,
    }
  }

  setImage(el, options = {}) {  

    if(options.alignUv) {
      let  offset =  this.getLeftTopByUv(el, options.alignUv);
      options.left = offset.x;
      options.top = offset.y;
    } else if(!options.forbidAutoAlign) {
      this.elementAlignTo(el, this.canvas, 'leftSideCenter')
    }

    if(options.left >= 0) el.left = options.left;
    if(options.top >= 0) el.top = options.top;   
    if(options.scale >= 0) el.scale(options.scale);
 
    if(options.width >= 0) el.width = options.width;
    if(options.height >= 0) el.height = options.height;
    if(options.angle !== undefined) el.angle = options.angle;
   
  }

  getElementOptionsBaseOthe(referElement, newElement) {

    let options = {
      left: referElement.left,
      top: referElement.top,
      scale: referElement.scaleX,
      angle: referElement.angle
    }

  
    let widthRatio = referElement.width / newElement.width;
    let heightRatio = referElement.height / newElement.height;

    if(widthRatio < heightRatio) options.scale *= widthRatio;
    else options.scale *= heightRatio;

    return options;
  }

  async replaceImage(oldElement, newElementUrl) {
    let that = this;
    await this.addImage(newElementUrl, {replaceElement: oldElement})
    // oldElement.setSrc(newElementUrl, function(oImg) {
    //   // oImg.userData.url = newElementUrl;
    //   // that.apply()  
    //   // console.log('5583replaceImage', oImg)
    // })
    

  }

  setText(textEl, elInfo) {

    let {ptToPxRatio} = this.target.activeMesh.userData.prints;
    let options = {}

    if(elInfo.alignUv) {
      let  offset =  this.getLeftTopByUv(textEl, elInfo.alignUv);
      options.left = offset.x;
      options.top = offset.y;
    } 

    if(elInfo.left > 0) options.left = elInfo.left;
    if(elInfo.top > 0) options.top = elInfo.top;
    if(elInfo.bold) options.fontWeight = 'bold';
    if(elInfo.italic) options.fontStyle = 'italic';
    if(elInfo.size > 0) options.fontSize = elInfo.size * ptToPxRatio ;
    if(elInfo.scale > 0) textEl.scale(elInfo.scale);
    if(elInfo.color) options.fill = elInfo.color;
    if(elInfo.angle) options.angle = elInfo.angle;
    if(elInfo.textAlign) options.textAlign = elInfo.textAlign;

    if(elInfo.font) textEl.setOnlineFont(elInfo.font).then(()=> {
      this.apply();
    });

    textEl.set(options)
   
  }

  elementAlignTo(element, canvas, location = 'center') {
    
    let left, top;

    if(location === 'center') {
      left = (canvas.width) / 2;
      top = (canvas.height) / 2;
    } else if(location === 'leftSideCenter') {
      left = ((canvas.width) / 2) / 2;
      top = (canvas.height) / 2;
    }

    element.set({left , top}); 
  }

  clearAll() {
    this.canvases.forEach((item) => {
      item.clear();
    })
  }

  getLeftTopByUv(element, alignUv) {

    let { offsetRate } = this.canvas.userData;

    let currentUv = {
      x: alignUv.x - offsetRate.x,
      y: alignUv.y - offsetRate.y,
    }

    let currentPoint = {
      x: currentUv.x * this.fullSize,
      y: currentUv.y * this.fullSize
    }


    let offset = {
      x: currentPoint.x - element.left,
      y: currentPoint.y - element.top
    }

    let endPos = {
      x: element.left + offset.x,
      y: element.top + offset.y
    };

    return endPos
    
  }

  setLeftTopByUv(element, uv) {
    let offset = this.getLeftTopByUv(element, uv);
    element.left = offset.x;
    element.top = offset.y;
    this.apply()
  }

  getElementInfos() {

    let elementsArray = [];
    let elements = this.canvas.getObjects();
    elements.forEach(item => {
      if(item.type === 'image') elementsArray.push(this.imageToJson(item));
      else if(item.type === 'text') elementsArray.push(this.textToJson(item));  
    })

    return elementsArray;
  }

  imageToJson(image) {

    let info = {};
    info.type = 'image'
    info.width = image.width;
    info.height = image.height;
    info.left = image.left;
    info.top = image.top;
    info.scale = image.scaleX; //因为，现在只能等比例放大，所以只需要scaleX 或 scaleY 其中一个即可
    info.angle = image.angle;
    if(image.userData) {
      info.url = image.userData.url
    }
   

    return info

  }

  textToJson(text) {
    let {ptToPxRatio} = this.target.activeMesh.userData.prints;
    let info = {};
    info.type = 'text'
    info.left = text.left;
    info.top = text.top;
    info.bold = text.fontWeight === 'bold';
    info.italic = text.fontStyle === 'italic';
    info.color = text.fill;
    info.angle = text.angle;
    info.scale = text.scaleX;
    info.size = text.fontSize / ptToPxRatio;
    info.text = text.text;
    info.textAlign = text.textAlign;

    if(text.userData.fontUrl)
    info.font = text.userData.fontUrl
    return info;
    
  }
  exportImage(imageWidth = 2000) {

    let multiplier  = imageWidth / this.canvas.width;
    return  this.canvas.toDataURL({multiplier})

  }

  async getOnlinePreview(previewUrl) {

    
    let data = await  ApiService.getJson(previewUrl);

    let clipPath = new fabric.Polygon(data.clipPath.points, {
      originX: 'center',
      originY: 'center',
      left:0,
      top:0,
      fill: '#fff'
    })
    let templateImg = await loadImageByFabric(data.templateUrl, );
    this.canvas.renderAll();
    let cupMap = await loadImageByFabric(this.canvas.toDataURL(), {clipPath});


    let scale = clipPath.height / cupMap.height;
    cupMap.scale(scale);
    clipPath.scale(1 / scale);

    let tempCanvas = new fabric.Canvas('c', { 
      width:templateImg.width, 
      height:templateImg.height,      
    });  
    let clipAreaCenterX = data.offsetRate.x * tempCanvas.width + clipPath.width / 2;
    let cupMapCenterX = (cupMap.width * cupMap.scaleX) / 2;
    cupMap.left = clipAreaCenterX - cupMapCenterX;
    cupMap.top = data.offsetRate.y * tempCanvas.height;
    cupMap.set({clipPath});
    cupMap.opacity = 0.8;

    tempCanvas.add(templateImg);
    // tempCanvas.add(clipPath);
    tempCanvas.add(cupMap);
    let dataUrl = tempCanvas.toDataURL();
    return dataUrl;
  }

  getElementBound3D (element) {
    let bounding = element.getBoundingRect();
    let { offsetRate, nodeName } = this.canvas.userData;
    let currentFullCanvas  = this.fullCanvasList[nodeName].canvas;

    // console.log('getElementBound3D', this.canvas.toDataURL())
    let offsetPiex = {
      x:  this.fullSize * offsetRate.x,
      y:  this.fullSize * offsetRate.y,
    }
    let startUv = new Vector2(
      (offsetPiex.x + bounding.left) / currentFullCanvas.width,
      (offsetPiex.y + bounding.top) / currentFullCanvas.height,
    )

    let endUv = new Vector2(
      (offsetPiex.x + bounding.left + bounding.width) / currentFullCanvas.width,
      (offsetPiex.y + bounding.top + bounding.height) / currentFullCanvas.height,
    )

    if(startUv.x < 0) startUv.x = 0;
    if(startUv.y < 0) startUv.y = 0;
    if(endUv.x > 1) endUv.x = 0.99; //模型生成的时候，会有一些偏差，所以先要用这个值
    if(endUv.y > 1) endUv.y = 0.99; //模型生成的时候，会有一些偏差，所以先要用这个值
    // endUv.y = 1- endUv.y
    let targetNodeName = this.canvas.userData.nodeName;
    let targetNode = this.target.activeMesh.getObjectByName(targetNodeName);
    let centerUv = startUv.clone().add(endUv).multiplyScalar(0.5);
    let min = this.getPoint3dByUv(targetNode, startUv);
    let max = this.getPoint3dByUv(targetNode, endUv);
    let center = this.getPoint3dByUv(targetNode, centerUv);

    return {max, min, center}
  }

  getPoint3dByUv(mesh, uv) {

    let geometry = mesh.geometry;
    let positions = geometry.getAttribute('position');
    let uvs = geometry.getAttribute('uv');
    let matrixWorld = mesh.matrixWorld.clone();

    if(geometry.index) {
      for(let i=0; i<geometry.index.count; i +=3) {
        let  index_0 = geometry.index.array[i];
        let  index_1 = geometry.index.array[i+1];
        let  index_2 = geometry.index.array[i+2];
        let uv_0 = new Vector2().fromBufferAttribute(uvs, index_0);
        let uv_1 = new Vector2().fromBufferAttribute(uvs, index_1);
        let uv_2 = new Vector2().fromBufferAttribute(uvs, index_2);


        let triangle = [uv_0, uv_1, uv_2];        
        let box2 = new Box2();
        triangle.forEach(item => box2.expandByPoint(item));
        if(!box2.containsPoint(uv)) continue;
        if(point2DInTriangle(uv, triangle)) {


          let pos_0 = new Vector3().fromBufferAttribute(positions, index_0);
          let pos_1 = new Vector3().fromBufferAttribute(positions, index_1);
          let pos_2 = new Vector3().fromBufferAttribute(positions, index_2);

          pos_0.applyMatrix4(matrixWorld);
          pos_1.applyMatrix4(matrixWorld);
          pos_2.applyMatrix4(matrixWorld);          
         
          let weights = getTrianglePointWeight2D(triangle, uv);
          let weightPos = pos_0.clone().multiplyScalar(weights[0]);

          weightPos.add(pos_1.clone().multiplyScalar(weights[1]));
          weightPos.add(pos_2.clone().multiplyScalar(weights[2]));

          let weightsAdd = weights[0] + weights[1] + weights[2];
          let centerPos = weightPos.divideScalar(weightsAdd);          

          return centerPos;
        }
        
      }
    }
  }

  elementRenderToDataURL(element) {
    let bounding =  element.getBoundingRect();
    let tempCanvas = document.createElement('canvas');
    let ctx = tempCanvas.getContext('2d');
    let lastLeft = element.left;
    let lastTop = element.top;
    element.left -= bounding.left;
    element.top -= bounding.top;
    tempCanvas.width = bounding.width;
    tempCanvas.height = bounding.height;    

    element.render(ctx);
    element.left = lastLeft;
    element.top = lastTop;

    return tempCanvas.toDataURL()
    // console.log('tempCanvas', tempCanvas.toDataURL())

  }

  // openNavigationMap() {
  //   this.isNavigationMap = true;
  //   if(!this.detailViewCanvas) this.addDetailViewCanvas();
    
  // }

  closeNavigationMap() {
    this.isNavigationMap = false;
  }

  addDetailViewCanvas () {  
    let that = this;
    
    let canvas = document.createElement('canvas');
    canvas.width = this.canvas.width;
    canvas.height = this.canvas.height;
    

    this.detailViewEl.appendChild(canvas );

    this.detailViewCanvas = new fabric.Canvas(canvas, {
      enableRetinaScaling: false,  
      selectionKey: null
    })

    this.detailViewCanvas.on('object:scaling', function(e){ 
      let {target} =e

      if(!target) return;
      let {originElement} = target;

      originElement.scale(target.scaleX)
      // originElement.scaleY = target.scaleY;

      if(that.timer_a) clearTimeout(that.timer_a)
      that.timer_a = setTimeout(()=>   {
        that.canvas.renderAll()
      }, 50);
    
    });
    this.detailViewCanvas.on('object:rotating', function(e){ 

      let {target} =e
      if(!target) return;
      let {originElement} = target;

      originElement.angle = target.angle;
      if(that.timer_a) clearTimeout(that.timer_a)
      that.timer_a = setTimeout(()=>   {
        that.canvas.renderAll()
      }, 50);
    })


  }


  updateNavigationMapCanvas() {
    let objects = this.canvas.getObjects();
    this.detailViewCanvas.clear()
    if(objects.length > 0) objects.forEach((item) => this.detailViewCanvas.add(item));
    if(this.detailViewCanvas) this.detailViewCanvas.renderAll();
    
  }

  renderToDataURL() {
    return this.canvas
  }

  saveState() { 

    let elements = this.canvas.getActiveObjects();
    if(elements.length === 0)  return;

    this.state = { elements: []};
    elements.forEach((item)=> {
      this.state.elements.push(item.toJSON());
    })

    console.log('saveState', this.state);
  }

  getIsChange(){

  }

  getElementLayerIndex(element) {

    let objects = this.canvas.getObjects();
    if(objects.length === 0) return;

    for(let i=0; i<objects.length; i++) {
      if(objects[i].id === element.id) return i;
    }

  }
 
  definePropertyAll() {}

  getPrintInfoByName(id) {
    let index =  this.target.userData.prints.sides.findIndex(item => {return item.id === id})  
    return cloneObject(this.target.userData.prints.nodes[index])
  }
}
Object.setPrototypeOf(SurfaceDecals.prototype, EventDispatcher.prototype);
