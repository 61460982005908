<template>
  <div id="step1" class="three-editor" ref="threeEditorEl" :class="{expand: isExpandThree, transitionAnimation: isTransitionAnimation}">
    <div v-if="isShowIntroBtn" class="new-tips" @click="viewIntro">
      <i class="svg-icon-operating"></i>
      <div >操作指引</div>
    </div>
    <div  v-if="isShowIntroBtn"  class="submits" @click="handleComplete">
      <i class="svg-icon-complete"></i>
      <div>完成</div>
    </div>
    <div id="step3" class="canvas-all">

      <div class="replace-image-icon" v-if="pickedElement && pickedElement.type == 'image'" @click="replaceImageClick">
        <div class="icon">
          <!-- <van-icon name="photo" size="20" color="#2B83FF" /> -->
          <i class="svg-icon-changePic"></i>
        </div>
        <div class="title">
          替换
        </div>
      </div>
      <div class="canvas-side-label">
        <div class="side-label-left">正面</div>
        <div class="side-label-right">反面</div>
      </div>
      <div  class="editor-canvas" ref="editorCanvas"></div>
      <div class="canvas-close-icon" @click="canvasCloseClick" ><van-icon name="close" color="rgb(137 137 137)" size="30" /></div>
    </div>
    <!-- <div 
      class="show-preview-btn" 
      :class="{active: previewDataURL, invalid: isPreviewInvalid }"  
      v-if="!isPreviewModel"
      @click="showPreview">
        生成展示图片
    </div> -->
    <div v-if="previewDataURL" class="preview-image-list"></div>
    <img v-if="previewDataURL" class="preview-image" :src="previewDataURL">
    
    <!-- <PrintFaces></PrintFaces> -->
    <div ref="threeContainer" class="three-container" :class="{'edit-collapse': isEditCollapse}"></div>
  </div>

</template>

<script>
import Vue from 'vue';
import { Button, Icon, Toast, Dialog } from 'vant';
import { Viewer, MaterialMaker, SurfaceDecals, CSS2DLabel, ProjectionDecals } from "three-style-editor";
import axios from 'axios';
import { createElement } from 'three-style-editor/src/utils/helper';
import APIServe from '@/plugins/APIServe.ts';
import APIServePc from '@/plugins/APIServePc.ts';
import getPhotographySceneOptions from './getPhotographySceneOptions'
import * as wx from "weixin-js-sdk"

Vue.use(Button);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Dialog);

export default {
  name: 'LayoutView',
  components: {
    // PrintFaces
  },
  
  props: {
    msg: String
  },
  data() {
    return {
      introOption: { // 参数对象
        prevLabel: '上一步',
        nextLabel: '下一步',
        skipLabel: '跳过',
        doneLabel: '完成',
        tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
        // highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
        exitOnEsc: true, /* 是否使用键盘Esc退出 */
        exitOnOverlayClick: false, /* 是否允许点击空白处退出 */
        keyboardNavigation: true, /* 是否允许键盘来操作 */
        showBullets: false, /* 是否使用点显示进度 */
        showProgress: false, /* 是否显示进度条 */
        scrollToElement: true, /* 是否滑动到高亮的区域 */
        overlayOpacity: 0.8, // 遮罩层的透明度 0-1之间
        positionPrecedence: ['bottom', 'top', 'right', 'left'], /* 当位置选择自动的时候，位置排列的优先级 */
        disableInteraction: true, /* 是否禁止与元素的相互关联 */
        hidePrev: true, /* 是否在第一步隐藏上一步 */
        // hideNext: true, /* 是否在最后一步隐藏下一步 */
        steps: [], /* steps步骤，可以写个工具类保存起来 */
      },

      tipsImg1: require('../../public/img/example1.png'),// 新手引导的提示图片
      tipsImg2: require('../../public/img/example2.png'), // 新手引导的提示图片
      tipsImg3: require('../../public/img/example3.png'), // 新手引导的提示图片

      left: 0,
      viewer: null,
      bowlCover: null,
      seriesModel: null,
      materialMaker: null,
      surfaceDecals: null,
      previewDataURL: null,
      previewDataURLAll:null,
      isShowEditorCanvas: false,
      isPreviewInvalid: false,
      isBowlCover: false,
      isTransitionAnimation: true,
      isExpandThree: false,
      isEditCollapse:false, 
      materialInfo: null,
      modelColor: null,
      isShowElementMoveLabel: false,
      isPreviewModel:false,
      isMaterialChange: false,
      isBowlCoverChange: false,
      isShowIntroBtn: true,
      schemeId: null,
      pickedElement: null,
      modelId: 3,
      modelType:0,
      labelIconElState: {
        isMousedown: false,
      },
      editorOptions: {
        decoderPath:'/draco'
      },
      urls: [
      '/data/Cup_V109_b.glb'
      ]
  }},

  methods:{
    viewIntro(){
        this.initGuide()
        setTimeout(() => this.showEditorCanvas());
        
    },
    initGuide() {
      // 绑定标签元素的选择器数组
      this.introOption.steps = [
          { title: '3D全景工作台', element: '#step1', intro: `滑动模型进行预览，点击模型上的图案，可在2D画布进行编辑，包括移动，缩放和旋转等操作。<img src="` + this.tipsImg1 + `" alt="" style="width: 150px;margin-top: 15px;"/>`},
          { title: '素材面板', element: '#step2', intro: `提供各类模板，图片和文字素材，也提供对素材的属性编辑，画板可收缩，可上划面板快速跳转功能页面。<img src="` + this.tipsImg2 + `" alt="" style="width: 125px;margin-top: 15px;"/>`, },
          { title: '2D画板', element: '#step3', intro: `您可以在这里对添加的图片文字进行编辑，如缩放，旋转和移动，同时画板也支持双指手势缩放。<img src="` + this.tipsImg3 + `" alt="" style="width: 125px;margin-top: 15px;"/>`, },
      ]
      this.$intro()
          .setOptions(this.introOption)
          // 点击结束按钮后执行的事件
          .oncomplete(() => {
              
              setTimeout(() => this.canvasCloseClick());
              
          })
          // 点击跳过按钮后执行的事件
          .onexit(() => {
            setTimeout(() => this.canvasCloseClick());
          })
          // 确认完毕之后执行的事件
          .onbeforeexit(() => {
            setTimeout(() => this.canvasCloseClick());
          })
          .start()
    },

    pushHistory(str = 'title', url = '#'){
        let state = {
          title: str,
          url: url,
        };
        console.log(state,"pushHistory");
        window.history.pushState(state, state.title, state.url);
    },
   initThreeScene() { 
    
    if(this.$route.path.indexOf('previewscheme')>-1) {      
      this.isShowIntroBtn = false;
      this.isPreviewModel = true;
    }
    this.viewer = new Viewer(this.$refs.threeContainer, this.editorOptions);
    console.log('$route', this.$route);

    //测试用的--开始
    // let params = {
    //   id: this.modelId
    // }
    // let url = './mock/cup-param.json'
    // let url = './mock/thermos_cup_white _Param.json'
    // axios.get(url).then((resp)=> {
    //   console.log('resp', resp.data);
    //   this.loadModel(resp.data);  
    //   if(!this.isPreviewModel) this.initPhotographyScene();  
    // })

    // if(url) return
    //测试用的--结束

    // let currentAPIServe = APIServe;
    // if(this.$route.query.productId) currentAPIServe = APIServePc;
    // if(!this.isPreviewModel) this.initPhotographyScene();
    // currentAPIServe.get('/design3dModel/getDesign3dModelById',{params}).then((e)=> {
    //   let resp = e.data
    //   if(resp.code !== "200") return
    //   let modelConfig = resp.data;

    //   this.modelConfigToJSON(modelConfig)
      
    //   console.log('modelConfig', modelConfig)    
       
    // })
    if(!this.isPreviewModel) this.initPhotographyScene(); 

    if(this.$route.query.schemeId) {
      this.loadPreviewscheme(this.$route.query.schemeId)

    }else if(this.$route.query.froms){
      this.loadPreviewscheme(this.$route.query.productId)

    }else if(this.$route.query.test) {
      //用于测试本地json
      console.log('thermos_cup_classical_Param')
      this.getModelInfoTest('thermos_cup_classical_Param.json').then((e)=> {
        console.log('currentConfig', e)
        this.loadModel(e)
      })
     
    }else {
      this.$eventBus.$emit('modelLoading')
      this.getModelInfo(this.modelId).then(modelConfig => this.loadModel(modelConfig));
    }
   
 
    // setTimeout(()=>  this.viewer.stopRender(), 5000)
    // setTimeout(()=>  this.viewer.startRender(), 8000)   

   },

   async getModelInfoTest(jsonName) {
    
    console.log('getModelInfoTest');
    let resp = await axios.get('./mock/'+ jsonName)
    resp = resp.data
    // console.log('getModelInfoTest_resp', resp);
    let modelConfig = resp;  

    return modelConfig;  
   },

   async getModelInfo(modelId) {
    
    let params = {
      id: modelId
    }

    let currentAPIServe = this.$route.query.froms ? APIServePc : APIServe;
  
    let resp = await  currentAPIServe.get('/design3dModel/getDesign3dModelById',{params})
    resp = resp.data
    if(resp.code !== "200") return
    let modelConfig = resp.data;

    this.modelConfigToJSON(modelConfig);
    console.log('modelConfig', modelConfig);
    return modelConfig;
  
   },

  modelConfigToJSON(config) {
    config.series = JSON.parse(config.series); 
    if(config.camera)  config.camera = JSON.parse(config.camera); 
    if(config.previewModeCamera)  config.previewModeCamera = JSON.parse(config.previewModeCamera);
    if(config.lights)  config.lights = JSON.parse(config.lights);  
  },

 
   loadModel(config) {

    if(config.type) this.modelType = config.type;

    // config.url = './data/thermos_cup_white.glb'

    if(config.enableEnvironment) {
        this.viewer.createEnvironment('./images/environment/venice_sunset_1k.hdr')      
    } else this.viewer.closeEnvironment();
    return this.viewer.loadSeriesModel(config).then(({model}) => {

      document.querySelector('.spinners__box').style.display = 'none';
      setTimeout(()=> window.dispatchEvent(new Event('resize')));

      // let seriesCup = model;
      this.seriesModel = model;
      this.cupParam = config;

      // console.log('this.cupParam', config)

      // this.cupParam.lights = [
        // {type:'directionalLight', intensity: 5, position:{x:0, y:10, z:0}},
      //   {type:'ambientLight', intensity: 3},
      // ]

      this.viewer.clearLight();
      if(this.cupParam.lights) this.viewer.addLights(this.cupParam.lights);
      else this.viewer.addDefaultLight();  
      if(config.enableEnvironment && config.environmentIntensity >= 0) {
        this.viewer.setNodeEnvIntensity(this.seriesModel, config.environmentIntensity);
      }
 
      //应用贴花工具      
      this.surfaceDecals = new SurfaceDecals(this.seriesModel, this.$refs.editorCanvas);
      this.surfaceDecals.dragOutCanvasDelete = true;
      // this.surfaceDecals.dragOutResetPosition = true;
      // this.surfaceDecals.openNavigationMap();

      //config.urlExtra是纸杯盖模型的url, 现在这个功能已经隐藏，先注释掉
      // if(config.urlExtra) {
      //   this.addBowlCover(config.urlExtra).then(() => {        
      //     if(this.$route.query.schemeId) {
      //       this.loadPreviewscheme(this.$route.query.schemeId)
      //     }else if(this.$route.query.productId){
      //       this.loadPreviewscheme(this.$route.query.productId)
      //     }
      //   });
      // }   
      
      console.log('isPreviewModel', this.isPreviewModel)
      if(this.isPreviewModel) {
       
         if(config.previewModeCamera) this.viewer.setCameraView(config.previewModeCamera);
         else if(config.camera)  this.viewer.setCameraView(config.camera);

          //  this.setPreviewModelVewe();
      } else {

        if(config.camera) this.viewer.setCameraView(config.camera)
        // this.setInitVewe(config.camera);    

        this.viewer.addEventListener('click', this.viewerClickEvent);  
        this.surfaceDecals.addEventListener('selectChange', this.selectChangeEvent);
        this.surfaceDecals.addEventListener('elementDragOut', this.elementDragOutEvent);
        this.surfaceDecals.addEventListener('mouseup', this.elementMouseupEvent);
      }
      
      if(this.$route.query.imageId) this.loadImageById(this.$route.query.imageId);
      console.log('seriesModel.activeMesh.userData', this.seriesModel.activeMesh.userData)

      if(this.seriesModel.activeMesh.userData.colorAreas) this.$eventBus.$emit('setShowModelSelectColor', true);
      else this.$eventBus.$emit('setShowModelSelectColor', false);

      this.addMaterialMaker()
      this.$eventBus.$emit('modelLoadComplete', this.modelId, this.modelType)
    })
   }, 

   switchModel(modelId) {
    this.$eventBus.$emit('modelLoading')
    if(this.seriesModel) {   
      this.seriesModel.dispose();
      this.seriesModel = null;
      document.querySelector('.spinners__box').style.display = 'block';
    }
    this.modelId = modelId;
    this.getModelInfo(this.modelId).then(modelConfig => this.loadModel(modelConfig));

   },

   setInitVewe(config) {
      // this.viewer.camera.position.copy({
      //     "x": -0.23163435161356216,
      //     "y": 0.1350007691555471,
      //     "z": -0.04871775307213377
      // });
      // this.viewer.camera.lookTo(this.seriesModel.name);
      // this.viewer.controls.enablePan = false;
      // this.viewer.controls.maxDistance  = 0.51;
      // this.viewer.controls.minDistance = 0.24;
      // this.viewer.controls.maxPolarAngle  = Math.PI / 1.5;
      // this.viewer.controls.minPolarAngle  = Math.PI / 4;
        // console.log('Math.PI / 1.5', Math.PI / 1.5)
        // console.log('Math.PI / 4', Math.PI / 4)
      // if(config) this.viewer.setCameraView(config);
      // else this.viewer.setCameraViewBaseModel(this.seriesModel);
 
   },

   setPreviewModelVewe() {
      this.viewer.camera.position.copy({
          "x": -0.3868716567048821,
          "y": 0.1864595327075219,
          "z": -0.08136754203624877
      });
      this.viewer.camera.lookTo(this.seriesModel.name);
      this.viewer.controls.enablePan = false
      this.viewer.controls.maxDistance  = 0.51;
      this.viewer.controls.minDistance = 0.24;
      this.viewer.controls.maxPolarAngle  = Math.PI / 1.5;
      this.viewer.controls.minPolarAngle  = Math.PI / 4;
   },

   elementMouseupEvent(e) {
    // console.log('elementMouseupEvent',e )
    let { picked, entireDragOutAxle} = e;
    // console.log('elementMouseupEvent', entireDragOutAxle)
    // if(picked) this.showElementMoveLabel(true, picked);
    if(this.toast) {
      this.toast.clear();
      this.toast = null;
    }

    if(picked && entireDragOutAxle && (entireDragOutAxle.x || entireDragOutAxle.y)) {
      Dialog.confirm({
        title: '提示',
        message: '是否删除该元素',
        cancelButtonText:'否',
        confirmButtonText:'是',
      }).then(() => {
        // on confirm
        console.log('anvas.remov', picked)
        // this.surfaceDecals.canvas.discardActiveObject();
        this.surfaceDecals.remove(picked);      
        this.surfaceDecals.apply();

      })
      .catch(() => {
        if(entireDragOutAxle.x) this.surfaceDecals.putToEdge('x', picked);
        if(entireDragOutAxle.y) this.surfaceDecals.putToEdge('y', picked);      
       
        this.surfaceDecals.canvas.renderAll();
        setTimeout(() => this.surfaceDecals.apply());
    
      });
    }
   },

   viewerClickEvent(e) {
    let { picked } = e;
    let pickedElement = null;
    if(picked && this.surfaceDecals) pickedElement = this.surfaceDecals.triggerClickByUv(picked.object, picked.uv)
    // this.pickedElement = pickedElement;
    if(pickedElement) this.pickedElementEvent(pickedElement);
    this.$eventBus.$emit("selectElement", pickedElement);
    
   },

   pickedElementEvent(element) {

    if(this.isExpandThree) {
      this.$eventBus.$emit('changeEditToolsState', 'normal');
      this.expandThreeEvent(false, false);      
    }

    // this.showElementMoveLabel(true, element); 
    window.dispatchEvent(new Event('resize'));
    setTimeout(() => this.showEditorCanvas());
    this.$eventBus.$emit("selectElement", element);
    // console.log('element', element.type, element)
   },

   showProjectionDecals(isShow, element) {

    if(!isShow) this.projectionDecals.visible = isShow;
    else {
      if(!this.projectionDecals) this.projectionDecals = this.addProjectionDecals(element);
      this.projectionDecals.visible = true;
      this.projectionDecals.bounding = this.surfaceDecals.getElementBound3D(element);
      this.projectionDecals.mapUrl = this.surfaceDecals.elementRenderToDataURL(element);
      this.projectionDecals.updata();
    }

   },

   addProjectionDecals(element) {
    let elementBound = this.surfaceDecals.getElementBound3D(element);
    let options = {
      bounding: elementBound,
      // mapUrl: this.surfaceDecals.elementRenderToDataURL(element),
    }
    return new ProjectionDecals(this.seriesModel.activeMesh, options);
   },

   showElementMoveLabel(isShow, element) {
    let pos;
    let elementBound;

      if(!isShow) {
        if(this.elementMoveLabel) this.viewer.remove(this.elementMoveLabel);      
        this.isShowElementMoveLabel = false;
        
      }else {

        elementBound = this.surfaceDecals.getElementBound3D(element)
        pos = {...elementBound.max}
        pos.y = elementBound.min.y;
        if(!this.elementMoveLabel) this.elementMoveLabel = this.addElementMoveLabel(pos);
        this.elementMoveLabel.target.copy(pos);
        this.viewer.add(this.elementMoveLabel);
      }
   },

   addElementMoveLabel(pos) {
    
      // this.surfaceDecals.elementRenderToDataURL(element);
      let html = `
        <div class="label-icon"></div>
      `
      let domEl = createElement('div', 'element-label', html);

      let labelIconEl = domEl.querySelector('.label-icon');
      labelIconEl.style.width = '30px';
      labelIconEl.style.height = '30px';
      labelIconEl.style.background = 'rgb(54 167 244 / 79%)';
      labelIconEl.style.borderRadius = '15px';
      labelIconEl.style.boxShadow = '0px 0px 10px 0px rgba(0, 0, 0, 0.2)';
      labelIconEl.style.pointerEvents = 'all';
      labelIconEl.addEventListener('touchstart', this.labelIconElTouchstart);
      labelIconEl.addEventListener('touchmove', this.labelIconElTouchmove);
      labelIconEl.addEventListener('touchend', this.labelIconElTouchend);

      let elementLabel = new CSS2DLabel(pos, {domEl})

      return elementLabel;    
  },

  labelIconElTouchstart(e) {

    let { touches } = e;
    if(!this.pickedElement) return;
    this.labelIconElState.isMousedown = true;
    this.labelIconElState.lastDownPos = {
      x: touches[0].clientX, 
      y: touches[0].clientY, 
    }

    this.showElementMoveLabel(false);
    this.showProjectionDecals(true, this.pickedElement);
  },

  labelIconElTouchmove (e) {
    if(!this.labelIconElState.isMousedown) return;
    let { touches } = e;

    let currentPos = {
      x: touches[0].clientX, 
      y: touches[0].clientY, 
    }
    let moveDir = {
      x: currentPos.x - this.labelIconElState.lastDownPos.x,
      y: currentPos.y - this.labelIconElState.lastDownPos.y,
    }

    if(moveDir.x  > 0)  moveDir.x = 1;
    else if(moveDir.y  < 0)  moveDir.y = -1;

    if(moveDir.x !== 0) this.projectionDecals.horizontalMove(Math.PI / 100 * moveDir.x);
    if(moveDir.y !== 0) this.projectionDecals.verticalMove(-0.0005 * moveDir.y);


    this.labelIconElState.lastDownPos = {
      x: touches[0].clientX, 
      y: touches[0].clientY, 
    }
    // console.log('moveDir', e)
  },

  labelIconElTouchend  () {
    this.labelIconElState.isMousedown = false;

    let rayPicked = this.projectionDecals.getCenterRayInfo();
    this.surfaceDecals.setLeftTopByUv(this.pickedElement, rayPicked.uv)
      console.log('rayPicked', rayPicked)
      this.showElementMoveLabel(true, this.pickedElement)
      this.showProjectionDecals(false);
    
    // console.log('labelIconElTouchend', e)
  },

   showBowlCover(isShow) {
    this.bowlCover.visible = isShow;
    this.isBowlCover = isShow;
    this.isBowlCoverChange = true;
   },

    async addBowlCover(bowlCoverUrl) {
      await  this.viewer.addAttachObjectByUrl(bowlCoverUrl).then((mesh) => {
        this.bowlCover = mesh;
        mesh.attachTo(this.seriesModel.activeMesh);
        mesh.visible = false;
      });
      return this.bowlCover;      
   },

   addMaterialMaker() {
    this.materialMaker = new MaterialMaker(this.seriesModel);
   },

   loadCupMaterial(materialInfo) {


    if(!this.materialMaker) this.addMaterialMaker();
    this.materialInfo = materialInfo;

    if(materialInfo.elements.modelColor) this.modelColor = materialInfo.elements.modelColor;
    this.$eventBus.$emit("materialLoading", materialInfo);

    if(materialInfo.lightsIntensityRate) this.viewer.setLightsIntensityRate(materialInfo.lightsIntensityRate);
    else this.viewer.setLightsIntensityRate();

   
    if(materialInfo.url) this.materialMaker.loadMaterialJson(materialInfo.url);   // 这一句测试用的-- 以后可删除
    else  {
      console.log('materialInfo进来了', materialInfo);
      this.materialMaker.loadMaterial(materialInfo.elements).then(()=> this.$eventBus.$emit("materialLoadComplete", materialInfo))
    }
    this.isMaterialChange = true;
    // 
   },

   addImage(imgInfo, isAlignUv = true) {
    let options = {...imgInfo}

    if(isAlignUv) options.alignUv=this.getScreenCenterObject().uv

    this.$eventBus.$emit("showModalMessage", true, { title: "图案加载中"});
    this.surfaceDecals.addImage(options.url, options).then((img)=>{
      this.$eventBus.$emit("showModalMessage", false);
      img.userData.elementInfo = imgInfo;
      
    });
   },

   addText(options) {
    let {ptToPxRatio} = this.surfaceDecals.target.activeMesh.userData.prints;
    options.alignUv = this.getScreenCenterObject().uv;
    let textEl = this.surfaceDecals.addText(options.text, options);  
    options.text = null;
    // this.changeTextStyle(textEl, options);
    textEl.userData.ptToPxRatio = ptToPxRatio;
    this.surfaceDecals.apply();
    this.$eventBus.$emit("selectElement", textEl);
   },

   changeTextStyle(textEl, options) {
    console.log('changeTextStyle', options)
    let{ text, bold, italic, color, size, textAlign } = options;
    let {ptToPxRatio} = this.surfaceDecals.target.activeMesh.userData.prints;
    let setOptions = {}
    if(options.text) textEl.text = text;

    if(color) setOptions.fill = color;
    if(bold !== undefined) setOptions.fontWeight = bold ? 'bold' : "normal";
    if(italic !== undefined) setOptions.fontStyle = italic ? 'italic' : "normal";
    if(size) setOptions.fontSize = size * ptToPxRatio;
    if(textAlign) setOptions.textAlign = textAlign;

    textEl.set(setOptions)

    // textEl.set({
    //   fill: color,
    //   fontWeight: bold ? 'bold' : "normal",
    //   fontStyle: italic ? 'italic' : "normal",
    //   fontSize: size * ptToPxRatio
    // })
    
    textEl.userData.ptToPxRatio = ptToPxRatio;
    this.surfaceDecals.apply();
   },
  
   switchPrintSide(faceId) {
    this.surfaceDecals.sideSwitchTo(faceId);
   },

    setOnlineFont(element, font) {
      this.$eventBus.$emit("showModalMessage", true, { title: "字体加载中"});
      element.setOnlineFont(font.url).then(()=> {
        this.surfaceDecals.apply();
        this.$eventBus.$emit("showModalMessage", false);
      })    
    },

    loadElements(elements, canvasLocation) {

      // this.surfaceDecals.clearAll();
      let {ptToPxRatio} = this.surfaceDecals.target.activeMesh.userData.prints;

      return new Promise((resolve, reject) => {
        this.surfaceDecals.addElements(elements, canvasLocation).then((e)=> {
          elements.forEach((item, index) => {
            e[index].userData = Object.assign(e[index].userData, item)
            e[index].userData.ptToPxRatio = ptToPxRatio;
          });
          this.surfaceDecals.apply();
          resolve()
        })
      })
    
    
    },

    showEditorCanvas(isShow = true) {

      if(this.isShowEditorCanvas === isShow) return;

      if(!isShow) {
        this.canvasCloseClick();
        return;
      }

      // let canvasContainerEl = document.querySelector('.canvas-container');
      // let editorCanvasEl = document.querySelector('.editor-canvas');
      let canvasAllEl = document.querySelector('.canvas-all');
      canvasAllEl.style.display = 'block';
      this.surfaceDecals.resetCanvasViewEl();
      // this.surfaceDecals.updateNavigationMapCanvas();
   
      // let { width } = editorCanvasEl.getBoundingClientRect();
      // let scale = (width)/ this.surfaceDecals.canvas.width;
      // canvasContainerEl.style.transform =`scale(${scale} )`;
      // canvasContainerEl.style.transformOrigin =  "left top";
      // canvasContainerEl.style.background = 'rgba(0, 0, 0, 0.3)';
      // canvasContainerEl.style.marginTop = '4%';
      // canvasContainerEl.style.pointerEvents = "all";
      // canvasContainerEl.style.maxWidth = scale * this.surfaceDecals.canvas.width +'px';
      // this.editorCanvasScrollLeft(editorCanvasEl, scale);
      this.isShowEditorCanvas = true;
      this.isPreviewInvalid = true;
      this.isEditCollapse = true;
      this.previewDataURL = null;
      this.surfaceDecals.canvas.renderAll();
      setTimeout(()=> window.dispatchEvent(new Event('resize')));
    },

    editorCanvasScrollLeft(editorCanvasEl, scale) {

      let editorCanvasBound = editorCanvasEl.getBoundingClientRect();
      let { object, uv } = this.getScreenCenterObject();   
 
      if(!object) return
      let selectCanvas = null;
      this.surfaceDecals.canvases.forEach((item)=>{
        if(item.userData.nodeName === object.name) selectCanvas = item; 
      })

      if(selectCanvas.userData.nodeName !== this.surfaceDecals.canvas.userData.nodeName) return;
      let { offsetRate } = selectCanvas.userData;
      let offsetPixel = {
        x:  this.surfaceDecals.fullSize * offsetRate.x,
        y:  this.surfaceDecals.fullSize * offsetRate.y
      }
      let  pointPixel = {
        x:  this.surfaceDecals.fullSize * uv.x,
        y:  this.surfaceDecals.fullSize * uv.y
      }

      let canvasPoint = {
        x: (pointPixel.x - offsetPixel.x) * scale,
        y: (pointPixel.y - offsetPixel.y) * scale,
      }

      editorCanvasEl.scrollLeft = canvasPoint.x - editorCanvasBound.width / 2;

    },

    canvasCloseClick() {
      let canvasAllEl = document.querySelector('.canvas-all');
      this.surfaceDecals.canvas.discardActiveObject();
      canvasAllEl.style.display = 'none';
      this.isShowEditorCanvas = false;
      this.isPreviewInvalid = false;
      this.previewDataURL = null;
      this.isEditCollapse = false;
      this.surfaceDecals.apply();
      setTimeout(()=> window.dispatchEvent(new Event('resize')));
    },

    selectChangeEvent(e) {
      let { picked } = e;
      this.pickedElement = picked;
          console.log('selectChange', this.pickedElement)
      this.$eventBus.$emit("selectElement", picked);
  
    },

    loadPreviewscheme(schemeId) {

      let params = {
        id: parseInt(schemeId)
      }

      let currentAPIServe = this.$route.query.froms ? APIServePc:APIServe;
      this.$eventBus.$emit('modelLoading')
      currentAPIServe.get('/design3dData/getDesign3dDataById', {params}).then(async (e)=> {

        let resp = e.data;  
        if(resp.code !== "200" || !resp.data.schemeDetail) return;
        this.schemeId = schemeId;
        let currentScheme = JSON.parse(resp.data.schemeDetail);
        // debugger
        this.$eventBus.$emit('schemeLoading', currentScheme, resp.data.modelId);
     
       
        if(resp.data.modelId) {
          this.modelId = resp.data.modelId;
          this.$eventBus.$emit('changePanelIndexByName', 'SelectTemplatePanel')
        }
        if(resp.data.type) this.modelType= resp.data.type;

        let modelConfig = await this.getModelInfo(this.modelId);
        console.log('currentScheme',resp.data)
        await this.loadModel(modelConfig);    
        this.loadScheme(currentScheme)
      })

      //之前的代码-开始，如果没出问题，以后再删除-10.13
      // if(this.$route.query.schemeId){
      //   APIServe.get('/design3dData/getDesign3dDataById', {params}).then((e)=> {
      //     let resp = e.data;  
      //     if(resp.code !== "200" || !resp.data.schemeDetail) return;
      //     this.schemeId = schemeId;
      //     console.log('getDesign3dDataById',resp.data)
      //     let currentScheme = JSON.parse(resp.data.schemeDetail);
      //       this.loadScheme(currentScheme)
      //   })
      // }else if(this.$route.query.productId){
      //   APIServePc.get('/design3dData/getDesign3dDataById', {params}).then((e)=> {
      //     let resp = e.data;  
      //     if(resp.code !== "200" || !resp.data.schemeDetail) return;
      //     this.schemeId = schemeId;
      //     console.log('getDesign3dDataById',resp.data)
      //     let currentScheme = JSON.parse(resp.data.schemeDetail);
      //       this.loadScheme(currentScheme)
      //   })
      // }
      //之前的代码-结束，如果没出问题，以后再删除-10.13
    },

      async loadScheme(currentScheme) {          
  
        let { isBowlCover, material, elements, type } = currentScheme;
        
        // if(type >= 0) {
        //   this
        // } 

        if(isBowlCover) {
          this.showBowlCover(true);
          this.$eventBus.$emit('setActiveUiBowlCover', true)
        }
        if(material && Object.keys(material).length > 0) {
          console.log('有材质', material)
          this.loadCupMaterial(material);
      
          this.$eventBus.$emit('setActiveUiMaterial', material)
        }
        if(elements && elements.length > 0) {
          elements.forEach((el)=> {
            el.forbidAutoAlign = true;
            el.forbidAutoScale = true;
          })
          await this.loadElements(elements); 
        }
        this.isBowlCover = false;
        this.isMaterialChange = false;
        this.surfaceDecals.isDesignChange = false;  
    },

    loadImageById(id) {
      let params = {id: parseInt(id)}
      // console.log('getDesign3dImgById-params', params)
      if(this.$route.query.froms){
        APIServePc.get('/design3dImg/getDesign3dImgById', {params}).then((e)=> {
          let resp = e.data;
          console.log('getDesign3dImgById', resp)

          this.addImage(resp.data, false);
        })
      }else{
        APIServe.get('/design3dImg/getDesign3dImgById', {params}).then((e)=> {
          let resp = e.data;
          console.log('getDesign3dImgById', resp)

          this.addImage(resp.data, false);
        })
      }
      
    },
    
    async saveScheme(id, name = "方案",isItStoredInMyTemplate) {
      console.log(isItStoredInMyTemplate);
      let previewDataURL, sceneOptions, printDataURL, printFormatImgUrl, thumbnailPicUrl, srcRes;
      // let saveParams = { name }
      let previewPicUrls = [];
      let schemeDetail = {}
      let currentAPIUrl = '/design3dData/addDesign3dData';
     
      schemeDetail.elements = this.surfaceDecals.getElementInfos();
      schemeDetail.isBowlCover = this.isBowlCover;
      if(this.materialInfo) schemeDetail.material = {...this.materialInfo};
     
      if(this.modelColor) {
        if(!schemeDetail.material) schemeDetail.material = {}
        if(!schemeDetail.material.elements) schemeDetail.material.elements = {};
        schemeDetail.material.elements.modelColor = this.modelColor;
      }

      schemeDetail.name = name;
      srcRes = schemeDetail.elements;

      let photographySceneOption = getPhotographySceneOptions(this.modelId);

      if(photographySceneOption) {
        for(let i=0; i<photographySceneOption.length; i++) {
          sceneOptions = photographySceneOption[i];

          this.viewer.photostudio.setPhotographyScene(sceneOptions);
          previewDataURL = await  this.viewer.photostudio.generateSnapshoot(this.seriesModel.activeMesh);
      
          let previewParams = {
            base64Str: previewDataURL
          }       
          // console.log('previewDataURL', previewDataURL) //测试用
          // if(photographySceneOption && i === photographySceneOption.length - 1) return //测试用的代码-调预览图生成

          await  APIServe.post('/file/uploadBase64Image', previewParams).then((e)=> {
            let resp = e.data;       
            previewPicUrls.push(resp.data);           
            console.log('/file/uploadBase64Image', e.data);
          })
        }
      }       

      thumbnailPicUrl = previewPicUrls[0]; 
     
      printDataURL = this.surfaceDecals.renderDataURL();

      let printParams = {
        base64Str: printDataURL
      }
      await  APIServe.post('/file/uploadBase64Image', printParams).then((e)=> {
        let resp = e.data;
        printFormatImgUrl = resp.data
        console.log('printFormatImgUrl', resp);
      })
      console.log('/file/uploadBase64Image_params', schemeDetail);

      let params = {
        thumbnailPicUrl,
        printFormatImgUrl,
        name,
        previewPicUrls: JSON.stringify(previewPicUrls),
        schemeDetail: JSON.stringify(schemeDetail),
        modelId: this.modelId,
        type: this.modelType,
        srcRes: JSON.stringify(srcRes),
      }

      if(id) {
        params.id = id;
        currentAPIUrl = '/design3dData/updateDesign3dDataById'
      }
      if (isItStoredInMyTemplate) {
        // 保存到我的模板
        const {elements} = schemeDetail
        let saveInMyTemplateParams = {
              name,
              uploadMark:'1',
              elements:JSON.stringify(elements),
              thumbnailPicUrl:printFormatImgUrl,
        }
        console.log(saveInMyTemplateParams,"--------->保存到我的模板参数");
        let storeRes = await APIServe.post('/design3dTemplate/addDesign3dTemplate',saveInMyTemplateParams)
        console.log(storeRes,"--------->保存到我的模板返回值");
        
      }


      console.log(currentAPIUrl +'_previewPicUrls', previewPicUrls);
      console.log(currentAPIUrl +'_params', params);
     await APIServe.post(currentAPIUrl, params).then((e)=> {
        let resp = e.data;
        console.log('保存方案完成', resp)

        if(this.$route.query.originPath) {
          if(this.$route.query.originPath==='navigateBack') wx.miniProgram.navigateBack();
          else wx.miniProgram.redirectTo({url:`${this.$route.query.originPath}?productId=${this.$route.query.productId}&orderId=${this.$route.query.orderId}`,
          })
        }
  
      })
    },

    expandThreeEvent(isExpand, isTransitionAnimation = true) {

      if(this.isExpandThree === isExpand) return;
      this.isExpandThree = isExpand;

      if(isTransitionAnimation !== this.isTransitionAnimation) this.isTransitionAnimation = isTransitionAnimation;
      if(isExpand && this.isShowEditorCanvas) this.canvasCloseClick();

      setTimeout(()=> {
        let myEvent = new Event('resize');
        window.dispatchEvent(myEvent);
        if(isExpand) this.viewer.camera.flyBackwardsTo(0.1, {duration: 300});
        else this.viewer.camera.flyForwardTo(0.1, {duration: 300});
     
      }, 400)
    },

    elementDragOutEvent(e) {
      
      let { dragOutAxle } = e
      if(dragOutAxle.y || dragOutAxle.x) {
        if(this.toast) this.toast.clear();
        this.toast = Toast({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '元素拖到画布外部，将会删除',
        });
      } else if(!dragOutAxle.y && this.toast) {
        this.toast.clear();
        this.toast = null;
      }
    },

    editToolsStateChangeEvent(state) {
      if(state === 'collapse' && this.isShowEditorCanvas) this.canvasCloseClick();
    },

    getScreenCenterObject() {
      let threeElBounding = this.$refs.threeContainer.getBoundingClientRect();
      let screenCenterPointX = Math.floor(threeElBounding.width / 2);
      let screenCenterPointY = Math.floor(threeElBounding.height / 2);
      let boject = this.viewer.getObjectByScreenPoint(screenCenterPointX, screenCenterPointY);  
      
      return boject;
    }, 

    showPreview() {
        this.saveScheme(this.schemeId);
        wx.miniProgram.navigateTo({ url:''});
      // sceneOptions = this.photographySceneOptionLists[0];
      // if(this.previewDataURL) { 
      //   this.previewDataURL = null;
      //   return;
      // } else {
      //   this.viewer.photostudio.setPhotographyScene(sceneOptions);
      //   this.viewer.photostudio.generateSnapshoot(this.seriesModel.activeMesh).then((e)=> {
      //     this.previewDataURL = e;
      //   })
      // }

    },

    async  initPhotographyScene() {
      await this.viewer.photostudio.loadStudioModel('/cup-demo/data/cup-studio-v2.glb')
      
      //测试用的代码-调预览图生成
      // this.viewer.photostudio.showScene();
      // let photographySceneOption = getPhotographySceneOptions(this.modelId);
      // setTimeout(()=> {
      //   this.viewer.photostudio.setPhotographyScene(photographySceneOption[2]);
      //   this.viewer.photostudio.generateSnapshoot(this.seriesModel.activeMesh);
      // }, 5000)
      

    },

    navigateBackAndSave(title) {
      console.log('this.surfaceDecals.isDesignChange', this.surfaceDecals.isDesignChange)
      console.log('this.isBowlCoverChange', this.isBowlCoverChange)
      console.log('this.isMaterialChange', this.isMaterialChange)
      
      if(!this.surfaceDecals.isDesignChange && !this.isBowlCoverChange && !this.isMaterialChange){
        if(this.$route.query.originPath==='navigateBack') wx.miniProgram.navigateBack();
        else wx.miniProgram.redirectTo({url:`${this.$route.query.originPath}?productId=${this.$route.query.productId}&orderId=${this.$route.query.orderId}`})
      }
      else {
        Dialog.confirm({
          message: '当前设计方案已有改动，是否退出？',
          cancelButtonText:'否',
          confirmButtonText:'是',
          closeOnPopstate:false,
        })
        .then(() => {
          // on confirm
          if(this.$route.query.originPath==='navigateBack') wx.miniProgram.navigateBack();
          else wx.miniProgram.redirectTo({url:`${this.$route.query.originPath}?productId=${this.$route.query.productId}&orderId=${this.$route.query.orderId}`})
          // this.$eventBus.$emit("showSaveSchemeDialog", true);  
        })
        .catch(() => {
        });    
        }
      } ,

      handleComplete() {
        this.$eventBus.$emit('showSaveSchemeDialog', true)
      },

      replaceImageClick() {
        console.log('replaceImage12345')
        this.$eventBus.$emit('showReplaceImage', true)
      },

      replaceImage(options) {
        this.$eventBus.$emit("showModalMessage", true, { title: "图案加载中"});
        this.$eventBus.$emit("showReplaceImage", false);
        this.surfaceDecals.replaceImage(this.pickedElement, options.url).then(()=>{
          this.$eventBus.$emit("showModalMessage", false);
          // img.userData.elementInfo = imgInfo;
          
        });
      }

      

  },

  
  created() {
    
    let that = this;

    this.$eventBus.$on("showBowlCover", this.showBowlCover);
    this.$eventBus.$on("loadCupMaterial", this.loadCupMaterial);
    this.$eventBus.$on("addImageToCup", this.addImage);
    this.$eventBus.$on("replaceImageToCup", this.replaceImage);
    this.$eventBus.$on("switchPrintFace", this.switchPrintSide);
    this.$eventBus.$on("addTextElement", this.addText);
    this.$eventBus.$on("changeTextElement", this.changeTextStyle);
    this.$eventBus.$on("loadOnlineFont", this.setOnlineFont);
    this.$eventBus.$on("loadElements", this.loadElements);
    this.$eventBus.$on("expandThree", this.expandThreeEvent);
    this.$eventBus.$on("showEditorCanvas", this.showEditorCanvas );
    this.$eventBus.$on("editToolsStateChange", this.editToolsStateChangeEvent );
    this.$eventBus.$on("resetMaterial", () => this.surfaceDecals.resetMaterial());
    this.$eventBus.$on("saveScheme", this.saveScheme);
    this.$eventBus.$on("navigateBackAndSave", this.navigateBackAndSave);
    this.$eventBus.$on("switchModel", this.switchModel);
    this.$eventBus.$on("setModelColorByArea", (areaId, color) => {
      this.modelColor = {areaId, color};
      this.materialMaker.setColorByAreaId(areaId, color)
    });
   
    // 监听返回
    this.pushHistory(document.title, location.href)
    window.addEventListener('popstate',function(e) {
        //为了避免只调用一次
        console.log(that.$route.path,"this.$route.path");
        that.pushHistory(document.title, location.href)
        if(that.$route.path.indexOf('previewscheme')>-1){
          wx.miniProgram.navigateBack()
        }else{
          // 自定义方法, 直接关闭网页或返回小程序上一页
          that.navigateBackAndSave() 

        }
      },
      false
    )
  },

  mounted(){
    
    if(this.$route.query.modelId) this.modelId = parseInt(this.$route.query.modelId);
    if(this.$route.query.token) {
      if(this.$route.query.froms){
        APIServePc.defaults.headers['Authorization-admin'] = 'Bearer ' + this.$route.query.token;
        this.initThreeScene()
      }else{
        APIServe.defaults.headers.Authorization = 'Bearer ' + this.$route.query.token;
        this.initThreeScene()
      }
    }else{
      let token ='eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJkZjUwMTBlYjI4MGM0MDEyOGNlYWRhMjFlZjhhYjg1ZiIsInVzZXIiOiIxODc3OTU2MjAwMyIsInN1YiI6IjE4Nzc5NTYyMDAzIn0.503qbNDYZimW5CyEe1jAwoOBSU0iHJbSIswYMlpLgguuwGslWnsVhwfo77jTW5bORxn5DfG1ZPDBpasydIjaNg'
      APIServe.defaults.headers.Authorization = 'Bearer ' + token;
      this.initThreeScene()
    }
	
    this.$nextTick(() => {
      if (localStorage.getItem('isFirst') === null || localStorage.getItem('isFirst') !== '1') {
        this.$intro().start()
        localStorage.setItem('isFirst', 1)
      }
    })

	}, 

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.three-editor {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  border-radius: 25px;
  // margin-top: 20px;
  // margin-left: 2%;
  position: relative;
  overflow: hidden;

  &.transitionAnimation {
      transition: height 0.3s;
      -moz-transition: height 0.3s;
      -webkit-transition: height 0.3s;
      -o-transition:height 0.3s;
  }

 
  &.expand {
    height: 90vh;
  }
  .three-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    &.edit-collapse {
      height: 70%;
    }
  }
}

.canvas-all {

  display: none;
  position: absolute;
  // top: 0px;
  left: 0px;
  width: 100%;
  // height: 56%;
  // background: rgba(0, 0, 0, 0.3);
  bottom: 0px;
  z-index: 1;
  pointer-events: none;
}




.editor-canvas {
  
  // height: calc(100% - 40px);
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;

  &::-webkit-scrollbar {
    // display: none; /*隐藏滚动条*/
    background-color: #00000085;
    // width: 20px; /*设置滚动条宽度*/
    height: 20px; /*设置滚动条高度*/
  }
  &::-webkit-scrollbar-thumb {
  background-color: #5555559c;
  }


}

.canvas-close-icon {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: all;
}

.preview-image {
  width: 60%;
  left: 20%;
  top: 6%;
  position: absolute;
  border-radius: 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.show-preview-btn {
  padding: 4px 10px;
  position: absolute;
  bottom: 4px;
  left: 20px;
  cursor: pointer;
  border: 1px solid #79797938;
  border-radius: 5px;
  pointer-events: all;
  z-index: 1;
  &.active {
    background: rgb(69, 109, 241);
    color: #fff;
  }

  &.invalid {
    pointer-events: none;
    opacity: 0.3;
    
  }
}

.preview-image-list {
  width: 100%;
  height: 66%;
  backdrop-filter: blur(20px);
  background: rgb(185 185 185 / 20%);
  position: absolute;
  top: 109px;
  padding-top: 50px;
  z-index: 1;
}

.canvas-side-label {
  position: absolute;
  top: 0px;
  width: 100%;
  pointer-events: none;
  transform: translateY(-100%);
  
  display:flex;
  justify-content: space-between;

  &>div {
    padding: 4px 0px;
    width: 24%;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    font-size: 12px;
    background: linear-gradient(to bottom, #0000001c, #00000009);
  }
  .side-label-left {
    margin-left: 1%;
  }
  .side-label-right {
    margin-right: 1%;
  }
}



.introjs-helperLayer{
    box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px!important;
    border: 3px dashed #409eff;
}
.new-tips{
  position: fixed;
  top: 30px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 13px;
  background: linear-gradient(180deg, #ececec 0%, #ececec 100%);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  .svg-icon-operating{
    width: 20px;
    height: 20px;
    margin-right: 3px;
    background-image: url("@/assets/images/operating-instructions.svg");
    background-size: contain;
  }
  z-index: 1;
}
.introjs-tooltip-title{
    font-size: 16px;
    width: 80%;
    padding-top: 10px;
}
.warper {
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid saddlebrown;
}
/* 重置引导组件样式(类似element-ui个人使用) */
.intro-tooltip {
  color: #ffff;
  background: #2c3e50;
}
/* 引导提示框的位置 */
.introjs-bottom-left-aligned {
  left: 45% !important;
}
.introjs-right,
.introjs-left {
  top: 30%;
}
.intro-highlight {
  background: rgba(255,255,255,0.5);
}
.introjs-arrow.left {
  border-right-color: #2c3e50;
}
.introjs-arrow.top {
  border-bottom-color: #2c3e50;
}
.introjs-arrow.right {
  border-left-color: #2c3e50;
}
.introjs-arrow.bottom {
  border-top-color: #2c3e50;
}
/* 提示框头部区域 */
.introjs-tooltip-header {
  padding-right: 0 !important;
  padding-top: 0 !important;
}
.introjs-skipbutton {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 15px;
    color: #616161;
    font-size: 22px;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}
.introjs-tooltipbuttons {
  border: none !important;
}
.introjs-tooltiptext {
  font-size: 14px !important;
  padding: 15px !important;
}
/* 提示框按钮 */
.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.introjs-button {
  width: 50px !important;
  text-align: center;
  padding: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
  border: none !important;
}
.introjs-button:last-child {
  margin-left: 10px;
}
.introjs-prevbutton {
  color: #606266 !important;
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
}
.introjs-nextbutton {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}
.introjs-disabled {
  color: #9e9e9e !important;
  border-color: #bdbdbd !important;
  background-color: #f4f4f4 !important;
}

.submits{
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 13px;
  background: linear-gradient(180deg, #ececec 0%, #ececec 100%);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 1;
  .svg-icon-complete{
    width: 20px;
    height: 20px;
    margin-right: 3px;
    background-image: url("@/assets/images/submit-icon.svg");
    background-size: contain;
  }
}

.replace-image-icon {
  // width: 36px;
  // height: 36px;
  cursor: pointer;
  position: absolute;
  bottom: -8px;
  right: 40px;
  z-index: 1;
  pointer-events: all;
  // border-radius: 18px;
  // background: linear-gradient(180deg, #ececec 0%, #ececec 100%);
  // box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  .icon{

  }
  .svg-icon-changePic{
    width: 30px;
    height: 24px;
    display: inline-block;
    background-image: url("@/assets/images/change-img.svg") ;
    background-size: contain;
    background-repeat:no-repeat;
  }
  .title{
    font-size: 10px;
    margin-left: 5px;
    transform: translateY(-4px);

  }
  .van-icon {
    transform: translateY(7%);
  }
}


</style>

