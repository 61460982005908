<template>
  <van-Row class="edit-nav">
    <van-dialog v-model="show" title="保存我的作品" show-cancel-button @confirm ="saveScheme" :beforeClose="beforeCloseFn">
      <van-row>
        <van-field v-model="schemeName" label="名称" />
        <van-radio-group v-model="radioName">
          <van-cell-group>
            <van-cell title="是否存储到我的模版" clickable @click="chooseSave">
              <template #right-icon>
                <van-radio name="yes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <!-- <p class="explain-text">{{explainText}}</p> -->
      </van-row>   
    </van-dialog>
    <van-col span="4" class="nav-perv" :class="{invalid: currentPanelIndex < 2}"  @click="goPanelIndex(currentPanelIndex-1)" >
      <van-icon name="down"  size="20" color="#000000" />
    </van-col>
    <van-col span="16" class="nav-title">
      {{panelInfos[currentPanelIndex].title }}   &nbsp;  &nbsp;  
      {{currentPanelIndex+1 }} <span> / {{ panelInfos.length + 1 }}</span>
    </van-col>
    <!-- <van-col span="3" class="nav-complete">
      <van-button plain type="default" size="small" @click="handleComplete">完成</van-button>
    </van-col> -->
    <van-col span="4" class="nav-next"  @click="goPanelIndex(currentPanelIndex+1)" >
       <van-icon name="down"  size="20" color="#000000" />
    </van-col>
  </van-Row>
</template>

<script>

import { Col, Row, Icon, Dialog, Field,RadioGroup, Radio ,Cell, CellGroup} from 'vant';
import Vue from 'vue';
import APIServe from '@/plugins/APIServe.ts';

Vue.use( Col );
Vue.use( Row );
Vue.use( Icon );
Vue.use( Dialog );
Vue.use( Field );
Vue.use( RadioGroup );
Vue.use( Radio );
Vue.use( Cell );
Vue.use( CellGroup );

export default {
  name: 'EditNav',
  data () {
    return {
    show: false,
    schemeName: '新设计',
    explainText: '请保存当前设计',
    isItStoredInMyTemplate:false,//是否储存到我的模板
    radioName:null
  }},  
  props: {
    currentPanelIndex: Number,
    panelInfos: Array
  },

  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  methods: {
    goPanelIndex(index) {
      // console.log('index', this.$props.panelInfos.length)
      if(index === this.$props.panelInfos.length) this.show = true
      else this.$eventBus.$emit('changePanelIndex', index)
    },

    saveScheme() {
    //  console.log('saveScheme')
      // let schemeId = parseInt(this.$route.query.schemeId)
      // this.$eventBus.$emit('saveScheme', schemeId, this.schemeName);
    },
    beforeCloseFn(action, done){
       if (action === 'confirm') {
        // 如果点击了确认按钮，则继续关闭对话框
        let schemeId = parseInt(this.$route.query.schemeId)
        this.$eventBus.$emit('saveScheme', schemeId, this.schemeName,this.isItStoredInMyTemplate);
        setTimeout(done, 3000)
      } else {
        // 如果点击了取消按钮，则阻止对话框关闭
        done()
      }
    },
    handleComplete(){
      this.show = true
    },
    // 选择是否保存到我的模板
    chooseSave(){
      this.isItStoredInMyTemplate =!this.isItStoredInMyTemplate
      this.radioName = this.isItStoredInMyTemplate?'yes':null
      console.log(this.isItStoredInMyTemplate,"--------->是否保存到我的模板");
    }
      
  },

  mounted(){
		if(this.$route.query.schemeId) {

      let params = {
        id: parseInt(this.$route.query.schemeId)
      }

      APIServe.get('/design3dData/getDesign3dDataById',{params}).then((e)=> {
        let resp = e.data;  
         console.log('editNav', resp)
        if(resp.code !== "200" || !resp.data.schemeDetail) return;
        this.schemeName = resp.data.name;
      })
    }
	}, 

  created() {
    this.$eventBus.$on("showSaveSchemeDialog", (isShow)=>{
      this.show = isShow     
    });
  },
  watch: {
		show(nVal, oVal) {
        if (this.$route.query.schemeId) {
          console.log(this.$route.query.schemeId,"方案Id");
        }else{
          let date = new Date()
          let nameSuffix = this.$moment(date).format("YYYYMMDDHHmmss")
          this.schemeName = '作品' + nameSuffix
        }
      
		}
	},

}
</script>

<style lang='scss' scoped>
.edit-nav {
  justify-content: center;

  .nav-title {
    font-size: 16px;
    line-height: 48px;
    text-align: center;

    span {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
.nav-complete{
   font-size: 16px;
    line-height: 40px;
    text-align: center;
}
.van-col{
  height: 48px;   
}
.nav-perv {
  display: flex;
  align-items: center;
  justify-content: center;

  &.invalid {
    pointer-events: none;
    opacity: 0.2;
  }
}
.nav-perv  .van-icon {
  transform: rotate(90deg);
}
.nav-next {
  display: flex;
  align-items: center;
  justify-content: center;  
  &.invalid {
    pointer-events: none;
    opacity: 0.2;
  }
}

.nav-next  .van-icon {
  transform: rotate(-90deg);
}

.explain-text {
  padding: 4px 20px ;
  background: #f9fad7;
  text-align: center;
  width: 90%;
  margin-left: 5%;
  color: rgba(0, 0, 0, 0.466);
  
}
::v-deep{
  .van-cell__title{
      font-size: 16px;
      color: #646566;
  }
  .van-dialog__header{
    border-bottom:1px solid #f7f8f9;
    padding-bottom:10px ;
  }
  
}
</style>
