
import { fabric } from 'fabric';
import { CanvasTexture, RepeatWrapping, Vector2, sRGBEncoding } from 'three';
import { defineProperty } from '../utils/helper';

export class BumpMaker {

  constructor(target, option = {}) {

    this.target = target;
    this.name = option.name || 'decals'
    this.pixelWidth = option.pixelWidth || 512;
    this.pixelHeight = option.pixelHeight || 512;
    this.pixelOffset = option.pixelOffset || {x: 0, y:0};    
    // this.printWidth = option.printWidth;
    // this.printHeight = option.printHeight;

    this.backgroundImg = null;
    this.fullCanvas = document.createElement('canvas');  
  
    this.originMap = this.target.material.map;
    this.canvas = new fabric.Canvas();  
    this.canvas.setWidth(this.pixelWidth);
    this.canvas.setHeight(this.pixelHeight);
    let canvasEl = this.canvas.getElement();
    canvasEl.width = this.pixelWidth;
    canvasEl.height = this.pixelHeight;

    if(this.originMap) this.setDrawSize();

    this.definePropertyAll();
  
  }

  initBackgroundCanvas() {

  }

  setDrawSize() {  
   
    this.fullCanvas.width = this.originMap.source.data.width;
    this.fullCanvas.height = this.originMap.source.data.height;
    let  fullCanvasCtx  = this.fullCanvas.getContext("2d");
    fullCanvasCtx.fillStyle = "rgb(128,128,255)";
    fullCanvasCtx.fillRect(0, 0, this.fullCanvas.width, this.fullCanvas.height)

  }

  addImage(url) {
    let that = this
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(url, function(img) {
        // var oImg = img.set({ left: 0, top: 0});
        let oImgs = that.tileImageToArea (
          img, 
          {x:0, y:0}, 
          {x:that.pixelWidth, y:that.pixelHeight}
        )
        
        that.canvas.add(...oImgs);    
        // console.log('oImg', oImg)
        resolve(oImgs);
      }, {crossOrigin: 'anonymous'});
    })
 
  }


  remove(element){
    console.log('element', element)
    this.canvas.remove(element);
  }



  apply() {
    this.canvas.renderAll();
    this.mergeCanvas();
    let currentCanvas = this.fullCanvas;
     let canvasTexture = new CanvasTexture(currentCanvas);
 
    // canvasTexture.encoding = sRGBEncoding;
    canvasTexture.wrapT = RepeatWrapping;
    canvasTexture.repeat.y = -1
    this.target.material.normalMap = canvasTexture;
    this.target.material.needsUpdate = true;
  }

  mergeCanvas() {

    let fullCanvasCtx = this.fullCanvas.getContext('2d');
    fullCanvasCtx.clearRect( 
      this.pixelOffset.x, 
      this.pixelOffset.y, 
      this.pixelWidth, 
      this.pixelHeight
      );

    fullCanvasCtx.drawImage(
      this.canvas.getElement(), 
      this.pixelOffset.x, 
      this.pixelOffset.y, 
      this.pixelWidth, 
      this.pixelHeight
      )
  }

  tileImageToArea(img, startPos, endPos, pattern) {
    
    let imgs = []
    let imgWidth = img.width;
    let left = startPos.x;
    let top = startPos.y;
    
    // debugger
    let areaSize = new Vector2( endPos.x - startPos.x, endPos.y - startPos.y );
    img.scaleToHeight(areaSize.y);
    imgWidth *= img.scaleX;

    let count = Math.ceil(areaSize.x / imgWidth);
    for(let i=0; i<count; i++) {
      let crrentImg = img;
      if(i > 0) {
        img.cloneAsImage(function (cloneObject){
          crrentImg = cloneObject;
        });
        left += imgWidth;
      }
      crrentImg.set({ left, top})
      imgs.push(crrentImg)
    }
    return imgs

  }

  clear() {
    this.canvas.clear();
    let fullCanvasCtx = this.fullCanvas.getContext('2d');
    fullCanvasCtx.fillStyle = "rgb(128,128,255)";
    fullCanvasCtx.fillRect(0, 0, this.fullCanvas.width, this.fullCanvas.height);
    this.target.material.normalMap = null;
    this.target.material.needsUpdate = true;
  }

 
  definePropertyAll() {
    // defineProperty(this, 'target', () => {

    // })
  }
}