<template>
 
  <van-tabs ref="materialTabs" :color="materialList.length === 0 && !isSelectColor ? '#00000000':null">
    <van-tab title="材质" :disabled="materialList.length === 0" >
      <van-Row class="edit-material" v-if="materialList">
        <van-Col   
            v-for="(item, index) in materialList" 
            class="material-item"
          
            :class="{active: selectMaterialIndex === index}"
            :key="index">

            <van-image     
              width="100%"
              height="100%"
              fit="cover"
              radius="15"
              :src="item.thumbnailPicUrl"
              @click="selectMaterial(item, index)"

            />
            <p>{{ item.name }} </p>
            <van-Col class="material-item-icon" />
          </van-Col>       
      </van-Row>
    </van-tab>

    <van-tab title="颜色" :disabled="!isSelectColor">

      <van-row class="colors-body">
          <van-col class="colors-body-item" :class="{ white: index === 0 }" v-for="(colorItem, index) in materialColors"
            :style="{ background: colorItem }" @click="changeMaterialColor(colorItem)" :key="index">
          </van-col>
   
      </van-row>

    </van-tab>
  </van-tabs>
    
   
</template>

<script>

import { Col, Row, Image, Icon, Tab, Tabs } from 'vant';
import Vue from 'vue';

import APIServe from '@/plugins/APIServe.ts';
import axios from 'axios';

Vue.use( Col );
Vue.use( Row );
Vue.use(Tab);
Vue.use(Tabs);
Vue.use( Image );
Vue.use(Icon);

export default {
  name: 'MaterialPanel',

  data() {
    return {
      selectMaterialIndex: 0,
      materialList: [],
      materialColors: null,
      isSelectColor: false,
    }
  },

  
  mounted(){   
 
   
	},

  methods: {    

    selectMaterial(info, index) {
      if(this.selectMaterialIndex === index) {
        // this.$eventBus.$emit('resetMaterial');
        this.selectMaterialIndex = -1;
        return;
      }
      this.selectMaterialIndex = index;
      this.$eventBus.$emit("loadCupMaterial", this.materialList[index]);
    },

    setActiveUiMaterial(activeMaterial) { 
      // console.log('setActiveUiMaterial',activeMaterial, this.materialList )
      if(!this.materialList) return;
      for(let i = 0; i < this.materialList.length; i++) {
        if(this.materialList[i].id === activeMaterial.id){
          this.selectMaterialIndex = i;
          return;
        }
      }
    },
    async loadMaterialInModelLoaded(modelId)  {
      
      if(this.$route.query.schemeId) return;
   
      this.getDesign3dMaterial(modelId)

    },
    
    async loadMaterial(scheme, modelId) {
      console.log('loadMaterialasdf5', modelId)
      await this.getDesign3dMaterial(modelId)
      if(scheme.material) this.setActiveUiMaterial(scheme.material)        

    },

    getDesign3dMaterial(modelId) {

      let params = {
        page:0, 
        pageSize: 10,
        modelId,
      }

      //  APIServe.post('/design3dFont/getAllDesign3dFontAndColor').then((e) => {
      //   let resp = e.data;
      //   if (resp.code !== '200') return;
      //   this.materialColors = resp.data.colors;
      // })

      return APIServe.post('/design3dMaterial/getPageDesign3dMaterial', params).then((e)=> {
        let res = e.data.data
        
        if(res.records.length === 0) return;
        console.log('getPageDesign3dMaterial',res.records, params)
        let  materialList = res.records;
    
        materialList.forEach(item => { item.elements = JSON.parse(item.elements)});
        this.materialList = materialList;
        this.$refs.materialTabs.scrollTo();  
      
      })
    },

    changeMaterialColor(color){
      this.$eventBus.$emit('setModelColorByArea', 'all', color);
    },

  },

  created() {
    this.$eventBus.$on("setActiveUiMaterial", this.setActiveUiMaterial);
    this.$eventBus.$on('modelLoadComplete', this.loadMaterialInModelLoaded);
    this.$eventBus.$on('schemeLoading', this.loadMaterial);
    this.$eventBus.$on('updateModelColorLists', (colorLists) => {
      this.materialColors = colorLists;
    });

    this.$eventBus.$on('setShowModelSelectColor', (isShow) => this.isSelectColor = isShow);

  },

}

</script>

<style lang="scss" scoped>
.edit-material {
  width: 100%;
  padding: 10px 20px 0px;
  .material-item {
    margin: 0px 1.5%;
    width: 30%;
    height:74px;
    position: relative;

    p {
      text-align: center;
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    &.active {
      .material-item-icon {
        background: url("@/assets/images/checked.svg");
        background-size: 100%;
        border:none;
      }
    }

    .material-item-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      pointer-events: none;
      top: 4px;
      right: 4px;
      border-radius: 10px;
      border: 1px solid #F1F1F1;

    }
  }

}

.colors-body {
  padding: 10px 30px 0px;

  .colors-body-item {
    width: calc(25% - 8px);
    height: 59px;
    border-radius: 10px;
    margin: 4px;
    background: #FFFFFF;
    // box-sizing: border-box;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);

    &.white {
      border: 1px solid #e4e4e4;
    }
  }
}






</style>
